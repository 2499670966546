import React, {
  FC,
  JSX,
  useState,
  Dispatch,
  ChangeEvent,
  SetStateAction,
} from "react";
import { TypicalTaskDecisionType, UploadedFilesPropsType } from "app/types";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import DrawerDragAndDrop from "./DrawerDragAndDrop/DrawerDragAndDrop";
import {
  Form,
  Input,
  FormInstance,
} from "antd";

interface ISendClarificationProps {
  form: FormInstance;
  taskUuid: string;
  taskType: string;
  onFinish: (actionBody: TypicalTaskDecisionType) => void;
  onDrawerClose: () => void;
  dragAndDropFiles: UploadedFilesPropsType[];
  drawerTaskAction: string;
  taskActionComment: string;
  uploadedFilesList: JSX.Element[] | null;
  setDragAndDropFiles: Dispatch<SetStateAction<UploadedFilesPropsType[]>>;
  markupTaskFileFrom1C: UploadedFilesPropsType[] | null;
  handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  remainingCharsComment: number;
  dragAndDropFilesCount: number;
  setDragAndDropFilesCount: Dispatch<SetStateAction<number>>;
  isAutomaticProcessingActive: boolean;
}

const { TextArea } = Input;

const SendClarification: FC<ISendClarificationProps> = ({
  form,
  taskUuid,
  taskType,
  onFinish,
  onDrawerClose,
  dragAndDropFiles,
  drawerTaskAction,
  taskActionComment,
  uploadedFilesList,
  setDragAndDropFiles,
  handleTextAreaChange,
  remainingCharsComment,
  dragAndDropFilesCount,
  setDragAndDropFilesCount,
  isAutomaticProcessingActive,
}): JSX.Element => {
  const isDeclaration: boolean = taskType === "Декларация";
  const isSubmitBtnDisabled: boolean = isDeclaration
    ? !remainingCharsComment || !uploadedFilesList?.length
    : !remainingCharsComment || isAutomaticProcessingActive;

  const isManualOrClarificationActionType = drawerTaskAction === "Отправить на уточнение"
    ? "clarification_decision"
    : "manual_decision";

  const requiredMessage: string = isDeclaration
    ? "Пожалуйста, прикрепите файлы"
    : "";

  const sendClarificationBody: TypicalTaskDecisionType = {
    task: taskUuid,
    task_decision_type: isManualOrClarificationActionType,
    comment: taskActionComment,
    files: dragAndDropFiles?.map(({ uuid }) => uuid) ?? []
  };

  const onSendClarificationFinish = (): void => {
    onFinish(sendClarificationBody);
  };

  return (
    <Form form={form} className="m-0" name="sendClarification" layout="vertical" onFinish={onSendClarificationFinish}>
      <Form.Item name="clarificationComment" label="Комментарий" className="mb-4" required>
        <TextArea
          id="clarificationComment"
          rows={25}
          maxLength={2000}
          className="text-base font-normal leading-5 text-gray-900"
          placeholder="Ваш комментарий клиенту"
          onChange={handleTextAreaChange}
        />
        <div className="text-xs font-normal leading-100 counter-gray-color text-right">
          {remainingCharsComment}/2000
        </div>
      </Form.Item>
      <Form.Item
        className="mb-4"
        name="clarificationDocs"
        required={isDeclaration}
        label={<div className="search-color">{requiredMessage}</div>}
      >
        <DrawerDragAndDrop
          isDeclaration={isDeclaration}
          uploadedFilesList={uploadedFilesList}
          setDragAndDropFiles={setDragAndDropFiles}
          isSuccessBlockHidden
          dragAndDropFilesCount={dragAndDropFilesCount}
          setDragAndDropFilesCount={setDragAndDropFilesCount}
        />
      </Form.Item>
      <Form.Item className="text-right">
        <ButtonCustom className="mr-2" size="large" type="default" ghost text="Отменить" onClick={onDrawerClose} />
        <ButtonCustom
          size="large"
          type="primary"
          text="Подтвердить"
          htmlType="submit"
          loading={isAutomaticProcessingActive}
          disabled={isSubmitBtnDisabled}
        />
      </Form.Item>
    </Form>
  );
};

export default SendClarification;
