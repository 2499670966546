import { ITypesOfProfessionProps } from "../AgreementForm";

export const typeOfEmployment: ITypesOfProfessionProps[] = [
  {
    value: "Основное место работы",
    label: "Основное место работы"
  },
  {
    value: "По внешнему совместительству",
    label: "По внешнему совместительству"
  },
  {
    value: "По внутреннему совместительству",
    label: "По внутреннему совместительству"
  },
];
