import React, { FC, JSX } from "react";
import { Tag } from "antd";
import css from "./TagCustom.module.css";
import { TagCustomColorType } from "app/types";

interface ITagCustomProps {
  color?: TagCustomColorType;
  text?: string;
  className?: string;
}

const TagCustom: FC<ITagCustomProps> = ({
  color,
  text,
  className,
}): "" | null | JSX.Element => {
  const customAntdStyle = (color: string) => {
    switch (color) {
      case "customProcessing":
        return css.TagCustomProcessing;
      case "customSuccess":
        return css.TagCustomSuccess;
      case "customWarning":
        return css.TagCustomWarning;
      case "customError":
        return css.TagCustomError;
      case "customDraft":
        return css.TagCustomDraft;
      case "customSkills":
        return css.TagCustomSkills;
      case "roles":
        return css.TagRoles;

      default :
        return css.Tag;
    }
  };

  type TagColorType = Exclude<TagCustomColorType, null>;

  const tagColor: TagColorType | undefined = color === null ? undefined : color;

  return text ? (
    <Tag
      color={tagColor}
      className={`${css.Tag} ${customAntdStyle(color ?? "")} ${className}`}
    >
      {text}
    </Tag>
  ) : null;
};

export default TagCustom;
