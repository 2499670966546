import {
  FC,
  JSX,
  Dispatch,
  useState,
  SetStateAction,
} from "react";
import { FilterTasksType, KeycloakUsersType } from "app/types";
import UsersListActions from "./UsersListActions/UsersListActions";
import UsersListTable, { UserDataType } from "./UsersListTable/UsersListTable";

interface IUsersListProps {
  isLoading: boolean;
  paramsData: FilterTasksType | null;
  setUserData: Dispatch<SetStateAction<UserDataType[]>>;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setModalVariant: Dispatch<SetStateAction<string>>;
  setIsOpenBlockModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenActiveModal: Dispatch<SetStateAction<boolean>>;
  setSortValue: Dispatch<SetStateAction<string | null>>;
  setParamsData: Dispatch<SetStateAction<FilterTasksType | null>>;
  keycloakUsersData: KeycloakUsersType[];
  selectedRows: UserDataType[];
  setSelectedRows: Dispatch<SetStateAction<UserDataType[]>>;
}

const UsersList: FC<IUsersListProps> = ({
  isLoading,
  paramsData,
  setUserData,
  setIsOpenModal,
  setModalVariant,
  setIsOpenBlockModal,
  setIsOpenActiveModal,
  setSortValue,
  setParamsData,
  keycloakUsersData,
  selectedRows,
  setSelectedRows,
}): JSX.Element => {
  const [activeUsersCount, setActiveUsersCount] = useState<number>(0);
  const [notActiveUsersCount, setNotActiveUsersCount] = useState<number>(0);

  return (
    <div className="p-4 bg-white rounded-xl">
      <UsersListActions
        paramsData={paramsData}
        setParamsData={setParamsData}
        activeUsersCount={activeUsersCount}
        notActiveUsersCount={notActiveUsersCount}
        setIsOpenBlockModal={setIsOpenBlockModal}
        setIsOpenActiveModal={setIsOpenActiveModal}
        selectedRows={selectedRows}
        setUserData={setUserData}
      />
      <UsersListTable
        isLoading={isLoading}
        setUserData={setUserData}
        setIsOpenModal={setIsOpenModal}
        setModalVariant={setModalVariant}
        setIsOpenBlockModal={setIsOpenBlockModal}
        setIsOpenActiveModal={setIsOpenActiveModal}
        setSortValue={setSortValue}
        setParamsData={setParamsData}
        keycloakUsersData={keycloakUsersData}
        setActiveUsersCount={setActiveUsersCount}
        setNotActiveUsersCount={setNotActiveUsersCount}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default UsersList;
