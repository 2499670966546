import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import css from "./Clients.module.css";
import {
  Input,
  Tooltip,
  Drawer,
  Form,
  Select,
  Tag,
  Checkbox,
  Radio,
  FormInstance,
} from "antd";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
import { ColumnsType } from "antd/es/table";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { ReactComponent as ClientActive } from "../../assets/icons/client_active.svg";
import { ReactComponent as ClientNotActive } from "../../assets/icons/client_not_active.svg";
import { ReactComponent as ClientDraft } from "../../assets/icons/client_draft.svg";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import {
  itemsPeriod,
  itemsStatus,
  itemsTaxSystem,
  itemsTypeOPF
} from "./filterMocks";
import {
  ClientsListingDataType,
  FilterClientsType,
  FilterNamesType,
  ResponsibleFilterType,
  UserListingType,
  UsersListingFilterType
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import {
  createClientLabourRegulationFailure,
  getClientBanksDataFailure,
  getClientBanksPageNumber,
  getClientBanksPageSize,
  getClientBanksSearch,
  getClientBanksSorter,
  getClientsData,
  getClientsPageNumber,
  getClientsPageSize,
  getCommentsDataFailure,
  getDocumentClientFailure,
  getSchemaClientFailure,
  setClientCardTagKey,
  setClientsListingFilter,
  setClientsListingParams
} from "../../actions/client.actions";
import { AppDispatch } from "../../store/store";
import LoadingCustom from "../ui-kit/LoadingCustom/LoadingCustom";
import TagCustom from "../ui-kit/TagCustom/TagCustom";
import dayjs, { Dayjs } from "dayjs";
import ZeroSearch from "./ZeroSearch/ZeroSearch";
import { useTableSorter } from "../../utils/handleSorterTable";
import {
  getEmployeesListingFailure,
  getEmployeesPageNumber,
  getEmployeesPageSize,
  setClientsEmployeeListingLoadingDefault,
  setClientsEmployeesListingFilter,
  setClientsEmployeesListingParamsData
} from "../../actions/employee.actions";
import {
  getTaskListingFailure,
  setTaskListingLoadingDefault,
  setTaskListingParams
} from "../../actions/tasks.actions";
import { renderFullDate } from "../../utils/renderDate";
import { getUsersListingData } from "../../actions/account.actions";
import { roleResolver } from "../../utils/roleResolver";
import { deadlineDays } from "../../utils/deadlineDays";
import { filterResponsible } from "../../utils/firlterResponsible";
import { getDocumentFailure } from "../../actions/document.actions";
import { renderClientStatus } from "../../utils/renderClientStatus";
import { filterParamsToArray } from "../../utils/filterParamsToArray";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CatalogTaskStatusType, clientStatus } from "../../utils/catalog";
import ClientsListing from "./ClientsListing/ClientsListing";
import { ClientsListingType, FormPropsType } from "./ClientsType/ClientsType";
import { getMonthWord } from "../../utils/ClientsItems/getMonthWord";
import { useInfiniteScroll, UseInfiniteScrollHookParams } from "../../Hooks/useInfiniteScroll/useInfiniteScroll";
import ExportList from "../../utils/ExportList/ExportList";

interface IClientsProps {
}

enum PaginationDefault {
  defaultPage = 1,
  defaultSize = 10,
  pagePerScroll = 50,
}

const { Option } = Select;

const Clients: FC<IClientsProps> = (): JSX.Element => {
  const [form] = Form.useForm();
  const values: FormPropsType = Form.useWatch([], form);

  //получение массива задач и данные пагинации.
  const {
    totalDocs,
    isListingLoading,
    clientsPageSize,
    clientsPage,
    clientsFilter,
    clientsParams
  } = useSelector((state: AppStateType) => state.client);

  const {
    decodedToken,
    usersListing,
  } = useSelector((state: AppStateType) => state.account);

  const isAccountant: boolean = roleResolver(decodedToken).isAccountant;

  const dispatch = useDispatch<AppDispatch>();

  const navigate: NavigateFunction = useNavigate();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<FilterClientsType | null>(clientsFilter);
  const [innData, setInnData] = useState<string>("");
  const [prepaidStartDate, setPrepaidStartDate] = useState<string | null>(null);
  const [prepaidEndDate, setPrepaidEndDate] = useState<string | null>(null);
  const [salaryStartDate, setSalaryStartDate] = useState<string | null>(null);
  const [salaryEndDate, setSalaryEndDate] = useState<string | null>(null);
  const [paramsData, setParamsData] = useState<FilterClientsType | null>(clientsParams
  ? clientsParams
  : {
    page_size: clientsPageSize,
    page: clientsPage,
  });
  const [selectedClientStatus, setSelectedClientStatus] = useState<string[]>(
    clientsParams?.pub_customer_status
      ? filterParamsToArray(clientsParams?.pub_customer_status as string)
      : []);
  const [sortValue, setSortValue] = useState<string | null>(null);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  const [selectedResponsible, setSelectedResponsible] = useState<ResponsibleFilterType[]>([]);

  useInfiniteScroll({
    totalDocs,
    paramsData,
    pagePerScroll: PaginationDefault.pagePerScroll,
    setParamsData,
    setLoadingData,
  } as UseInfiniteScrollHookParams);

  useEffect(() => {
    if (isModalOpen) {
      const params: UsersListingFilterType = {
        page_size: 100,
      };

      dispatch(getUsersListingData(params));
    }
  }, [isModalOpen]);

  //сброс данных карточки клиента
  useEffect(() => {
    dispatch(createClientLabourRegulationFailure());
    dispatch(getEmployeesListingFailure());
    dispatch(getClientBanksDataFailure());
    dispatch(getDocumentClientFailure());
    dispatch(getSchemaClientFailure());
    dispatch(getTaskListingFailure());

    dispatch(setClientCardTagKey("1"));
    dispatch(setClientsEmployeesListingParamsData(null));
    dispatch(getEmployeesPageSize(PaginationDefault.defaultSize));
    dispatch(getEmployeesPageNumber(PaginationDefault.defaultPage));
    dispatch(setClientsEmployeesListingFilter(null));
    dispatch(setClientsEmployeeListingLoadingDefault(true));
    dispatch(setTaskListingParams(null));
    dispatch(setTaskListingLoadingDefault(true));

    dispatch(getClientBanksPageNumber(PaginationDefault.defaultPage));
    dispatch(getClientBanksPageSize(PaginationDefault.defaultSize));
    dispatch(getClientBanksSorter("-name"));
    dispatch(getClientBanksSearch(""));
    dispatch(getCommentsDataFailure());
    dispatch(getDocumentFailure());
  }, [dispatch]);

  useEffect(() => {
      setLoadingData(true);
      dispatch(getClientsData(paramsData))
        .finally(() => {
          setLoadingData(false);
        });
      dispatch(setClientsListingParams(paramsData));
  }, [paramsData]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue("clientsName", e.target.value);

    if (e.target.value) {
      setTimeout((): void => {
        setCurrentPage(PaginationDefault.defaultPage);
        dispatch(getClientsPageNumber(PaginationDefault.defaultPage));
      }, 2000);
    } else {
      setCurrentPage(PaginationDefault.defaultPage);
      dispatch(getClientsPageNumber(PaginationDefault.defaultPage));
    }

  };

  const toClientCard = (text: string, record: ClientsListingDataType) => (
    <Link
      className={css.columnText}
      to={`/clients/${record?.fields?.uuid}`}
    >
      {text}
    </Link>
  );

  const contentTooltip: JSX.Element = (
    <div className={css.tooltip}>
      <span>Статус организации</span>
      <div className="flex items-center">
        <ClientActive/>
        <span className="ml-2">- Активный</span>
      </div>
      <div className="flex items-center">
        <ClientNotActive/>
        <span className="ml-2">- Неактивный</span>
      </div>
      {!isAccountant && (
        <div className="flex items-center">
          <ClientDraft/>
          <span className="ml-2">- Черновик</span>
        </div>
      )}
    </div>
  );

  const renderClientInn = (text: string, record: ClientsListingDataType) => {
    return (
      <Link
        className={css.columnText}
        to={`/clients/${record?.fields?.uuid}`}
      >
        {text ?? ""}
      </Link>
    );
  };

  const checkServiceStatus = (
    date: string | undefined
  ): "customError" | "customWarning" | "customSuccess" | null => {
    if (date) {
      const expirationDate: Date = new Date(date);
      const today: Date = new Date();
      const daysUntilExpired: number = Math.floor((expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24));

      if (daysUntilExpired <= 30) {
        return "customError";
      } else if (daysUntilExpired <= 60) {
        return "customWarning";
      } else {
        return "customSuccess";
      }
    }

    return null;
  };

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setParamsData, setSortValue);

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="table-title">
        {text}
      </div>
    );
  };

  const onClientStatusFilterChange = (e: CheckboxChangeEvent, clientStatus: string): void => {
    const removeClientStatusFilter: string[] =
      selectedClientStatus?.filter((filter: string): boolean => filter !== clientStatus);
    setSelectedClientStatus(e.target.checked ? [...selectedClientStatus, clientStatus] : removeClientStatusFilter);
  };

  const isClientStatusFilterChecked = (taskType: string): boolean => {
    return selectedClientStatus?.includes(taskType);
  };

  const clearClientStatusFilters = (): void => {
    setSelectedClientStatus([]);
    setParamsData((prev: FilterClientsType | null) => {
      const newParamsData: FilterClientsType = {
        ...prev,
        pub_customer_status: null,
        page: PaginationDefault.defaultPage,
      };

      dispatch(setClientsListingParams(newParamsData));
      setCurrentPage(PaginationDefault.defaultPage);
      dispatch(getClientsPageNumber(PaginationDefault.defaultPage));
      return newParamsData;
    });
  };

  const filteredClientStatus = (): CatalogTaskStatusType[] => {
    if (isAccountant) {
      return clientStatus.filter(({ status }: CatalogTaskStatusType) => !["Проспект", "Оплачено"].includes(status));
    }

    return clientStatus;
  };

  const filterStatus = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          {filteredClientStatus().map(({status, label}) => (
            <Checkbox
              key={status}
              className="table-checkbox"
              onChange={(e: CheckboxChangeEvent) => onClientStatusFilterChange(e, status)}
              checked={isClientStatusFilterChecked(status)}
            >
              {label}
            </Checkbox>
          ))}
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={clearClientStatusFilters}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={() => {
              confirm();
              applyTaskTypeFilters();
            }
          }
          />
        </div>
      </div>
    );
  };

  const applyTaskTypeFilters = (): void => {
    setParamsData((prev: FilterClientsType | null) => {
      const newParamsData: FilterClientsType = {
        ...prev,
        pub_customer_status: selectedClientStatus?.length ? `[${selectedClientStatus}]` : null,
        page: PaginationDefault.defaultPage,
      };

      dispatch(setClientsListingParams(newParamsData));
      setCurrentPage(PaginationDefault.defaultPage);
      dispatch(getClientsPageNumber(PaginationDefault.defaultPage));
      return newParamsData;
    });
  };

  //шапка таблицы
  const columns: ColumnsType<ClientsListingDataType> = [
    {
      title: tableTitle("Организация"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "name"],
      key: "name",
      width: "42.2%",
      className: css.tableHeader,
      filtered: !!selectedClientStatus?.length,
      filterDropdown: ({ confirm }) => filterStatus(confirm),
      onFilterDropdownOpenChange: (visible: boolean): void => {
        if (!visible) {
          applyTaskTypeFilters();
        }
      },
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "name"),
      }),
      render: (text: string, record: ClientsListingDataType) => (
        <Link
          className={`${css.tableCell} flex items-center ml-2`}
          to={`/clients/${record?.fields?.uuid}`}
        >
          <div>
            <Tooltip
              placement="topLeft"
              title={contentTooltip}
              trigger="hover"
              arrow={{pointAtCenter: true}}
            >
              {renderClientStatus(record?.fields?.status_client)}
            </Tooltip>
          </div>
          <a
            className={`${css.columnTextOrganization} ml-3`}
          >
            {text}
          </a>
        </Link>
      ),
    },
    {
      title: tableTitle("ИНН"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "inn"],
      key: "inn",
      width: "17.2%",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "inn"),
      }),
      render: renderClientInn,
    },
    {
      title: tableTitle("Система налогообложения"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "tax_system"],
      key: "tax_system",
      width: "21.3%",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "tax_system"),
      }),
      render: toClientCard,
    },
    {
      title: tableTitle("Срок обслуживания"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "service_expired"],
      key: "service_expired",
      width: "19.3%",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "client_tariff.service_life_months"),
      }),
      render: (_text: string, record: ClientsListingDataType) => {
        return (record.fields.service_expired && (
          <div className={`${css.tableCell} flex items-center gap-3`}>
            <Link
              className={css.columnText}
              to={`/clients/${record?.fields?.uuid}`}
            >
              {getMonthWord(record?.fields?.client_tariff?.[0]?.service_life_months)}
            </Link>
            <TagCustom
              color={checkServiceStatus(record.fields.service_expired)}
              text={deadlineDays(record.fields.service_expired) as string}
            />
          </div>
        ));
      },
    },
    //Временно скрываем столбцы листинга клиентов до их реализации на бэке
    // {
    //   title: tableTitle("Тариф"),
    //   dataIndex: ["fields", "client_tariff", "label"],
    //   key: "label",
    //   className: css.tableHeader,
    //   onHeaderCell: () => ({
    //     onClick: (e: React.MouseEvent<HTMLElement>) => handleSorterTableClients(e, "client_tariff.label"),
    //   }),
    //   render: renderClientTariff,
    // },
    // {
    //   title: "Регион обслуживания",
    //   dataIndex: ["fields", "address"],
    //   key: "address",
    //   className: css.tableHeader,
    //   render: toClientCard,
    // },
  ];

  const FilterNames: FilterNamesType = {
    opf: "ОПФ:",
    inn: "ИНН:",
    "client_tariff.service_life_months": "Период:",
    status_client: "Статус:",
    responsible_lookup: "Ответственный:",
    //пока моковые данные
    prepaid_date_gte: "Период начисления аванса:",
    salary_date_gte: "Период начисления зарплаты:",
    tax_system: "Система налогообложения:",
    agency_agreements_qty: "Агентские договоры:",
    online_desk_qty: "Онлайн кассы:",
    employees: "Сотрудники:",
    number_of_consultations: "Платные консультации:",
    accounting_restore_months: "Восстановление учета:",
    patent: "Патент:",
    trade_fee: "Торговый сбор:",
  };

  // логика пагинации страницы
  const [currentPage, setCurrentPage] = useState<number>(clientsPage);
  const [pageSize, setPageSize] = useState<number>(clientsPageSize);

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    dispatch(getClientsPageNumber(newPage));
    setParamsData({
      ...paramsData,
      page: newPage,
    });
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    const pageSize: number = newPageSize > 20 ? PaginationDefault.pagePerScroll : newPageSize;

    setPageSize(pageSize);
    dispatch(getClientsPageSize(pageSize));
    setCurrentPage(PaginationDefault.defaultPage);
    dispatch(getClientsPageNumber(PaginationDefault.defaultPage));
    setParamsData({
      ...paramsData,
      page: PaginationDefault.defaultPage,
      page_size: pageSize,
    });
  };

  const deleteFilterData = (e: React.MouseEvent<HTMLElement>, key: string): void => {
    e.preventDefault();
    setCurrentPage(PaginationDefault.defaultPage);
    dispatch(getClientsPageNumber(PaginationDefault.defaultPage));

    setFilterData(prev => {
      if (prev) {
        dispatch(setClientsListingFilter({...prev, [key]: null}));
        return {...prev, [key]: null};
      }

      dispatch(setClientsListingFilter(null));
      return null;
    });
    setParamsData((prev: FilterClientsType | null) => {
      const updatedParams: FilterClientsType | null = prev ? { ...prev, [key]: null } : null;

      if (key === "prepaid_date_gte" && updatedParams) updatedParams["prepaid_date_lte"] = null;
      if (key === "salary_date_gte" && updatedParams) updatedParams["salary_date_lte"] = null;

      if (updatedParams) {
        dispatch(setClientsListingParams(updatedParams));
        return updatedParams;
      }

      dispatch(setClientsListingParams(null));
      return null;
    });
  };

  const deleteResponsibleFilterData = (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    id: string,
    value: ResponsibleFilterType[]
  ): void => {
    e.preventDefault();
    const filterResponsibleData: ResponsibleFilterType[] =
     value.filter((resp: ResponsibleFilterType):boolean => resp.id !== id);
    
    setFilterData((prev: FilterClientsType | null) => {
      const returnResponsible: ResponsibleFilterType[] | null =
        filterResponsibleData.length ? filterResponsibleData : null;

      if (prev) {
        dispatch(setClientsListingFilter({ ...prev, [key]: returnResponsible }));
        return { ...prev, [key]: returnResponsible };
      }

      dispatch(setClientsListingFilter(null));
      return null;
    });

    setParamsData((prev: FilterClientsType | null) => {
      setCurrentPage(1);
      dispatch(getClientsPageNumber(PaginationDefault.defaultPage));
      const nameResponsible: string[] = filterResponsibleData.map((name: ResponsibleFilterType) => name.id);
      const returnResponsible: string | null = filterResponsibleData.length ? `[${nameResponsible}]` : null;

      const updatedParams =
        prev ? { ...prev, [key]: returnResponsible, page: 1 } : null;
      if (updatedParams) {
        dispatch(setClientsListingParams(updatedParams));
        return updatedParams;
      }

      dispatch(setClientsListingParams(null));
      return null;
    });
  };

  const clearFilterData = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setCurrentPage(PaginationDefault.defaultPage);
    dispatch(getClientsPageNumber(PaginationDefault.defaultPage));

    setFilterData(() => {
      dispatch(setClientsListingFilter(null));
      return null;
    });

    setParamsData(() => {
      const updateParams: FilterClientsType = {
        page_size: clientsPageSize,
        page: PaginationDefault.defaultPage,
        order_by: sortValue
      };

      dispatch(setClientsListingParams(updateParams));
      return updateParams;
    });
  };

  //статус клиента для фильтрации
  const statusValue = (value: string): string => {
    switch (value) {
      case ("Активный"):
        return "^ACTIVE";
      case ("Неактивный"):
        return "^NOT_ACTIVE";
      default:
        return "";
    }
  };

  //период обслуживания для фильтрации
  const periodValue = (value: string): number | null => {
    switch (value) {
      case ("6 месяцев"):
        return 6;
      case ("12 месяцев"):
        return 12;
      default:
        return null;
    }
  };

  const handleInnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue: string = event.target.value.replace(/\D/g, "").slice(0, 12);

    setInnData(inputValue);
  };

  const showModal = (): void => {
    setModalOpen(true);
  };

  const modalOk = (): void => {
    setCurrentPage(PaginationDefault.defaultPage);

    const {
      typeOPF,
      period,
      clientsStatus,
      typeTaxSystem,
      agencyAgreements,
      onlineDesk,
      employees,
      paidConsultations,
      accountRestoration,
      patent,
    } = values || {};
    const uuidResponsible: string[] = selectedResponsible.map((uuid: ResponsibleFilterType) => uuid.id);

    setParamsData(() => {
      const updateParams: FilterClientsType = {
        client_employees: clientsParams?.client_employees || null,
        opf: typeOPF ? `^${typeOPF}` : null,
        inn: innData ? innData : null,
        page_size: clientsPageSize,
        page: PaginationDefault.defaultPage,
        "client_tariff.service_life_months": period ? periodValue(period) : null,
        status_client: clientsStatus ? statusValue(clientsStatus) : null,
        responsible_lookup: selectedResponsible.length ? `[${uuidResponsible}]` : null,
        order_by: sortValue,
        //нет реализации на бэке, ждем согласования с заказчиком
        prepaid_date_gte: prepaidStartDate ? prepaidStartDate.slice(0, 10) : null,
        prepaid_date_lte: prepaidEndDate ? prepaidEndDate.slice(0, 10) : null,
        salary_date_gte: salaryStartDate ? salaryStartDate.slice(0, 10) : null,
        salary_date_lte: salaryEndDate ? salaryEndDate.slice(0, 10) : null,
        //конец моков
        tax_system: typeTaxSystem ? `^${typeTaxSystem}$` : null,
        agency_agreements_qty: agencyAgreements !== null ? (agencyAgreements ? "True" : "False") : null,
        online_desk_qty: onlineDesk !== null ? (onlineDesk ? "True" : "False") : null,
        employees: employees !== null ? (employees ? "True" : "False"): null,
        number_of_consultations: paidConsultations !== null ? (paidConsultations ? "True" : "False") : null,
        accounting_restore_months: accountRestoration !== null ? (accountRestoration ? "True" : "False") : null,
        patent: patent !== null ? (patent ? "True" : "False") : null,
      };

      dispatch(setClientsListingParams(updateParams));
      
      return updateParams;
    });
    
    setFilterData(() => {
      const newFilter: FilterClientsType = {
        opf: typeOPF || null,
        inn: innData ? innData : null,
        "client_tariff.service_life_months": period || null,
        status_client: clientsStatus || null,
        responsible_lookup: selectedResponsible.length ? selectedResponsible : null,
        //пока моковые данные
        prepaid_date_gte: (prepaidStartDate && prepaidEndDate)
          ? renderFullDate(prepaidStartDate, prepaidEndDate)
          : null,
        salary_date_gte: (salaryStartDate && salaryEndDate)
          ? renderFullDate(salaryStartDate, salaryEndDate)
          : null,
        //конец моков
        tax_system: typeTaxSystem ? typeTaxSystem : null,
        agency_agreements_qty: agencyAgreements !== null ? (agencyAgreements ? "Да" : "Нет") : null,
        online_desk_qty: onlineDesk !== null ? (onlineDesk ? "Да" : "Нет") : null,
        employees: employees !== null ? (employees ? "Да" : "Нет") : null,
        number_of_consultations: paidConsultations !== null ? (paidConsultations ? "Да" : "Нет") : null,
        accounting_restore_months: accountRestoration !== null ? (accountRestoration ? "Да" : "Нет") : null,
        patent: patent !== null ? (patent ? "Да" : "Нет") : null,
      };

      dispatch(setClientsListingFilter(newFilter));
      
      return newFilter;
    });

    setInnData("");
    setSelectedResponsible([]);
    setModalOpen(false);
    form.resetFields();
  };

  const modalCancel = (): void => {
    setInnData("");
    setSelectedResponsible([]);
    setModalOpen(false);
    form.resetFields();
  };

  const modalTitle: JSX.Element = (
    <div className={css.modalHeader}>
      Фильтры
    </div>
  );

  const buttonAccess = (): boolean => {
    const values = form.getFieldsValue([
      "typeOPF",
      "typeTaxSystem",
      "clientsStatus",
      "period",
      "selectResponsible",
      "agencyAgreements",
      "onlineDesk",
      "employees",
      "paidConsultations",
      "accountRestoration",
      "patent",
    ]);

    return Object.values(values).some(value =>
      value !== null &&
      value !== undefined &&
      !(Array.isArray(value) && value.length === 0)
    ) || !!innData;
  };

  const footerModal: JSX.Element = (
    <div className={css.modalFooter}>
      <div key="modalBotton" className={`${css.modalFooterButton}`}>
        <ButtonCustom
          className={css.buttonStyle}
          key="cancel"
          size="large"
          type="default"
          text="Отменить"
          onClick={modalCancel}
        />
        <ButtonCustom
          className={css.buttonStyle}
          key="ok"
          size="large"
          text="Применить"
          type="primary"
          onClick={modalOk}
          disabled={!buttonAccess()}
        />
      </div>
    </div>
  );

  const responsibleTagRender = (props: any): JSX.Element => {
    interface IDateType {
      id: string;
      name: string;
    }

    const { value, closable, onClose } = props;

    let data: IDateType | null = null;
    try {
      if (typeof value === "string" && value.trim().startsWith("{") && value.trim().endsWith("}")) {
        data = JSON.parse(value);
      }
    } catch (error) {
      console.error("Error parsing JSON value:", value, error);
    }

    return (
      <Tag
        className={css.blockResponsibleTag}
        closable={closable}
        onClose={onClose}
      >
        {data?.name}
      </Tag>
    );
  };

  const filterRole: boolean =
    roleResolver(decodedToken).isAdmin
    || roleResolver(decodedToken).isContactCenter
    || roleResolver(decodedToken).isQualityControl
    || false;

  const handleSelectChange = (values: string[]): void => {
    const newSelectedResponsible: ResponsibleFilterType[] = values.map((value: string) => JSON.parse(value));
    setSelectedResponsible(newSelectedResponsible);
  };

  const filterOption = (inputValue: string, option: any): boolean => {
    const value = JSON.parse(option.value);
    const user: UserListingType | undefined =
      usersListing?.find((user: UserListingType): boolean => user.id.toString() === value.id);

    if (user) {
      const fullName: string =
        `${user?.attributes?.last_name} ${user?.attributes?.first_name} ${user?.attributes?.second_name}`;
      return fullName.toLowerCase().includes(inputValue.toLowerCase());
    }

    return false;
  };

  const placeholderSelect = (text: string): JSX.Element => {
    return (
      <div className="flex justify-between items-center">
        <div>
          {text}
        </div>
        <SearchIcon className={css.searchIconInput}/>
      </div>
    );
  };

  const handlePrepaidDateChange = (dates: null | (Dayjs | null)[]): void | null => {
    if (dates && dates[0] && dates[1]) {
      setPrepaidStartDate(dates[0].format("YYYY-MM-DD"));
      setPrepaidEndDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setPrepaidStartDate(null);
      setPrepaidEndDate(null);
    }
  };

  const handleSalaryDateChange = (dates: null | (Dayjs | null)[]): void | null => {
    if (dates && dates[0] && dates[1]) {
      setSalaryStartDate(dates[0].format("YYYY-MM-DD"));
      setSalaryEndDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setSalaryStartDate(null);
      setSalaryEndDate(null);
    }
  };

  const [formData, setFormData] = useState<FormInstance | null>(null);

  useEffect(() => {
    form.validateFields();
  }, [formData]);

  const onReset = (text: string): void => {
    setFormData((prevState: FormInstance | null): FormInstance | null => {
      if (prevState) {
        return {
          ...prevState,
          [text]: null,
        };
      }
      return prevState;
    });
    form.resetFields([text]);
  };

  const onValuesChange = (_: unknown, allValues: FormInstance): void => {
    setFormData(allValues);
  };

  const isFieldBoolean = (fieldName: string): boolean => {
    return typeof form.getFieldValue(fieldName) === "boolean";
  };

  const renderFilterOption = (value: string, name: string): JSX.Element => {
    return (
      <div className="mb-5">
        <div className="flex justify-between items-center">
          <div className={`${css.modalTextTask} mb-2`}>
            {value}
          </div>
          {isFieldBoolean(name) && (
            <ButtonCustom
              type="link"
              text="Сбросить"
              className="mb-2 p-0"
              onClick={() => onReset(name)}
            />
          )}
        </div>
        <Form.Item name={name} initialValue={null} noStyle>
          <Radio.Group>
            <Radio value={true}>Да</Radio>
            <Radio value={false}>Нет</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
    );
  };

  const initialValues: FormPropsType = {
    typeOPF: null,
    period: null,
    clientsStatus: null,
    typeTaxSystem: null,
    agencyAgreements: null,
    onlineDesk: null,
    employees: null,
    paidConsultations: null,
    accountRestoration: null,
    patent: null,
    clientsName: clientsParams?.client_employees ?? null,
  };

  const ClientsListingComponent = (form: FormInstance): JSX.Element => {
    const clientsListingProps: ClientsListingType = {
      paramsData,
      setParamsData,
      filterData,
      columns,
      isLoadingData,
      currentPage,
      pageSize,
      selectedClientStatus,
      FilterNames,
      handleInputChange,
      deleteResponsibleFilterData,
      deleteFilterData,
      showModal,
      clearFilterData,
      handlePageChange,
      handlePageSizeChange
    };

    return <ClientsListing form={form} clientsListingProps={clientsListingProps}/>;
  };

  return (
    !isListingLoading ?
      (
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
        >
          <div className="w-full flex justify-between pt-4 px-4 items-center">
            <h1 className={css.containerHeader}>Клиенты</h1>
            <ExportList variant="клиенты" />
          </div>
          <div className={css.container}>
            {ClientsListingComponent(form)}
            <Drawer
              title={modalTitle}
              footer={footerModal}
              placement="right"
              onClose={modalCancel}
              open={isModalOpen}
            >
              <div className={css.modalContainer}>
                <div className={`${css.modalText} mb-5`}>
                  Отфильтруйте клиентов по необходимым параметрам
                </div>
                <div className="mb-5">
                  <div className={`${css.modalTextTask} mb-2`}>По типу (ОПФ)</div>
                  <Form.Item name="typeOPF" className="mb-0 mt-0">
                    <Select
                      id="typeOPF"
                      placeholder="ОПФ"
                      options={itemsTypeOPF}
                      showSearch
                      allowClear
                      size="large"
                      className={css.modalDrop}
                      notFoundContent={<ZeroSearch dataFilter />}
                    />
                  </Form.Item>
                </div>
                <div className="mb-5">
                  <div className={`${css.modalTextTask} mb-2`}>По системе налогообложения</div>
                  <Form.Item name="typeTaxSystem" className="mb-0 mt-0">
                    <Select
                      id="typeTaxSystem"
                      placeholder="Налогообложение"
                      options={itemsTaxSystem}
                      showSearch
                      allowClear
                      size="large"
                      className={css.modalDrop}
                      notFoundContent={<ZeroSearch dataFilter />}
                    />
                  </Form.Item>
                </div>
                <div className="mb-5">
                  <div className={`${css.modalTextTask} mb-2`}>По статусу</div>
                  <Form.Item name="clientsStatus" className="mb-0 mt-0">
                    <Select
                      id="clientsStatus"
                      placeholder="Статус клиента"
                      options={itemsStatus}
                      showSearch
                      allowClear
                      size="large"
                      className={css.modalDrop}
                      notFoundContent={<ZeroSearch dataFilter />}
                    />
                  </Form.Item>
                </div>
                <div className="mb-5">
                  <div className={`${css.modalTextTask} mb-2`}>По ИНН</div>
                  <Input
                    id="inn"
                    placeholder="Номер ИНН"
                    className={css.modalInput}
                    value={innData}
                    onChange={handleInnChange}
                  />
                </div>
                <div className="mb-5">
                  <div className={`${css.modalTextTask} mb-2`}>По сроку обслуживания</div>
                  <Form.Item name="period" className="mb-0 mt-0">
                    <Select
                      id="period"
                      placeholder="Выберите срок обслуживания"
                      options={itemsPeriod}
                      showSearch
                      allowClear
                      size="large"
                      className={css.modalDrop}
                    />
                  </Form.Item>
                </div>
                {filterRole ? (
                  <>
                    <div className="mb-5">
                      <div className={`${css.modalTextTask} mb-2`}>По ответственному сотруднику</div>
                      <Form.Item name="selectResponsible" className="mt-0 mb-0">
                        <Select
                          id="selectResponsible"
                          mode="multiple"
                          showSearch
                          className={css.selectImplementer}
                          placeholder={placeholderSelect("Выберите сотрудника")}
                          size="large"
                          suffixIcon={null}
                          onChange={handleSelectChange}
                          filterOption={filterOption}
                          notFoundContent={<ZeroSearch dataFilter />}
                          value={selectedResponsible.map((responsible: ResponsibleFilterType
                          ) => JSON.stringify(responsible))}
                          tagRender={responsibleTagRender}
                        >
                          {filterResponsible(usersListing)
                            ?.map(({ id, attributes }: UserListingType) => {
                              const { position, last_name = "", first_name = "", second_name = "" } = attributes;

                              return (
                                <Option
                                  className={css.responsible}
                                  key={id}
                                  value={JSON.stringify({
                                    id: id.toString(),
                                    name: `${last_name} ${first_name} ${second_name}`,
                                  })}
                                >
                                  <div>
                                    <div className={css.blockPosition}>
                                      {position}
                                    </div>
                                    <div className={css.blockResponsible}>
                                      <div>
                                        {last_name}{" "}{first_name}{" "}{second_name}
                                      </div>
                                    </div>
                                  </div>
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                  </>
                ) : null}
                {/*TODO нет данных полей на бэке, ожидаем доработки*/}
                {/*<div className="mb-[20px]">*/}
                {/*  <div className={`${css.modalTextTask} mb-[8px]`}>Дата начисления аванса</div>*/}
                {/*  <Form.Item name="prepaidDate" className="mt-0 mb-0">*/}
                {/*    <RangePicker*/}
                {/*      id="prepaidDate"*/}
                {/*      size="large"*/}
                {/*      className="w-full mb-2"*/}
                {/*      format="DD.MM.YYYY"*/}
                {/*      placeholder={["С даты", "По дату"]}*/}
                {/*      onChange={(dates: null | (Dayjs | null)[]): void | null => {*/}
                {/*        if (dates) {*/}
                {/*          handlePrepaidDateChange(dates);*/}
                {/*        } else {*/}
                {/*          setPrepaidStartDate(null);*/}
                {/*          setPrepaidEndDate(null);*/}
                {/*        }*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </Form.Item>*/}
                {/*</div>*/}
                {/*<div className="mb-[20px]">*/}
                {/*  <div className={`${css.modalTextTask} mb-[8px]`}>Дата начисления заработной платы</div>*/}
                {/*  <Form.Item name="salaryDate" className="mt-0 mb-0">*/}
                {/*    <RangePicker*/}
                {/*      id="salaryDate"*/}
                {/*      size="large"*/}
                {/*      className="w-full mb-2"*/}
                {/*      format="DD.MM.YYYY"*/}
                {/*      placeholder={["С даты", "По дату"]}*/}
                {/*      onChange={(dates: null | (Dayjs | null)[]): void | null => {*/}
                {/*        if (dates) {*/}
                {/*          handleSalaryDateChange(dates);*/}
                {/*        } else {*/}
                {/*          setSalaryStartDate(null);*/}
                {/*          setSalaryEndDate(null);*/}
                {/*        }*/}
                {/*      }}*/}
                {/*    />*/}
                {/*  </Form.Item>*/}
                {/*</div>*/}
                {renderFilterOption("Наличие агентских договоров", "agencyAgreements")}
                {renderFilterOption("Наличие онлайн касс", "onlineDesk")}
                {renderFilterOption("Наличие сотрудников", "employees")}
                {renderFilterOption("Наличие платных консультаций", "paidConsultations")}
                {renderFilterOption("Наличие восстановления учета", "accountRestoration")}
                {renderFilterOption("Наличие патента", "patent")}
                {/*TODO Ожидает доработки на бэке, как только согласуют с заказчиком*/}
                {/*<div className="mb-[20px]">*/}
                {/*  <div className="flex justify-between items-center">*/}
                {/*    <div className={`${css.modalTextTask} mb-[8px]`}>*/}
                {/*      Наличие торгового сбора*/}
                {/*    </div>*/}
                {/*    {isFieldBoolean("tradeFee") && (*/}
                {/*      <ButtonCustom*/}
                {/*        type="link"*/}
                {/*        text="Сбросить"*/}
                {/*        className="mb-[8px] p-[0]"*/}
                {/*        onClick={() => onReset("tradeFee")}*/}
                {/*      />*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*<Form.Item name="tradeFee" initialValue={null} noStyle>*/}
                {/*  <Radio.Group>*/}
                {/*    <Radio value={true}>Да</Radio>*/}
                {/*    <Radio value={false}>Нет</Radio>*/}
                {/*  </Radio.Group>*/}
                {/*</Form.Item>*/}
                {/*</div>*/}
              </div>
            </Drawer>
          </div>
        </Form>
      ) : (
        <div className="text-center mt-96">
          <LoadingCustom />
        </div>
      )
  );
};

export default Clients;
