import { ChangeEvent } from "react";
import { FormInstance } from "antd";

export const handleDigitsOnlyChange = (
  event: ChangeEvent<HTMLInputElement> | number | string | null,
  fieldName: string,
  form: FormInstance,
): void => {
  let inputValue: string | number | null;

  if (typeof event !== "number" && typeof event !== "string" && event !== null) {
    // Разрешаем только цифры и точки, заменяем запятые на точки
    inputValue = event.target.value
    ?.replace(/[^\d.]/g, "")
    ?.replace(",", ".");
  } else {
    inputValue = event;
  }

  if (inputValue === "0" && fieldName !== "rate") {
    form.setFieldValue(fieldName, null);
  } else {
    form.setFieldValue(fieldName, inputValue);
  }
};
