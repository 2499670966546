export interface ITaskActionsProps {
  key?: number,
  value: string,
  label: string,
  disabled?: boolean,
}

export const taskActions:ITaskActionsProps[] = [
  {
    value: "Отправить на уточнение",
    label: "Отправить на уточнение",
  },
  {
    value: "Обработать задачу автоматически",
    label: "Обработать задачу автоматически",
  },
  {
    value: "Обработать задачу вручную",
    label: "Обработать задачу вручную",
  },
  {
    value: "Сменить исполнителя",
    label: "Сменить исполнителя",
  },
  {
    value: "Переназначить задачу",
    label: "Переназначить задачу",
  },
  {
    value: "Выбрать тему обращения",
    label: "Выбрать тему обращения",
  },
  {
    value: "Распознать документы в КНАП",
    label: "Распознать документы в КНАП",
  },
  //Временно комментируем, бп будет реализован позже
  //{
  //  value: "increasePriority",
  //  label: "Повысить приоритет",
  //},
  {
    value: "closeTask",
    label: "Завершить задачу",
  },
];

export const topicOfAppeal:ITaskActionsProps[] = [
  {
    key: 1,
    value: "Общие вопросы по сервису",
    label: "Общие вопросы по сервису",
  },
  {
    key: 2,
    value: "Получение документов для обслуживания",
    label: "Получение документов для обслуживания",
  },
  {
    key: 3,
    value: "Справочная информация по работе в сервисе",
    label: "Справочная информация по работе в сервисе",
  },
  {
    key: 4,
    value: "Технический вопрос",
    label: "Технический вопрос",
  },
  {
    key: 5,
    value: "Справочная информация по ЛКП",
    label: "Справочная информация по ЛКП",
  },
  {
    key: 6,
    value: "Система налогообложения",
    label: "Система налогообложения",
  },
  {
    key: 7,
    value: "Справочная информация по работе с Маркетплейсом",
    label: "Справочная информация по работе с Маркетплейсом",
  },
  {
    key: 8,
    value: "Отчетность Маркетплейса",
    label: "Отчетность Маркетплейса",
  },
  {
    key: 9,
    value: "Вопрос к бухгалтеру",
    label: "Вопрос к бухгалтеру",
  },
  {
    key: 10,
    value: "Налоговый вычет",
    label: "Налоговый вычет",
  },
  {
    key: 11,
    value: "Календарь налоговых событий",
    label: "Календарь налоговых событий",
  },
  {
    key: 12,
    value: "Получение ЭЦП",
    label: "Получение ЭЦП",
  },
  {
    key: 13,
    value: "Вопрос по СНО",
    label: "Вопрос по СНО",
  },
  {
    key: 14,
    value: "Справочная информация по обращению",
    label: "Справочная информация по обращению",
  },
  {
    key: 15,
    value: "Уточнение статуса обработки документов",
    label: "Уточнение статуса обработки документов",
  },
  {
    key: 16,
    value: "Не поступил расчет",
    label: "Не поступил расчет",
  },
  {
    key: 17,
    value: "Восстановление отчетности",
    label: "Восстановление отчетности",
  },
  {
    key: 18,
    value: "Сдача отчетности",
    label: "Сдача отчетности",
  },
  {
    key: 19,
    value: "Претензия к работе сервиса",
    label: "Претензия к работе сервиса",
  },
  {
    key: 20,
    value: "Претензия к работе бухгалтера",
    label: "Претензия к работе бухгалтера",
  },
  {
    key: 21,
    value: "Возврат за услуги",
    label: "Возврат за услуги",
  },
  {
    key: 22,
    value: "Ликвидация ИП",
    label: "Ликвидация ИП",
  },
  {
    key: 23,
    value: "Вопрос по Единому налоговому платежу (ЕНП)",
    label: "Вопрос по Единому налоговому платежу (ЕНП)",
  },
  {
    key: 24,
    value: "Вопрос по Единому налоговому счету (ЕНС)",
    label: "Вопрос по Единому налоговому счету (ЕНС)",
  },
  {
    key: 25,
    value: "Уведомление об исчисленных налогах и взносах",
    label: "Уведомление об исчисленных налогах и взносах",
  },
  {
    key: 26,
    value: "КУДиР",
    label: "КУДиР",
  },
  {
    key: 27,
    value: "Декларация УСН",
    label: "Декларация УСН",
  },
  {
    key: 28,
    value: "Декларация 3-НДФЛ",
    label: "Декларация 3-НДФЛ",
  },
  {
    key: 29,
    value: "Расчет авансового платежа по сотруднику",
    label: "Расчет авансового платежа по сотруднику",
  },
  {
    key: 30,
    value: "Расчет заработной платы сотрудника",
    label: "Расчет заработной платы сотрудника",
  },
  {
    key: 31,
    value: "Расчет отпускных сотруднику",
    label: "Расчет отпускных сотруднику",
  },
  {
    key: 32,
    value: "Платежное поручения для уплаты налогов и взносов",
    label: "Платежное поручения для уплаты налогов и взносов",
  },
  {
    key: 33,
    value: "Отчеты по сотрудникам в налоговую  и социальный фонд России",
    label: "Отчеты по сотрудникам в налоговую  и социальный фонд России",
  },
  {
    key: 34,
    value: "Заявление на уменьшение налога",
    label: "Заявление на уменьшение налога",
  },
  {
    key: 35,
    value: "Декларации по имуществу ООО",
    label: "Декларации по имуществу ООО",
  },
  {
    key: 36,
    value: "Декларация НДС",
    label: "Декларация НДС",
  },
  {
    key: 37,
    value: "Декларация по налогу на прибыль",
    label: "Декларация по налогу на прибыль",
  },
  {
    key: 38,
    value: "Платные консультации",
    label: "Платные консультации",
  },
  {
    key: 109,
    value: "Задача по уточнению данных обращения",
    label: "Задача по уточнению данных обращения",
  },
];

export const taskActionsType: ITaskActionsProps[] = [
  {
    value: "Запрос КУДиР",
    label: "Запрос КУДиР",
  },
  {
    value: "Формирование декларации УСН",
    label: "Формирование декларации УСН",
  },
  {
    value: "Формирование декларации по имуществу ООО",
    label: "Формирование декларации по имуществу ООО",
  },
  {
    value: "Формирование единой упрощённой декларации",
    label: "Формирование единой упрощённой декларации",
  },
  {
    value: "Формирование декларации 3-НДФЛ для ИП на ОСНО",
    label: "Формирование декларации 3-НДФЛ для ИП на ОСНО",
  },
  {
    value: "Формирование декларации НДС",
    label: "Формирование декларации НДС",
  },
  {
    value: "Формирование декларации по налогу на прибыль",
    label: "Формирование декларации по налогу на прибыль",
  },
  {
    value: "Формирование уведомления об исчисленных налогах",
    label: "Формирование уведомления об исчисленных налогах",
  },
  {
    value: "Задача по расчету авансового платежа по сотруднику",
    label: "Задача по расчету авансового платежа по сотруднику",
  },
  {
    value: "Задача по расчету заработной платы сотрудника",
    label: "Задача по расчету заработной платы сотрудника",
  }
];
