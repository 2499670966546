import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Select,
  Skeleton,
  Tooltip,
  UploadFile,
  UploadProps,
} from "antd";
import ZeroSearch from "../../../ZeroSearch/ZeroSearch";
import DraggerForm from "../../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import {
  declarationTopic,
  IContactingToClientProps,
  ITaskTypeProps
} from "../../ClientCardItem/Tasks/contactingToClient";
import { ReactComponent as AttachFileIcon } from "../../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../../../assets/icons/delete_file.svg";
import {
  TypicalRequestToClientType,
  DocumentClientType,
  ParamsGetUuid1CType,
  ParamsGetFile1CType,
} from "app/types";
import { deleteUploadFile, uploadFiles } from "../../../../../api/document.api";
import { validFileFormat } from "../../../../../utils/ModalUploadItems/validFileFormat";
import { UploadChangeParam } from "antd/es/upload";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { sendTypicalRequestToClient } from "../../../../../actions/client.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { getTaskFile1C, getTaskUuid1CData } from "../../../../../api/tasks.api";
import FetchDeclarationFilesStatuses from "./utils/FetchDeclarationFilesStatuses";
import dayjs, { Dayjs } from "dayjs";
import css from "../ClientCardActionDrawer.module.css";
import { downloadFile, downloadUrl } from "../../../../../utils/downloadFile";

const DECLARATION_CATEGORY_CODE: string | undefined = process.env.REACT_APP_DECLARATION_CATEGORY_CODE;

interface IInformationToClientProps {
  form: FormInstance;
  filteredTopic: IContactingToClientProps[];
  drawerClientAction: string;
  setDrawerClientAction: Dispatch<SetStateAction<string>>;
  correctSelectWidth: number;
}

interface IUploadedFilesProps {
  file_name: string;
  uuid: string;
}

type DatesArray = (Dayjs | null)[] | null;

enum totalFiles {
  Total100 = 100
}

enum FileListing {
  Empty = 0
}

const maxSizeFile: number = 50;
const notDeclarationCodes: number[] = [210, 205, 211, 208, 212, 213, 209, 206, 214];
const declarationCodes: number[] = [202, Number(DECLARATION_CATEGORY_CODE)];
const dateFormat: string = "YYYY-MM-DD";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const InformationToClient: FC<IInformationToClientProps> = ({
  form,
  filteredTopic,
  drawerClientAction,
  setDrawerClientAction,
  correctSelectWidth
}): JSX.Element => {
  const [uploadedFilesData, setUploadedFilesData] = useState<IUploadedFilesProps[] | null>(null);
  const [fileCount, setFileCount] = useState<number>(0);
  const [uploadFilesLoader, setUploadFilesLoader] = useState<boolean>(false);
  const [selectedActionCode, setSelectedActionCode] = useState<number | null>(null);
  const [selectedDeclarationCode, setSelectedDeclarationCode] = useState<number | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [isFetchDocsBtnDisabled, setFetchDocsBtnDisabled] = useState<boolean>(true);
  const [clientGuid, setClientGuid] = useState<string>("");
  const [fetchedDeclarationFileStatus, setFetchedDeclarationFileStatus] = useState<string>("");
  const [fetchedDeclarationFiles, setFetchedDeclarationFiles] = useState<IUploadedFilesProps[] | null>(null);
  const [isFilesLoading, setIsFilesLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const documentClient: DocumentClientType | null = useSelector((state: AppStateType) => state.client.documentClient);
  
  const values = Form.useWatch([], form);
  const { topic, textArea } = values || {};

  const textAreaLength: number = textArea?.length || 0;
  const isDeclaration: boolean = !notDeclarationCodes?.includes(selectedActionCode as number)
    && drawerClientAction === "Предоставление информации";
  const isDeclarationToSend: boolean = declarationCodes?.includes(selectedActionCode as number);
  const hasInformationToClientValues: boolean = !(topic && textArea);
  const clientUuid: string | undefined = documentClient?.fields?.uuid;
  const clientInn: string | null = documentClient?.fields?.inn ?? null;
  const hasDeclarationValues: boolean = !topic
    || !textArea
    || !uploadedFilesData?.length
    || isFetchDocsBtnDisabled;

  const toolTipText: string = isFetchDocsBtnDisabled
    ? `Для получения файлов необходимо выбрать ${isDeclarationToSend ? "тип декларации и" : ""} период`
    : "";

  useEffect(() => {
    if (drawerClientAction) {
      setUploadedFilesData(null);
      setFileCount(0);
    }
  }, [drawerClientAction]);

  useEffect(() => {
    const hasAllValues: boolean = !!selectedDeclarationCode && !!endDate && !!startDate;
    const hasInfoValues: boolean = !!endDate && !!startDate;

    setFetchDocsBtnDisabled(isDeclarationToSend ? !hasAllValues : !hasInfoValues);
  }, [selectedActionCode, selectedDeclarationCode, endDate, startDate]);

  useEffect(() => {
    if (clientGuid) {
      fetchDeclarationFiles();
      setIsFilesLoading(true);
    }
  }, [clientGuid]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (fetchedDeclarationFileStatus === "PROCESSING") {
      intervalId = setInterval(fetchDeclarationFiles, 5000);
    }

    if (fetchedDeclarationFiles) {
      setIsFilesLoading(false);
    }

    return () => clearTimeout(intervalId);
  }, [fetchedDeclarationFileStatus]);

  const fetchDeclarationFiles = (): void => {
    const fetchDeclarationFilesBody: ParamsGetFile1CType = {
      client_uuid: clientUuid ?? null,
      one_s_task_uuid: clientGuid
    };

    getTaskFile1C(fetchDeclarationFilesBody)
      ?.then((response) => {
        setFetchedDeclarationFileStatus(response?.data?.status ?? "");
        setFetchedDeclarationFiles(response?.data?.files ?? null);
        setUploadedFilesData(response?.data?.files ?? null);
      })
      ?.catch((err) => console.error("Fetch declaration files err ", err));
  };
  
  const onDrawerClose = (): void => {
    setDrawerClientAction("");
    setUploadedFilesData(null);
    setFileCount(0);
  };
  
  const handleSelectTopic = (
    value: string,
    option: IContactingToClientProps | IContactingToClientProps[] | undefined
  ): void => {
    const actionCode: number | null = (value && !Array.isArray(option) && option?.key) ? option.key : null;
    
    setSelectedActionCode(actionCode);
  };

  const handleDeclarationType = (
    value: string,
    option: ITaskTypeProps | ITaskTypeProps[] | undefined
  ): void => {
    const actionCode: number | null = (value && !Array.isArray(option) && option?.key) ? option.key : null;

    setSelectedDeclarationCode(actionCode);
  };

  const handleDateChange = (dates: DatesArray): void => {
    if (dates?.[0] && dates?.[1]) {
      setStartDate(dates[0].format(dateFormat));
      setEndDate(dates[1].format(dateFormat));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const uploadFile = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoader(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesData((prevData: IUploadedFilesProps[] | null) => {
        const safePrevData: IUploadedFilesProps[] = prevData || [];
        let newData: IUploadedFilesProps[] = [...safePrevData];
        let newFileCount: number = safePrevData.length;

        response.data.forEach((file: IUploadedFilesProps): void => {
          if (!newData.some(({ uuid }): boolean => uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });

        if (newData.length > 100) {
          newData = newData.slice(0, 100);
          newFileCount = 100;
        }

        setFileCount(newFileCount);

        return newData;
      });

      if (response.status === 200) {
        setUploadFilesLoader(false);
      }
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoader(false);
    }
  };

  const handleUploadChange = (info: UploadChangeParam): boolean => {
    if (info.fileList.length > 100) {
      info.fileList.splice(100);
    }

    const allFilesUploading: boolean = info.fileList.every(
      ({ status }): boolean => status === "uploading"
    );

    if (allFilesUploading) {
      const filterFile: UploadFile<unknown>[] = info.fileList.filter((file: UploadFile<unknown>): boolean => {
        if (!file) return false;

        const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
        return size < maxSizeFile;
      });

      if (filterFile.length < info.fileList.length) {
        message.error("Объем некоторых файлов или файла не должен превышать 50мб.");
      }

      const files: File[] = filterFile.map(({ originFileObj }) => originFileObj!) as File[];

      if (files.length > 0) {
        uploadFile(files);
      }
    }
    info.fileList.length = 0;
    return false;
  };

  const propsFile: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: handleUploadChange,
    showUploadList: false
  };

  const counterUpload = (fileCountClarification: number): boolean =>
    fileCountClarification === totalFiles.Total100 || fileCountClarification > totalFiles.Total100;

  const showUpload: boolean = fileCount >= totalFiles.Total100;

  const handleRemove = async (e: React.MouseEvent<HTMLElement>, fileRemove: IUploadedFilesProps): Promise<void> => {
    e.preventDefault();

    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove.uuid);

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setUploadedFilesData((prevFiles: IUploadedFilesProps[] | null): IUploadedFilesProps[] | null => {
        if (prevFiles) {
          const newFiles: IUploadedFilesProps[] = prevFiles.filter(
            ({ uuid }): boolean => uuid !== fileRemove.uuid
          );
          setFileCount(newFiles.length);
          return newFiles.length > 0 ? newFiles : null;
        }
        setFileCount(0);
        
        return [];
      });
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const uploadFilesList: JSX.Element[] | null = uploadedFilesData?.length
    ? uploadedFilesData.map((file: IUploadedFilesProps, index: number) => (
        <div key={index} className={css.documentsUpload}>
          <div className="flex items-center pl-[2px]">
            <AttachFileIcon className="flex-shrink-0" />
            <a
              className={css.fileName}
              onClick={() => downloadFile(file?.file_name, downloadUrl(file?.uuid))}
            >
              {file.file_name}
            </a>
          </div>
          <div
            className={css.deleteIcon}
            onClick={(e: React.MouseEvent<HTMLElement>) => handleRemove(e, file)}
          >
            <DeleteDocument className="flex-shrink-0" />
          </div>
        </div>
      ))
    : null;
  
  const onClientActionFinish = (): void => {
    const selectedAction: number | null = selectedActionCode ? selectedActionCode : null;
    const uuids: string[] = uploadedFilesData?.map(({ uuid }) => uuid) ?? [];
    
    const clientRequest: TypicalRequestToClientType = {
      client: clientUuid,
      category_code: selectedAction,
      task_content: textArea,
      files: uuids,
      ...(isDeclarationToSend
        && {
            declaration_type: String(selectedDeclarationCode),
            declaration_start_date: startDate,
            declaration_end_date: endDate,
          }
        )
    };

    dispatch(sendTypicalRequestToClient(clientRequest));
    onDrawerClose();
  };

  const getClientGuid = (): void => {
    const fetchingClientGuidBody: ParamsGetUuid1CType = {
      client_uuid: clientUuid,
      start_date: startDate,
      end_date: endDate,
      report_type: isDeclarationToSend ? String(selectedDeclarationCode) : String(selectedActionCode),
      inn: clientInn,
    };

    getTaskUuid1CData(fetchingClientGuidBody)
      ?.then((response) => setClientGuid(response?.data?.guid))
      ?.catch((err) => console.error("getTaskUuid1CData", err));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onClientActionFinish}
    >
      <Form.Item
        label="Тема"
        name="topic"
        className="mt-0 mb-4"
        rules={[
          {
            required: true,
            message: "Пожалуйста, выберите тему"
          }
        ]}
        colon={false}
      >
        <Select
          placeholder="Выберите тему задачи"
          options={filteredTopic}
          listHeight={196}
          showSearch
          allowClear
          size="large"
          style={{ width: `${correctSelectWidth}px` }}
          onChange={handleSelectTopic}
          notFoundContent={<ZeroSearch dataFilter />}
        />
      </Form.Item>
      {!isDeclaration && (
        <div className={css.modalText}>Напишите ваше сообщение и прикрепите файлы, если это необходимо</div>
      )}
      {isDeclaration && (
        <>
          {isDeclarationToSend && (
            <Form.Item
              label="Тип декларации"
              name="declaretion"
              className="mt-0 mb-4"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите тип декларации"
                }
              ]}
              colon={false}
            >
              <Select
                placeholder="Выберите тип декларации"
                options={declarationTopic}
                listHeight={196}
                showSearch
                allowClear
                size="large"
                onChange={handleDeclarationType}
                notFoundContent={<ZeroSearch dataFilter />}
              />
            </Form.Item>
          )}
          <Form.Item label="Период" name="datePeriod" className="mb-4" required>
            <RangePicker
              id="datePeriod"
              size="large"
              className="w-full"
              format="DD.MM.YYYY"
              placeholder={["С даты", "По дату"]}
              maxDate={dayjs()}
              onChange={(dates: DatesArray): void | null => handleDateChange(dates)}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Комментарий"
        name="textArea"
        className="mt-0 mb-0"
        rules={[{
          required: true,
          message: "Пожалуйста, введите комментарий"
        }]}
        colon={false}
        layout="vertical"
      >
        <TextArea
          rows={7}
          maxLength={2000}
          className="text-base font-normal leading-5 text-gray-900"
          placeholder="Ваш комментарий клиенту"
        />
      </Form.Item>
      <div className="mb-4 text-xs font-normal leading-100 text-gray-700 text-right">{textAreaLength}/2000</div>
      {isDeclaration && !fetchedDeclarationFiles && (
        !isFilesLoading ? (
          <Tooltip
            title={toolTipText}
            overlayInnerStyle={{ width: isDeclarationToSend ? "500px" : "365px" }}
            align={{
              targetOffset: isDeclarationToSend ? ["50%", "0%"] : ["45%", "0%"],
            }}
            placement="topRight"
          >
            <div className="w-full mt-0 mb-4">
              <ButtonCustom
                type="primary"
                text="Запросить документы из 1С"
                size="large"
                className="w-full"
                onClick={getClientGuid}
                disabled={isFetchDocsBtnDisabled}
              />
            </div>
          </Tooltip>
        ) : <FetchDeclarationFilesStatuses isLoading />
      )}
      {isDeclaration && fetchedDeclarationFiles && (
        <>
          {!fetchedDeclarationFiles?.length && (
            <ButtonCustom
              type="primary"
              text="Обработать повторно"
              size="large"
              className="w-full mb-4"
              onClick={getClientGuid}
              disabled={isFetchDocsBtnDisabled}
            />
          )}
          <FetchDeclarationFilesStatuses isFetched hasFiles={!!fetchedDeclarationFiles?.length} />
        </>
      )}
      {!showUpload && <DraggerForm props={{ ...propsFile }} fileCount={fileCount} counterUpload={counterUpload} />}
      <div className={`${css.fileListing} grid justify-items-start items-center`}>
        {uploadFilesLoader ? (
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              width: "100%"
            }}
          />
        ) : (
          uploadFilesList
        )}
      </div>
      <Form.Item className="mt-2 text-right">
        <ButtonCustom
          className="mr-2"
          size="large"
          type="default"
          ghost
          text="Отменить"
          onClick={onDrawerClose}
        />
        <ButtonCustom
          size="large"
          type="primary"
          text="Подтвердить"
          htmlType="submit"
          disabled={isDeclaration ? hasDeclarationValues : hasInformationToClientValues}
        />
      </Form.Item>
    </Form>
  );
};

export default InformationToClient;
