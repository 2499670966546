import React, {
  FC,
  JSX,
  ChangeEvent,
  useContext,
  RefObject,
  useState,
  useRef,
} from "react";
import { validateTriggersArr } from "../../ClientProfileCardImageViewer";
import {
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Tooltip
} from "antd";
import { isFormItemRequiredRule } from "../../../../../utils/isFormItemRequiredRule";
import { onlyOneDirector } from "../../../../../utils/onlyOneDirector";
import dayjs, { Dayjs } from "dayjs";
import { DocumentClientProfileType, SchemaType } from "app/types";
import css from "../../ClientProfileCardImageViewer.module.css";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { roleResolver } from "../../../../../utils/roleResolver";
import { JWTPayload } from "jose";
import { StepContext, TaskQuestionnaireCardSteps } from "../../../ClientProfileCard";

interface IPassportFormProps {
  passportForm: FormInstance;
  onFormValuesChange: () => void;
  docApproveStatus: RadioChangeEvent | string;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  isDocApprovalDisabled: boolean;
  handlePassportSeriesChange: (event: ChangeEvent<HTMLInputElement> | string) => void;
  handlePassportDivisionCodeChange: (event: ChangeEvent<HTMLInputElement> | string) => void;
  handlePassportNumberOnlyChange: (
    event: ChangeEvent<HTMLInputElement> | string,
    fieldName: string
  ) => void;
  isClientEmployee: boolean | undefined;
  isValidationCompleted: boolean | undefined;
}

enum FormLength {
  passportSeries = 4,
  passportNumber = 6,
  passportSubdivision = 7,
}

const PassportForm: FC<IPassportFormProps> = ({
  passportForm,
  onFormValuesChange,
  docApproveStatus,
  onApproveChange,
  isDocApprovalDisabled,
  handlePassportSeriesChange,
  handlePassportDivisionCodeChange,
  handlePassportNumberOnlyChange,
  isClientEmployee,
  isValidationCompleted,
}): JSX.Element => {
  const passportDocSchema: SchemaType | null = useSelector((state: AppStateType) => state.document.passportDocSchema);
  
  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);
  const { passport_document } = documentClientProfile?.fields || {};
  
  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);
  
  const [seriesPassportBlur, setSeriesPassportBlur] = useState<boolean>(false);
  const [passportNumberBlur, setPassportNumberBlur] = useState<boolean>(false);
  const [subdivisionCodeBlur, setSubdivisionCodeBlur] = useState<boolean>(false);
  
  const currentStep: number | null = useContext(StepContext);
  const isCeoDetailCurrentStep: boolean = currentStep === TaskQuestionnaireCardSteps.ManagerDetailsStep;
  
  const handlePassportLettersOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
  ): void => {
    const inputValue: string = event.target.value.replace(/[^A-Za-zА-Яа-яЁё]/g, "");

    passportForm.setFieldValue(fieldName, inputValue);
  };
  
  const managerDetailStep: boolean | undefined =
    isCeoDetailCurrentStep ? onlyOneDirector(documentClientProfile) : undefined;
  
  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isPassportFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;
  const disableFullName: boolean | undefined = isPassportFormDisabled || managerDetailStep;
  
  const refInput: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const declineSelectWidth: number = refInput?.current?.clientWidth ?? 0;
  
  const handleDateChange = (date: Dayjs | null, variant: string): void => {
    passportForm.setFieldValue(variant as any, date || null);
  };
  
  const handleValidateStatus = (values: string, formLength: number): "error" | "" => {
    return passportForm.getFieldValue(values as any)?.length === formLength
      ? "" : "error";
  };
  
  const handleHelp = (values: string, text: string): null | string => {
    return passportForm.getFieldValue(values as any) ? null : text;
  };
  
  const handleBlur = (variant: string): void => {
    const handlers: Record<string, () => void> = {
      series_number: () => setSeriesPassportBlur(true),
      passport_number: () => setPassportNumberBlur(true),
      subdivision_code: () => setSubdivisionCodeBlur(true),
    };
    
    handlers[variant]();
  };
  
  return (
    <Form
      form={passportForm}
      name="passport-form"
      onClick={onFormValuesChange}
      onChange={onFormValuesChange}
      validateTrigger={validateTriggersArr}
      layout="vertical"
    >
      <Form.Item
        name="last_name"
        label="Фамилия"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("last_name", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          size="large"
          readOnly={disableFullName}
          value={
            isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile) ? passport_document?.[0]?.last_name : ""
          }
          placeholder="Фамилия"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "last_name")}
        />
      </Form.Item>
      <Form.Item
        name="first_name"
        label="Имя"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("first_name", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          size="large"
          readOnly={disableFullName}
          value={
            isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile) ? passport_document?.[0]?.first_name : ""
          }
          placeholder="Имя"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "first_name")}
        />
      </Form.Item>
      <Form.Item
        name="second_name"
        label="Отчество"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("second_name", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          className="w-full"
          size="large"
          readOnly={disableFullName}
          value={
            isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile) ? passport_document?.[0]?.second_name : ""
          }
          placeholder="Отчество"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "second_name")}
        />
      </Form.Item>
      <Form.Item
        name="date_of_birth"
        label="Дата рождения"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("date_of_birth", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <DatePicker
          size="large"
          className="w-full"
          style={{
            padding: "6.35px"
          }}
          value={undefined}
          format="DD.MM.YYYY"
          allowClear={!docApproveStatus}
          inputReadOnly={false}
          placeholder="00.00.0000"
          onChange={(date: Dayjs | null): void => handleDateChange(date, "date_of_birth")}
          disabledDate={(current: Dayjs) => dayjs().isBefore(current.add(16, "year"))}
          defaultPickerValue={dayjs().subtract(16, "year")}
          disabled={isPassportFormDisabled}
        />
      </Form.Item>
      <Form.Item
        name="sex"
        label="Пол"
        className="mb-5"
        rootClassName="w-full"
        rules={[
          {
            required: isFormItemRequiredRule("sex", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Select
          id="sex"
          size="large"
          allowClear={!docApproveStatus}
          placeholder="Выберите пол"
          disabled={isPassportFormDisabled}
          options={[
            { value: "Мужской", label: "Мужской" },
            { value: "Женский", label: "Женский" }
          ]}
        />
      </Form.Item>
      <div ref={refInput} className="flex justify-between w-full gap-[18px] mb-5">
        <Form.Item
          name="series_number"
          label="Серия"
          className="mb-0"
          rules={[
            {
              max: 5,
              message: "4 цифры."
            },
            {
              min: 4,
              message: "4 цифры."
            },
            {
              required: isFormItemRequiredRule("series_number", passportDocSchema),
              message: ""
            }
          ]}
          validateTrigger={validateTriggersArr}
          normalize={(value) => value.replace(/\D/g, "")}
          validateStatus={seriesPassportBlur ? handleValidateStatus("series_number", FormLength.passportSeries) : ""}
          help={seriesPassportBlur ? handleHelp("series_number", "Только цифры") : null}
        >
          <Input
            min={4}
            max={4}
            size="large"
            placeholder="0000"
            onChange={handlePassportSeriesChange}
            readOnly={isPassportFormDisabled}
            onBlur={() => handleBlur("series_number")}
          />
        </Form.Item>
        <Form.Item
          name="passport_number"
          label="Номер паспорта"
          className="mb-0"
          rules={[
            {
              max: 7,
              message: "6 цифр."
            },
            {
              min: 6,
              message: "6 цифр."
            },
            {
              required: isFormItemRequiredRule("passport_number", passportDocSchema),
              message: ""
            }
          ]}
          validateTrigger={validateTriggersArr}
          normalize={(value) => value.replace(/\D/g, "")}
          validateStatus={passportNumberBlur ? handleValidateStatus("passport_number", FormLength.passportNumber) : ""}
          help={passportNumberBlur ? handleHelp("passport_number", "Только цифры"): ""}
        >
          <Input
            min={6}
            max={6}
            size="large"
            placeholder="000000"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportNumberOnlyChange(e, "passport_number")}
            readOnly={isPassportFormDisabled}
            onBlur={() => handleBlur("passport_number")}
          />
        </Form.Item>
      </div>
      <Form.Item
        name="issued_date"
        label="Дата выдачи"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("issued_date", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <DatePicker
          size="large"
          className="w-full"
          style={{
            padding: "6.35px"
          }}
          format="DD.MM.YYYY"
          allowClear={!docApproveStatus}
          inputReadOnly
          placeholder="00.00.0000"
          onChange={(date: Dayjs | null): void => handleDateChange(date, "issued_date")}
          disabledDate={(current: Dayjs) => dayjs().isBefore(current)}
          disabled={isPassportFormDisabled}
        />
      </Form.Item>
      <Form.Item
        name="issued_by"
        label="Кем выдан"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("issued_by", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          size="large"
          placeholder="Введите наименование органа, выдавшего документ"
          readOnly={isPassportFormDisabled}
        />
      </Form.Item>
      <Form.Item
        name="subdivision_code"
        label="Код подразделения"
        className="mb-5"
        rules={[
          {
            max: 8,
            message: "6 цифр."
          },
          {
            min: 7,
            message: "6 цифр."
          },
          {
            required: isFormItemRequiredRule("subdivision_code", passportDocSchema),
            message: "Только цифры"
          }
        ]}
        validateTrigger={validateTriggersArr}
        normalize={(value) => value.replace(/[^0-9-]/g, "")}
        validateStatus={subdivisionCodeBlur
          ? handleValidateStatus("subdivision_code", FormLength.passportSubdivision)
          : ""
        }
        help={subdivisionCodeBlur ? handleHelp("subdivision_code", "Только цифры") : ""}
      >
        <Input
          size="large"
          placeholder="000-000"
          onChange={handlePassportDivisionCodeChange}
          readOnly={isPassportFormDisabled}
          onBlur={() => handleBlur("subdivision_code")}
        />
      </Form.Item>
      <Form.Item
        name="citizenship"
        label="Гражданство"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("citizenship", passportDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          size="large"
          placeholder="Введите гражданство"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePassportLettersOnlyChange(e, "citizenship")}
          readOnly={isPassportFormDisabled}
        />
      </Form.Item>
      {!isPassportFormDisabled && (
        <>
          <Divider className="mt-0 mb-5" />
          <div className={`mb-2 ${css.modalText}`}>Согласование</div>
          <p className="mb-5 gray-color w-full">
            Подтвердите согласование документа. При
            <br />
            наличии ошибок или проблем с файлом
            <br />
            отклоните согласование, указав причину.
          </p>
          <div className="flex justify-between">
            <Tooltip
              title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
              overlayInnerStyle={{ width: "320px", textAlign: "center" }}
            >
              <Form.Item name="isApproved">
                <Radio.Group
                  onChange={onApproveChange}
                  value={docApproveStatus}
                  disabled={isDocApprovalDisabled}
                  className="flex flex-col gap-2"
                >
                  <Radio value="APPROVED">Согласовать</Radio>
                  <Radio value="DECLINED">Отклонить</Radio>
                </Radio.Group>
              </Form.Item>
            </Tooltip>
          </div>
          <div>
            {docApproveStatus === "DECLINED" && (
              <Form.Item
                name="declineReason"
                label="Причина"
                className="w-fit"
                rules={[
                  {
                    required: true,
                    message: ""
                  }
                ]}
                initialValue="Необходимо приложить верный документ (приложен не тот документ)"
              >
                <Select
                  placeholder="Выберите причину"
                  size="large"
                  style={{ width: `${declineSelectWidth}px` }}
                  options={[
                    {
                      value: "Необходимо приложить верный документ (приложен не тот документ)",
                      label: "Необходимо приложить верный документ (приложен не тот документ)"
                    },
                    {
                      value: "Истек срок действия документа",
                      label: "Истек срок действия документа"
                    },
                    {
                      value: "Плохое качество документа, нечитаемый скан",
                      label: "Плохое качество документа, нечитаемый скан"
                    }
                  ]}
                />
              </Form.Item>
            )}
          </div>
        </>
      )}
    </Form>
  );
};

export default PassportForm;
