import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect
} from "react";
import css from "./TasksListing.module.css";
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  Table
} from "antd";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search_icon.svg";
import { FilterNamesType, FilterTasksType, ResponsibleFilterType } from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import ZeroTasks from "../ZeroTasks/ZeroTasks";
import { AppDispatch } from "../../../store/store";
import {
  getTaskPageNumber,
  setTaskListingFilter,
  setTaskListingParams,
  setTaskListingSorter,
  setTaskListingTagKey
} from "../../../actions/tasks.actions";
import ZeroSearch from "../../ClientsList/ZeroSearch/ZeroSearch";
import { FormPropsType, TasksListingType } from "../TasksType/TasksListingType/TasksListingType";
import TasksListingTags from "./TasksListingItem/TasksListingTags";
import { useInfiniteScroll, UseInfiniteScrollHookParams } from "../../../Hooks/useInfiniteScroll/useInfiniteScroll";
import TaskListingFastFilters from "./TasksListingItem/TaskListingFastFilters";
import debounce, { DebouncedFunction } from "debounce";
import { filterParamsToArray } from "../../../utils/filterParamsToArray";
import * as jose from "jose";

interface ITasksProps {
  form: FormInstance;
  tasksListingProps: TasksListingType;
  setLoadingData: Dispatch<SetStateAction<boolean>>;
}

enum ListingPage {
  firstPage = 1,
  pagePerScroll = 50,
}

const debouncedGetUsersListing: DebouncedFunction<(
  handleTaskUpdate: () => void,
) => void> = debounce((handleTaskUpdate): void => {
  handleTaskUpdate();
}, 1000);

const TasksListing: FC<ITasksProps> = ({
  form,
  tasksListingProps,
  setLoadingData,
}): JSX.Element => {
  const values: FormPropsType = Form.useWatch([], form);
  const { organizationName } = values || {};

  const {
    activeKey,
    setActiveKey,
    prevActiveKey,
    tabChange,
    columns,
    currentPage,
    filterData,
    setFilterData,
    pageSize,
    paramsData,
    setParamsData,
    setCurrentPage,
    showModal,
    handlePageChange,
    handlePageSizeChange,
    sortValue,
    isLoadingData,
    setFilterStatusLabel,
    setSelectedTaskStatuses,
    setSelectedOPFType,
    setSelectedTaxSystem,
    setSelectedTaskTypes,
    setCreatedStartDate,
    setCreatedEndDate,
    setDeadlineStartDate,
    setDeadlineEndDate,
    setInitiationStartDate,
    setInitiationEndDate,
    activeStatus,
    setActiveStatus,
    clearFilter
  } = tasksListingProps;

  const {
    tasksData,
    tasksPageSize,
    totalDocs,
    tasksParams,
  } = useSelector((state: AppStateType) => state.tasks);
  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);
  
  const userProfileUuid: string = decodedToken?.sub ?? "";

  const dispatch = useDispatch<AppDispatch>();

  useInfiniteScroll({
    totalDocs,
    paramsData,
    pagePerScroll: ListingPage.pagePerScroll,
    setParamsData,
    setLoadingData,
  } as UseInfiniteScrollHookParams);

  useEffect(() => {
    if (organizationName?.trim() === "") {
      setParamsData((prev: FilterTasksType | null) => {
        if (prev && prev.clients !== null) {
          const { clients, ...rest } = prev;
          dispatch(setTaskListingParams({...rest}));
          return {...rest};
        } else {
          dispatch(setTaskListingParams(prev));
          return prev;
        }
      });
    }

    const timeout: NodeJS.Timeout = setTimeout(():void => {
      const hasInputValue: boolean = organizationName?.trim() !== ""
        && typeof organizationName === "string"
        && (!paramsData?.clients || paramsData?.clients !== organizationName);

      if (hasInputValue) {
        setParamsData((prev: FilterTasksType | null) => {
          const updatedParams = {
            ...prev,
            clients: organizationName?.trim()
          };
          dispatch(setTaskListingParams(updatedParams));
          return updatedParams;
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [organizationName]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue("organizationName", e.target.value);

    if (e.target.value) {
      setTimeout((): void => {
        setCurrentPage(ListingPage.firstPage);
        dispatch(getTaskPageNumber(ListingPage.firstPage));
      }, 2000);
    } else {
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
    }
  };

  const handleTaskUpdate = (taskField: string, value: string | null): void => {
    const paramsValues: FilterTasksType = {
      page_size: paramsData?.page_size,
      page: 1,
      order_by: paramsData?.order_by,
      [taskField]: value
    };
    
    setParamsData(paramsValues);
    dispatch(setTaskListingParams(paramsValues));
    setFilterData(null);
    setActiveStatus([]);
    clearFilter();
    form.setFieldValue("number", value);
    form.setFieldValue("organizationName", null);
  };

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const numericValue: string = e.target.value.replace(/\D/g, "");

    form.setFieldValue("number", numericValue);
    setActiveKey("1");

    if (numericValue?.length) {
      debouncedGetUsersListing(() => handleTaskUpdate("number", numericValue));
    } else {
      debouncedGetUsersListing.clear();
      onNumberClear();
    }
  };

  const onNumberClear = (): void => {
    form.setFieldValue("number", null);
    handleTaskUpdate("number", null);
  };

  const FilterNames: FilterNamesType = {
    "clients_lookup.short_name": "ОПФ:",
    status_task: "Статус задачи:",
    task_type_lookup: "Тип задачи:",
    inn: "ИНН:",
    "clients_lookup.tax_system": "Тип налогообложения:",
    responsible_lookup: "Ответственный:",
    created_at_gte: "Период от:",
    updated_at_lte: "Период до:",
    initiation_date_gte: "Период взятия в работу:",
    is_expired: "",
    new_notifications: "Наличие обновлений:",
  };

  const filterTabs = (activeKey: string, isClear: boolean = false): FilterTasksType | null => {
    setCurrentPage(ListingPage.firstPage);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    setSelectedTaskTypes([]);
    
    const {
      task_type_lookup,
      page_size,
      page,
      order_by,
      ...restParamsData
    } = paramsData || {};

    const pageDataProps: FilterTasksType = {
      page_size: tasksPageSize,
      page: ListingPage.firstPage,
      order_by: sortValue,
      ...(!isClear && restParamsData),
    };

    switch (activeKey) {
      case ("2"):
        return { ...pageDataProps, task_type_lookup: "[301]" };
      case ("3"):
        return { ...pageDataProps, task_type_lookup: "[302]" };
      case ("4"):
        return { ...pageDataProps, task_type_lookup: "[100-300]" };
      default:
        return pageDataProps;
    }
  };

  const deleteFilterData = (e: React.MouseEvent<HTMLElement>, key: string): void => {
    e.preventDefault();
    setFilterData((prev: FilterTasksType | null) => {
      if (prev) {
        dispatch(setTaskListingFilter({ ...prev, [key]: null }));
        return { ...prev, [key]: null };
      }
      dispatch(setTaskListingFilter(null));
      return null;
    });

    if (key === "clients") {
      form.setFieldValue("organizationName", null);
    }
    
    if (key === "is_expired") {
      form.setFieldValue("deadline" as any, false);
    }
    
    if (key === "inn") {
      form.setFieldValue("inn" as any, null);
    }

    setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
      const updatedParams: FilterTasksType | null = prev
        ? { ...prev, [key]: null, page: 1, ... (key === "task_type_lookup" ? { order_by: "-number" } : {}) }
        : null;
      
      if (key === "is_expired" && updatedParams) {
        updatedParams["or_filters"] = null;
      }

      if (key === "created_at_gte" && updatedParams) {
        updatedParams["created_at_lte"] = null;
        form.setFieldValue("dateFrom" as any, null);
        
        setCreatedStartDate(null);
        setCreatedEndDate(null);
      }
      
      if (key === "initiation_date_gte" && updatedParams) {
        updatedParams["initiation_date_lte"] = null;
        form.setFieldValue("dateContract" as any, null);
        
        setInitiationStartDate(null);
        setInitiationEndDate(null);
      }
      
      if (key === "updated_at_lte" && updatedParams) {
        updatedParams["updated_at_gte"] = null;
        form.setFieldValue("dateTo" as any, null);
        
        setDeadlineStartDate(null);
        setDeadlineEndDate(null);
      }

      if (key === "task_type_lookup") {
        dispatch(setTaskListingSorter("-number"));
      }

      if (updatedParams) {
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      } else {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        filterTabs(activeKey);
      }
      dispatch(setTaskListingParams(null));
      return null;
    });

  };

  const deleteStatusFilterData = (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    filterStatus: string,
    value: string[]
  ): void => {
    e.preventDefault();
    setCurrentPage(ListingPage.firstPage);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    
    setFilterData((prev: FilterTasksType | null) => {
      const filterStatusData: string[] = value.filter((status:string):boolean => status !== filterStatus);
      const returnStatus: string[] | null = filterStatusData.length ? filterStatusData : null;

      if (prev) {
        dispatch(setTaskListingFilter({ ...prev, [key]: returnStatus }));
        return { ...prev, [key]: returnStatus };
      }

      dispatch(setTaskListingFilter(null));
      return null;
    });
    setParamsData((prev: FilterTasksType | null) => {
      const filterStatusData: string[] = value.filter((status:string):boolean => status !== filterStatus);
      const filteredActiveStatus: string[] = activeStatus.filter(
        (status: string) => !value.includes(status)
      );
      setActiveStatus(filteredActiveStatus);
      const newFilterStatus: string[] = [...filterStatusData, ...filteredActiveStatus];
      const showDeadline: string | null = prev?.deadline_gte ? "![FIN_TRUE, FIN_FALSE]" : null;
      const returnStatus: string | null = (filterStatusData?.length || activeStatus?.length)
        ? `[${newFilterStatus}]`
        : showDeadline;

      const updatedParams: FilterTasksType | null = prev
        ? {
            ...prev,
            [key]: returnStatus,
            page: 1
          }
        : null;
      if (updatedParams) {
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      } else {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        filterTabs(activeKey);
      }

      dispatch(setTaskListingParams(null));
      return null;
    });
    setSelectedTaskStatuses((prev: string[]) => {
     return prev.filter((status:string):boolean => status !== filterStatus);
    });
  };
  
  const deleteFilterType = (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    typeVariant: string,
    value: string[]
  ): void => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    
    if (key === "clients_lookup.short_name") {
      setSelectedOPFType((prev: string[]) => {
        return prev.filter((type: string): boolean => type !== typeVariant);
      });
    }

    if (key === "clients_lookup.tax_system") {
      setSelectedTaxSystem((prev: string[]) => {
        return prev.filter((type: string): boolean => type !== typeVariant);
      });
    }
    
    if (key === "task_type_lookup") {
      setSelectedTaskTypes((prev: string[]) => {
        return prev.filter((type: string): boolean => type !== typeVariant);
      });
    }

    setFilterData((prev: FilterTasksType | null) => {
      const filterTaskType: string[] = value.filter((type: string): boolean => type !== typeVariant);
      const returnTaskType: string[] | null = filterTaskType.length ? filterTaskType : null;

      if (prev) {
        dispatch(setTaskListingFilter({ ...prev, [key]: returnTaskType }));
        return { ...prev, [key]: returnTaskType };
      }
      
      dispatch(setTaskListingFilter(null));
      return null;
    });
    
    setParamsData((prev: FilterTasksType | null) => {
      const filterTaskType: string[] = value.filter((type: string): boolean => type !== typeVariant);
      const returnTaskType: string | null = filterTaskType.length ? `[${filterTaskType}]` : null;
      
      const updatedParams = prev ? { ...prev, [key]: returnTaskType, page: 1 } : null;
      
      if (updatedParams) {
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      } else {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        filterTabs(activeKey);
      }
      
      dispatch(setTaskListingParams(null));
      return null;
    });
  };

  const deleteResponsibleFilterData = (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    id: string,
    value: ResponsibleFilterType[]
  ): void => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    
    setFilterData((prev: FilterTasksType | null) => {
      const filterResponsibleData: ResponsibleFilterType[] =
        value.filter((resp: ResponsibleFilterType):boolean => resp.id !== id);
      const returnResponsible: ResponsibleFilterType[] | null =
        filterResponsibleData.length ? filterResponsibleData : null;
      
      if (key === "responsible_lookup") {
        const responsibleList: string[] = filterResponsibleData.length
          ? filterResponsibleData.map((responsible: ResponsibleFilterType) => JSON.stringify(responsible))
          : [];
        form.setFieldValue("responsible" as any, responsibleList);
      }

      if (prev) {
        dispatch(setTaskListingFilter({ ...prev, [key]: returnResponsible }));
        return { ...prev, [key]: returnResponsible };
      }

      dispatch(setTaskListingFilter(null));
      return null;
    });

    setParamsData((prev: FilterTasksType | null) => {
      const filterResponsibleData: ResponsibleFilterType[] =
        value.filter((resp: ResponsibleFilterType):boolean => resp.id !== id);
      const nameResponsible: string[] = filterResponsibleData.map((name: ResponsibleFilterType) => name.id);
      const returnResponsible: string | null = filterResponsibleData.length ? `[${nameResponsible}]` : null;
      const hasUserUuid: boolean =
        filterParamsToArray(tasksParams?.responsible_lookup as string).includes(userProfileUuid);
      const uuidAllResponsible: string | null = hasUserUuid
        ? `[${[...nameResponsible, userProfileUuid]}]`
        : returnResponsible;

      const updatedParams =
        prev ? { ...prev, [key]: uuidAllResponsible, page: 1 } : null;
      
      if (updatedParams) {
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      } else {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        filterTabs(activeKey);
      }

      dispatch(setTaskListingParams(null));
      return null;
    });
  };

  const clearFilterData = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setFilterData(null);
    dispatch(setTaskListingSorter(sortValue));
    dispatch(setTaskListingFilter(null));
    setParamsData(filterTabs(activeKey, true));
    setActiveStatus([]);
    dispatch(setTaskListingParams(filterTabs(activeKey, true)));
    clearFilter();
  };

  useEffect(() => {
    if (activeKey !== prevActiveKey && tabChange) {
      setFilterData((prevState: FilterTasksType | null): FilterTasksType | null => {
        if (!prevState) return null;
        
        const { task_type_lookup, ...restState } = prevState;
        const newState: FilterTasksType = { ...restState };
        
        dispatch(setTaskListingFilter(newState));
        return newState;
      });

      dispatch(setTaskListingTagKey(activeKey));
      setParamsData(() => {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        return filterTabs(activeKey);
      });
    }
  }, [activeKey]);

  const zeroTasks = (): JSX.Element => {
    const hasFilter: boolean = !!paramsData?.clients
      || !!paramsData?.task_type_lookup
      || !!paramsData?.status_task
      || !!filterData
      || form.getFieldValue("number");

    return (
      <>
        {!isLoadingData && hasFilter && <ZeroSearch />}
        {!isLoadingData && !hasFilter && <ZeroTasks key={activeKey} activeKey={activeKey} />}
      </>
    );
  };
  
  const renderTaskFastFilters = (filter: boolean): JSX.Element => (
    <TaskListingFastFilters
      filter={filter}
      setParamsData={setParamsData}
      setCurrentPage={setCurrentPage}
      setFilterStatusLabel={setFilterStatusLabel}
      activeStatus={activeStatus}
      setActiveStatus={setActiveStatus}
      setSelectedTaskStatuses={setSelectedTaskStatuses}
    />
  );
  
  const initialValue: FormPropsType = {
    number: paramsData?.number ?? null,
    organizationName: paramsData?.clients ?? null,
  };

  return (
    <Form
      form={form}
      initialValues={initialValue}
    >
      <div className={css.blockTable}>
        {filterData
          && Object.values(filterData).find((
            value: string | number | string[] | ResponsibleFilterType[] | null
            ) => value && !(Array.isArray(value) && value.length === 0)) ? (
            <div className={css.blockInputData}>
              <div className="flex mb-3">
                <Form.Item name="number" noStyle>
                  <Input
                    id="number"
                    allowClear
                    className={css.numberInput}
                    suffix={<SearchIcon className={css.searchIcon} />}
                    placeholder="Найти по номеру задачи"
                    width="240px"
                    size="large"
                    onChange={onNumberChange}
                    onClear={onNumberClear}
                  />
                </Form.Item>
                <Form.Item name="organizationName" noStyle>
                  <Input
                    id="organizationName"
                    allowClear
                    className={css.input}
                    suffix={<SearchIcon className={css.searchIcon} />}
                    placeholder="Найти по наименованию клиента или ИНН"
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Button
                  className={css.button}
                  onClick={showModal}
                >
                  Фильтры
                </Button>
              </div>
              {renderTaskFastFilters(true)}
              <Divider className={css.borderLine} />
              <TasksListingTags
                filterData={filterData}
                deleteResponsibleFilterData={deleteResponsibleFilterData}
                deleteStatusFilterData={deleteStatusFilterData}
                deleteFilterType={deleteFilterType}
                deleteFilterData={deleteFilterData}
                FilterNames={FilterNames}
                clearFilterData={clearFilterData}
              />
            </div>
          ) : (
           <>
            <div className={css.blockInput}>
              <Form.Item name="number" noStyle>
                <Input
                  id="number"
                  allowClear
                  className={css.numberInput}
                  suffix={<SearchIcon className={css.searchIcon} />}
                  placeholder="Найти по номеру задачи"
                  width="240px"
                  size="large"
                  onChange={onNumberChange}
                  onClear={onNumberClear}
                />
              </Form.Item>
              <Form.Item name="organizationName" noStyle>
                <Input
                  id="organizationName"
                  className={css.input}
                  allowClear
                  suffix={<SearchIcon className={css.searchIcon} />}
                  placeholder="Найти по наименованию клиента или ИНН"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Button
                className={css.button}
                onClick={showModal}
              >
                Фильтры
              </Button>
            </div>
            {renderTaskFastFilters(false)}
           </>
          )
        }
        <Table
          className={css.table}
          loading={isLoadingData}
          columns={columns}
          dataSource={tasksData?.length ? tasksData : []}
          pagination={false}
          locale={{ emptyText: zeroTasks() }}
        />
        {tasksData?.length ? (
          <PaginationCustom
            total={totalDocs ? totalDocs : 0}
            currentPage={currentPage}
            defaultPageSize={tasksPageSize}
            pageSize={pageSize}
            defaultCurrent={tasksPageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        ) : (
          <div className={css.noTasks}/>
        )}
      </div>
    </Form>
  );
};

export default TasksListing;
