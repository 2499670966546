import { ITypesOfProfessionProps } from "../AgreementForm";

export const typesOfContracts: ITypesOfProfessionProps[] = [
  {
    value: "Трудовой договор",
    label: "Трудовой договор"
  },
  {
    value: "Договор ГПХ",
    label: "Договор ГПХ"
  },
];
