import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction, 
  useRef,
} from "react";
import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Modal,
  Button,
  message,
  Pagination,
  PaginationProps,
  RadioChangeEvent,
  Tooltip,
  Divider
} from "antd";
import css from "./ClientProfileCardImageViewer.module.css";
import { ReactComponent as PlusIcon } from "../../../assets/icons/pluse_icon.svg";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus_icon.svg";
import { ReactComponent as RotateLeftIcon } from "../../../assets/icons/rotate_left_icon.svg";
import { ReactComponent as RotateRightIcon } from "../../../assets/icons/rotate_right_icon.svg";
import { ReactComponent as ZoomInIcon } from "../../../assets/icons/zoom_in_icon.svg";
import ClientProfileCardImageViewerFormPassport
  from "./ClientProfileCardImageViewerForms/ClientProfileViewerFormPasport/ClientProfileCardImageViewerFormPassport";
import ClientProfileCardImageViewerFormUsn
  from "./ClientProfileCardImageViewerForms/ClientProfileCardImageViewerFormUsn";
import ClientProfileCardImageViewerFormDecision
  from "./ClientProfileCardImageViewerForms/ClientProfileCardImageViewerFormDecision";
import ClientProfileCardImageViewerFormSnils
  from "./ClientProfileCardImageViewerForms/ClientProfileCardImageViewerFormSnils";
import ClientProfileCardImageViewerFormInn
  from "./ClientProfileCardImageViewerForms/ClientProfileCardImageViewerFormInn";
import ClientProfileCardImageViewerFormPatent
  from "./ClientProfileCardImageViewerForms/ClientProfileCardImageViewerFormPatent";
import ClientProfileCardImageViewerFormPersonalTax
  from "./ClientProfileCardImageViewerForms/ClientProfileCardImageViewerFormPersonalTax";
import ClientProfileCardImageViewerFormBankDetails
  from "./ClientProfileCardImageViewerForms/ClientProfileCardImageViewerFormBankDetails";
import ClientProfileCardImageViewerFormAgreement
  from "./ClientProfileCardImageViewerForms/ClientProfileViewerFormAgreement/ClientProfileCardImageViewerFormAgreement";
import { CloseOutlined } from "@ant-design/icons";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import {
  DocumentDataType,
  PdfDownloadOptionsType,
  UpdateDocumentFieldType,
} from "app/types";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { TIFFViewer } from "react-tiff";
import "react-tiff/dist/index.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface IClientProfileCardImageViewerProps {
  closeForm: () => void;
  saveFormData?: (docUuid: string, data: UpdateDocumentFieldType | null) => void;
  saveCompanyFormData?: (docUuid: string, data: UpdateDocumentFieldType | null) => void;
  documentType: string;
  documentUuid: string;
  isImageViewerOpen: boolean;
  isEmployee?: boolean;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isCompany?: boolean;
  isNotAgreedDocs?: boolean;
  setDocumentUuid?: Dispatch<SetStateAction<string>>;
  setDocumentType?: Dispatch<SetStateAction<string>>;
}

const token: string | null = window?.localStorage.getItem("authToken");
const pdfDownloadOptions: PdfDownloadOptionsType = {
  httpHeaders: {
    "method": "GET",
    "Content-Type": "application/pdf",
    "Authorization": JSON.parse(String(token)),
  }
};

const API_TASKS_URL: string | undefined = process.env.REACT_APP_API_TASKS_URL;

export const validateTriggersArr: string[] = [
  "onBlur",
  "onChange",
  "onFieldsChange",
  "onValuesChange",
];

const ClientProfileCardImageViewer: FC<IClientProfileCardImageViewerProps> = ({
  closeForm,
  saveFormData,
  saveCompanyFormData,
  documentType,
  documentUuid,
  isImageViewerOpen,
  isEmployee,
  isClientEmployee,
  isValidationCompleted,
  isCompany,
  isNotAgreedDocs,
  setDocumentUuid,
  setDocumentType,
}) => {
  const [isZoomed, setZoomed] = useState<boolean>(false);
  const [rotateDeg, setRotateDeg] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [canSaveDocument, setCanSaveDocument] = useState<boolean>(false);
  const [docApproveStatus, setDocApproveStatus] = useState<RadioChangeEvent | string>("");
  const [currentDocumentData, setCurrentDocumentData] = useState<UpdateDocumentFieldType | null>(null);
  const [docFilesLinks, setDocFilesLinks] = useState<string[]>([""]);
  const [totalDocFiles, setTotalDocFiles] = useState<number>(1);
  const [documentContentType, setDocumentContentType] = useState<string | null>("");
  const [documentFileName, setDocumentFileName] = useState<string | null>("");
  const [documentPages, setDocumentPages] = useState<number>(0);
  const [img, setImg] = useState<string>();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const authToken = useSelector((state: AppStateType) => state.account.authToken);

  useEffect(() => {
    if (!!docFilesLinks[currentPage - 1] && !!authToken) {
      getDocumentInfo()
        .then(({ docContentType, docFileName } : DocumentDataType): void => {
          setDocumentFileName(docFileName);
          setDocumentContentType(docContentType);
        })
        .catch((err) => message.error(err));
    }
  }, [docFilesLinks, currentPage, authToken]);

  const getDocumentInfo = async (): Promise<DocumentDataType> => {
    const response: Response = await fetch(
      `${API_TASKS_URL}/api/mdm/storage/v1/${docFilesLinks[currentPage - 1]}`,
      {
      headers: {
        "method": "GET",
        "Authorization": JSON.parse(String(authToken).replace(/^(?!").*(?<!")$/, "\"$&\"")),
      }
    });

    const imageBlob: Blob = await response.blob();
    const imageObjectURL: string = URL.createObjectURL(imageBlob);
    setImg(imageObjectURL);

    let fileName: string = "";
    const contentHeader: string = ((): string => {
      const headerValue: string = response.headers.get("Content-Disposition") ?? "";
      
      if (headerValue.includes("\"")) {
        return headerValue.split("\"")[1] ?? "";
      }
      
      if (headerValue.includes("=")) {
        return headerValue.split("=")[1] ?? "";
      }
      
      return "";
    })();

    if (contentHeader) {
      if (contentHeader.toLowerCase().includes("utf-8")) {
        fileName = decodeURIComponent(contentHeader.replace(/utf-8''/i, ""));
      } else {
        fileName = contentHeader;
      }
    }

    return {
      docFileName: fileName,
      docContentType: response.headers.get("Content-Type"),
    };
  };

  const {
    usnDoc,
    innDoc,
    bankDoc,
    snilsDoc,
    incomeDoc,
    passportDoc,
    decisionDoc,
    contractDoc,
    migrationDoc,
    usnCompanyDoc,
    snilsCompanyDoc,
    passportCompanyDoc,
    decisionCompanyDoc,
    migrationCompanyDoc,
  } = useSelector((state: AppStateType) => state.document);

  const zoomInOut = (setTransform: (newPositionX: number, newPositionY: number, newScale: number) => void): void => {
    setZoomed(!isZoomed);

    return isZoomed
      ? setTransform(-300, -300, 2)
      : setTransform(0, 0, 1);
  };

  const Controls = () => {
    const { zoomIn, zoomOut, setTransform } = useControls();

    return (
      <div className="z-10 absolute top-7 right-5 flex flex-col">
        <ButtonCustom
          id="zoomIn"
          className={`mb-1 w-10/12 p-1 gap-0 ${css.btnBorder}`}
          text=""
          type="default"
          ghost
          onClick={() => zoomIn()}
        >
          <PlusIcon className="w-7 h-6" />
        </ButtonCustom>
        <ButtonCustom
          id="zoomInOut"
          className={`mb-1 w-10/12 p-1 gap-0 ${css.btnBorder}`}
          text=""
          type="default"
          ghost
          onClick={() => zoomInOut(setTransform)}
        >
          <ZoomInIcon className="w-7 h-6" />
        </ButtonCustom>
        <ButtonCustom
          id="zoomOut"
          className={`mb-3 w-10/12 p-1 gap-0 ${css.btnBorder}`}
          onClick={() => zoomOut()}
          text=""
          type="default"
          ghost
        >
          <MinusIcon className="w-7 h-7" />
        </ButtonCustom>
        <ButtonCustom
          id="rotateClockwise"
          className={`mb-1 w-10/12 p-1 gap-0 ${css.btnBorder}`}
          text=""
          type="default"
          ghost
          onClick={() => setRotateDeg(rotateDeg + 90)}
        >
          <RotateRightIcon className="w-7 h-6" />
        </ButtonCustom>
        <ButtonCustom
          id="rotateСounterСlockwise"
          className={`mb-1 w-10/12 p-1 gap-0 ${css.btnBorder}`}
          text=""
          type="default"
          ghost
          onClick={() => setRotateDeg(rotateDeg - 90)}
        >
          <RotateLeftIcon className="w-7 h-6" />
        </ButtonCustom>
      </div>
    );
  };

  const onPaginationChange: PaginationProps["onChange"] = (page: number): void => {
    setCurrentPage(page);
  };

  const [imageViewerForm] = Form.useForm();

  const { getFieldValue, resetFields } = imageViewerForm;

  const onIncomeTaxFormValuesChange = (): void => {
    const isApproved: string = getFieldValue("isApproved");
    const declineReason: string = getFieldValue("declineReason");

    const incomeDocData: UpdateDocumentFieldType = {
      uuid: incomeDoc?.fields?.uuid ?? "",
      files: incomeDoc?.fields?.files ?? [],
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
    };

    setCurrentDocumentData(incomeDocData);
  };

  const onMigrationCardFormValuesChange = (): void => {
    const isApproved: string = getFieldValue("isApproved");
    const declineReason: string = getFieldValue("declineReason");

    const migrationDocData: UpdateDocumentFieldType = {
      uuid: isCompany ? migrationCompanyDoc?.fields?.uuid ?? "" : migrationDoc?.fields?.uuid ?? "",
      files: isCompany ? migrationCompanyDoc?.fields?.files ?? [] : migrationDoc?.fields?.files ?? [],
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
    };

    setCurrentDocumentData(migrationDocData);
  };

  const onContractFormValuesChange = (): void => {
    const rate: string = getFieldValue("rate");
    const salary: string | number = getFieldValue("salary");
    const position: string = getFieldValue("position");
    const workplace: string = getFieldValue("workplace");
    const allowances: string | number = getFieldValue("allowances");
    const isApproved: string = getFieldValue("isApproved");
    const contractType: string = getFieldValue("contract_type");
    const declineReason: string = getFieldValue("declineReason");
    const employmentType: string = getFieldValue("employment_type");
    const paymentMethods: string = getFieldValue("payment_methods");
    const conclusionDate: string = getFieldValue("contract_date");
    const expirationDate: string = getFieldValue("expiration_date");
    const salaryPaymentType: string = getFieldValue("payment_type");

    const contractDocData: UpdateDocumentFieldType = {
      uuid: contractDoc?.fields?.uuid ?? "",
      files: contractDoc?.fields?.files ?? [],
      rate: rate?.toString(),
      salary: salary?.toString(),
      position: position,
      payment_type: salaryPaymentType,
      workplace: workplace,
      allowances: allowances?.toString(),
      employment_type: employmentType,
      contract_type: contractType,
      contract_date: dayjs(conclusionDate).isValid() ? dayjs(conclusionDate).utc(true).format() : null,
      expiration_date: dayjs(expirationDate).isValid() ? dayjs(expirationDate).utc(true).format() : null,
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
      payment_methods: paymentMethods,
    };

    setCurrentDocumentData(contractDocData);
  };

  const onBankDetailsFormValuesChange = (): void => {
    const bic: string = getFieldValue("bic");
    const isApproved: string = getFieldValue("isApproved");
    const declineReason: string = getFieldValue("declineReason");
    const accountNumber: string = getFieldValue("bank_account_number");
    const correspondentAccount: string = getFieldValue("correspondent_account");

    const bankDocData: UpdateDocumentFieldType = {
      uuid: bankDoc?.fields?.uuid ?? "",
      bic: bic,
      files: bankDoc?.fields?.files ?? [],
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
      bank_account_number: accountNumber,
      correspondent_account: correspondentAccount,
    };

    setCurrentDocumentData(bankDocData);
  };

  const onInnFormValuesChange = (): void => {
    const isApproved: string = getFieldValue("isApproved");
    const inn: string = getFieldValue("inn")?.split("-")?.join("")?.split(" ")?.join("");
    const declineReason: string = getFieldValue("declineReason");

    const innDocData: UpdateDocumentFieldType = {
      uuid: innDoc?.fields?.uuid ?? "",
      inn: inn,
      files: innDoc?.fields?.files ?? [],
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
    };

    setCurrentDocumentData(innDocData);
  };

  const onDecisionFormValuesChange = (): void => {
    const isApproved: string = getFieldValue("isApproved");
    const declineReason: string = getFieldValue("declineReason");

    const decisionDocData: UpdateDocumentFieldType = {
      uuid: isCompany ? decisionCompanyDoc?.fields?.uuid ?? "" : decisionDoc?.fields?.uuid ?? "",
      files: isCompany ? decisionCompanyDoc?.fields?.files ?? [] : decisionDoc?.fields?.files ?? [],
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
    };

    setCurrentDocumentData(decisionDocData);
  };

  const onUsnFormValuesChange = (): void => {
    const isApproved: string = getFieldValue("isApproved");
    const declineReason: string = getFieldValue("declineReason");

    const usnDocData: UpdateDocumentFieldType = {
      uuid: isCompany ? usnCompanyDoc?.fields?.uuid ?? "" : usnDoc?.fields?.uuid ?? "",
      files: isCompany ? usnCompanyDoc?.fields?.files ?? [] : usnDoc?.fields?.files ?? [],
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
    };

    setCurrentDocumentData(usnDocData);
  };

  const onPassportFormValuesChange = (): void => {
    const sex: string = getFieldValue("sex");
    const lastName: string = getFieldValue("last_name");
    const issuedBy: string = getFieldValue("issued_by");
    const birthDate: string = getFieldValue("date_of_birth");
    const firstName: string = getFieldValue("first_name");
    const isApproved: string = getFieldValue("isApproved");
    const issuedDate: string = getFieldValue("issued_date");
    const secondName: string = getFieldValue("second_name");
    const citizenship: string = getFieldValue("citizenship");
    const seriesNumber: string = getFieldValue("series_number");
    const divisionCode: string = getFieldValue("subdivision_code");
    const declineReason: string = getFieldValue("declineReason");
    const passportNumber: string = getFieldValue("passport_number");

    const correctDivisionCode: string = divisionCode?.split("-")?.join("");

    const passportDocData: UpdateDocumentFieldType = {
      uuid: isCompany ? passportCompanyDoc?.fields?.uuid ?? "" : passportDoc?.fields?.uuid ?? "",
      sex: sex,
      files: isCompany ? passportCompanyDoc?.fields?.files ?? [] : passportDoc?.fields?.files ?? [],
      last_name: lastName,
      issued_by: issuedBy,
      first_name: firstName,
      issued_date: dayjs(issuedDate).isValid() ? dayjs(issuedDate).utc(true).format() : null,
      citizenship: citizenship,
      second_name: secondName,
      date_of_birth: dayjs(birthDate).isValid() ? dayjs(birthDate).utc(true).format() : null,
      series_number: seriesNumber,
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
      passport_number: passportNumber,
      subdivision_code: correctDivisionCode,
    };

    setCurrentDocumentData(passportDocData);
  };

  const onSnilsFormValuesChange = (): void => {
    const isApproved: string = getFieldValue("isApproved");
    const snilsNumber: string = getFieldValue("snils_number")?.split("-")?.join("")?.split(" ")?.join("");
    const declineReason: string = getFieldValue("declineReason");

    const snilsDocData: UpdateDocumentFieldType = {
      uuid: isCompany ? snilsCompanyDoc?.fields?.uuid ?? "" : snilsDoc?.fields?.uuid ?? "",
      files: isCompany ? snilsCompanyDoc?.fields?.files ?? [] : snilsDoc?.fields?.files ?? [],
      snils_number: snilsNumber,
      decline_reason: isApproved ? declineReason : "",
      document_status: isApproved,
    };

    setCurrentDocumentData(snilsDocData);
  };

  const onApproveChange = (event: RadioChangeEvent | string): void => {
    const approveValue: RadioChangeEvent | string = typeof event !== "string"
      ? event?.target.value
      : event;

    setDocApproveStatus(approveValue);
  };

  useEffect((): void => {
    if (canSaveDocument) {
      isCompany
        ? saveCompanyFormData && saveCompanyFormData(documentUuid, currentDocumentData)
        : saveFormData && saveFormData(documentUuid, currentDocumentData);

      setCanSaveDocument(false);
      setCurrentDocumentData(null);
      resetFields();
    }
  }, [
    saveFormData,
    documentUuid,
    canSaveDocument,
    saveCompanyFormData,
  ]);
  
  const cancelCheck = (): void => {
    return (isClientEmployee || isValidationCompleted) ? closeForm() : setConfirmModalOpen(true);
  };

  const renderDocumentForm = (docType: string): JSX.Element => {
    switch (docType) {
      case "contract-labor-schema":
      case "contract-civil-schema":
        return (
          <ClientProfileCardImageViewerFormAgreement
            docType={docType}
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            documentUuid={documentUuid}
            setCanSaveDoc={setCanSaveDocument}
            agreementForm={imageViewerForm}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onContractFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            setDocumentUuid={setDocumentUuid}
            setDocumentType={setDocumentType}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "bank-schema":
        return (
          <ClientProfileCardImageViewerFormBankDetails
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            documentUuid={documentUuid}
            setCanSaveDoc={setCanSaveDocument}
            bankDetailsForm={imageViewerForm}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onBankDetailsFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "ndfl-schema":
        return (
          <ClientProfileCardImageViewerFormPersonalTax
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            incomeForm={imageViewerForm}
            documentUuid={documentUuid}
            setCanSaveDoc={setCanSaveDocument}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onIncomeTaxFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "inn-schema":
        return (
          <ClientProfileCardImageViewerFormInn
            innForm={imageViewerForm}
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            documentUuid={documentUuid}
            setCanSaveDoc={setCanSaveDocument}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onInnFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "migration-card-schema":
      case "patent-schema":
        return (
          <ClientProfileCardImageViewerFormPatent
            docType={docType}
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            documentUuid={documentUuid}
            migrationForm={imageViewerForm}
            setCanSaveDoc={setCanSaveDocument}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onMigrationCardFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "mdm_founders-decision-schema":
      case "founders-decision-schema":
        return (
          <ClientProfileCardImageViewerFormDecision
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            documentUuid={documentUuid}
            decisionForm={imageViewerForm}
            setCanSaveDoc={setCanSaveDocument}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onDecisionFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "snils-schema":
        return (
          <ClientProfileCardImageViewerFormSnils
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            snilsForm={imageViewerForm}
            documentUuid={documentUuid}
            setCanSaveDoc={setCanSaveDocument}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onSnilsFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            isEmployee={isEmployee}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "usn-schema":
        return (
          <ClientProfileCardImageViewerFormUsn
            usnForm={imageViewerForm}
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            documentUuid={documentUuid}
            setCanSaveDoc={setCanSaveDocument}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onUsnFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
        );
      case "passport-schema":
      default:
        return (
          <ClientProfileCardImageViewerFormPassport
            closeForm={closeForm}
            cancelCheck={cancelCheck}
            documentUuid={documentUuid}
            passportForm={imageViewerForm}
            setCanSaveDoc={setCanSaveDocument}
            onApproveChange={onApproveChange}
            docApproveStatus={docApproveStatus}
            onFormValuesChange={onPassportFormValuesChange}
            setDocFilesLinks={setDocFilesLinks}
            setTotalDocFiles={setTotalDocFiles}
            isEmployee={isEmployee}
            isClientEmployee={isClientEmployee}
            isValidationCompleted={isValidationCompleted}
            isNotAgreedDocs={isNotAgreedDocs}
            currentDocumentData={currentDocumentData}
            isConfirmModalOpen={isConfirmModalOpen}
            setConfirmModalOpen={setConfirmModalOpen}
          />
      );
    }
  };

  const renderPages = (): JSX.Element[] => {
    return [...Array(documentPages), null]
    ?.map((_x, i: number) => i + 1)
    ?.map((page: number) =>
      // Пустые атрибуты являются заглушками, чтоб не выводить текст процесса загрузки
      <Page
        key={`page_${page}`}
        pageNumber={page}
        className={css.pdfPageHeight}
        error=""
        loading=""
        noData=""
        renderForms={false}
        renderTextLayer={false}
        renderAnnotationLayer={false}
      />
    );
  };

  const refFileBlock: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const labelWidth: number = refFileBlock?.current?.clientWidth
    ? refFileBlock?.current?.clientWidth - (refFileBlock?.current?.clientWidth * 0.15)
    : 0;

  const contentTooltip = (name: string | null): JSX.Element => {
    return (
      <div className={css.tooltip}>
        {name}
      </div>
    );
  };

  return (
    <Modal
      open={isImageViewerOpen}
      onOk={closeForm}
      onCancel={closeForm}
      centered
      width="100vw"
      closable={false}
      footer={null}
      wrapClassName={css.modalWrap}
    >
      <div className="flex justify-between items-stretch h-screen">
        <div
          className={`absolute z-10 top-6 left-5 text-gray-400 bg-white ${css.imgNameBorderRadius}`}
          style={{maxWidth: `${labelWidth}px`}}
        >
          <Tooltip
            placement="bottomLeft"
            title={contentTooltip(documentFileName)}
            trigger="hover"
            overlayInnerStyle={{width: `${labelWidth}px`}}
            overlayStyle={documentFileName ? {} : {display: "none"}}
          >
            <span className={`${css.labelStyle} block p-1`}>
              {documentFileName}
            </span>
          </Tooltip>
        </div>
        <div
          className={`relative flex items-center justify-center w-full bg-gray-200 ${css.imgBorderRadius}`}
          ref={refFileBlock}
        >
          <TransformWrapper wheel={{ wheelDisabled: true, disabled: true }}>
            <Controls />
            <TransformComponent contentClass="cursor-grab">
              {documentContentType === "application/pdf" && (
                <div style={{ transform: `rotate(${rotateDeg}deg)` }} className={css.documentWrapScroll}>
                  <Document
                    error=""
                    loading={<LoadingCustom className={css.pdfLoading} />}
                    noData=""
                    options={pdfDownloadOptions}
                    className={css.documentPdfStyle}
                    file={`${API_TASKS_URL}/api/mdm/storage/v1/${docFilesLinks[currentPage - 1]}`}
                    onLoadSuccess={(pdf) => setDocumentPages(pdf?.numPages)}
                  >
                    {renderPages()}
                  </Document>
                </div>
              )}
              {documentContentType === "image/png" && (
                <img
                  className="h-85vh"
                  style={{ transform: `rotate(${rotateDeg}deg)` }}
                  src={img}
                  alt="Изображение документа"
                  width="100%"
                />
              )}
              {documentContentType === "image/jpeg" && (
                <img
                  className="h-85vh"
                  style={{ transform: `rotate(${rotateDeg}deg)` }}
                  src={img}
                  alt="Изображение документа"
                  width="100%"
                />
              )}
              {documentContentType === "image/tiff" && (
                <TIFFViewer
                  className="h-85vh"
                  style={{ transform: `rotate(${rotateDeg}deg)` }}
                  tiff={img}
                  lang="en"
                  paginate={false}
                  printable={false}
                  width="100%"
                />
              )}
            </TransformComponent>
          </TransformWrapper>
          <div className="absolute bottom-7">
            <Pagination
              total={totalDocFiles}
              pageSize={1}
              responsive
              defaultCurrent={currentPage}
              onChange={onPaginationChange}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between items-center mx-6 my-4">
            <h1 className={css.modalHeaderText}>
              Предпросмотр файла
            </h1>
            <Button
              id="closeModal"
              className="border-0"
              shape="circle"
              onClick={cancelCheck}
            >
              <CloseOutlined />
            </Button>
          </div>
          <Divider className="m-0" />
          {renderDocumentForm(documentType)}
        </div>
      </div>
    </Modal>
  );
};

export default ClientProfileCardImageViewer;
