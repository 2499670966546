import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import {
  GetFile1CType,
  UploadedFilesPropsType,
  TypicalTaskDecisionType,
} from "app/types";
import {
  fetchDocumentTask,
  sendTypicalTaskDecision,
  sendTypicalTaskDecisionFailure
} from "../../../../../actions/tasks.actions";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { deleteUploadFile } from "../../../../../api/document.api";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close_icon.svg";
import { renderActionDrawerDescr } from "./utils/renderActionDrawerDescription";
import ChangeTaskImplementer from "./TaskCardDrawerActions/ChangeTaskImplementer";
import AutomaticProcessing from "./TaskCardDrawerActions/AutomaticProcessing/AutomaticProcessing";
import TaskCardConfirmModal from "../../TaskCardConfirmModal/TaskCardConfirmModal";
import SendClarification from "./TaskCardDrawerActions/SendClarification";
import ChooseAppealTopic from "./TaskCardDrawerActions/ChooseAppealTopic";
import ReassignTask from "./TaskCardDrawerActions/ReassignTask";
import RecognizeKnap from "./TaskCardDrawerActions/RecognizeKnap/RecognizeKnap";
import UploadFilesListing from "../../../../../utils/ModalUploadItems/UploadFilesListing/UploadFilesListing";
import { Form, message } from "antd";
import css from "./TaskCardActionDrawer.module.css";

interface ITaskCardActionDrawerProps {
  taskInn: string;
  taskUuid: string;
  taskType: string;
  taskFile1CUuid: string | null;
  taskFile1CData: GetFile1CType | null;
  drawerTaskAction: string;
  parsingResultUuid: string;
  decisionTypeStatus: number;
  isAutomaticProcess: boolean;
  setDrawerTaskAction: Dispatch<SetStateAction<string>>;
  parsingResultStatus: number;
  file1CDownloadStatus: number;
  markupTaskFileFrom1C: UploadedFilesPropsType[] | null;
  markupTaskStatusForm1C: string | null;
  documentSchemaVersion: number;
  setAutomaticProcessingActive: Dispatch<SetStateAction<boolean>>;
  isActionDisabled: boolean;
  declarationType: string;
  declarationEndDate: string;
  declarationStartDate: string;
  isAutomaticProcessingActive: boolean;
}

enum DecisionTaskStatus {
  Initial = 0,
  Success = 200,
}

const TaskCardActionDrawer: FC<ITaskCardActionDrawerProps> = ({
  taskInn,
  taskUuid,
  taskType,
  taskFile1CUuid,
  taskFile1CData,
  drawerTaskAction,
  parsingResultUuid,
  decisionTypeStatus,
  isAutomaticProcess,
  setDrawerTaskAction,
  parsingResultStatus,
  file1CDownloadStatus,
  markupTaskFileFrom1C,
  markupTaskStatusForm1C,
  documentSchemaVersion,
  setAutomaticProcessingActive,
  isActionDisabled,
  declarationType,
  declarationEndDate,
  declarationStartDate,
  isAutomaticProcessingActive,
}): JSX.Element => {
  const [isFormHasValue, setFormHasValue] = useState<boolean>(false);
  const [isCloseModalOpen, setCloseModalOpen] = useState<boolean>(false);
  const [taskActionComment, setTaskActionComment] = useState<string>("");
  const [remainingCharsComment, setRemainingCharsComment] = useState<number>(0);
  const [dragAndDropFiles, setDragAndDropFiles] = useState<UploadedFilesPropsType[]>([]);
  const [dragAndDropFilesCount, setDragAndDropFilesCount] = useState<number>(0);

  const clarificationHasFiles: boolean = !!taskFile1CData?.files?.length || !!markupTaskFileFrom1C?.length;
  const isSuccessDecisionType: boolean = decisionTypeStatus === DecisionTaskStatus.Success;
  const isMarkupTask: boolean = taskType === "Разметка операций"
    && drawerTaskAction === "Обработать задачу автоматически";

  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  useEffect((): void => {
    if (values) {
      const hasValue: boolean = Object.values(values).some((value) => {
        if (Array.isArray(value)) {
          return !!value.length;
        }

        return Boolean(value);
      });

      setFormHasValue(hasValue);
    }
  }, [values]);

  useEffect((): void => {
    if (isSuccessDecisionType) {
      message.success("Действие по задаче успешно выполнено");

      fetchTask();
      setDrawerTaskAction("");
    } else if (decisionTypeStatus !== DecisionTaskStatus.Initial) {
      message.error("Произошла ошибка при выполнении действия по задаче. Пожалуйста, повторите позже");
    }

    setAutomaticProcessingActive(false);
    dispatch(sendTypicalTaskDecisionFailure(DecisionTaskStatus.Initial));
  }, [decisionTypeStatus]);

  const fetchTask = (): NodeJS.Timeout => {
    return setTimeout(() => {
      dispatch(fetchDocumentTask(taskUuid));
    }, 500);
  };

  const onDrawerClose = (): void => {
    !isMarkupTask
      ? setCloseModalOpen(true)
      : setDrawerTaskAction("");
  };

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement> | string): void => {
    const value: string = typeof e === "string" ? e : e.target.value;
    const charsCount: number = value.length;

    setTaskActionComment(value);
    setRemainingCharsComment(charsCount);
  };

  const onFinish = (actionBody: TypicalTaskDecisionType, isReassignTask?: boolean): void => {
    if (documentSchemaVersion && values || isReassignTask) {
      dispatch(sendTypicalTaskDecision(documentSchemaVersion, actionBody));

      setAutomaticProcessingActive(true);
    }
  };

  const handleRemoveFile = async (
    e: React.MouseEvent<HTMLElement>,
    fileRemove: UploadedFilesPropsType
  ): Promise<void> => {
    e.preventDefault();

    try {
      const { uuid } = fileRemove;
      const params = new URLSearchParams({ file_uuid: uuid });

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setDragAndDropFiles((prevFiles: UploadedFilesPropsType[]): UploadedFilesPropsType[] => {
        const newFiles: UploadedFilesPropsType[] =
          prevFiles?.filter((file: UploadedFilesPropsType) => file.uuid !== uuid) ?? [];

        setDragAndDropFilesCount(newFiles.length);
        return newFiles;
      });
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const uploadedFilesList: JSX.Element[] | null = dragAndDropFiles?.length ? (
    dragAndDropFiles.map((file: UploadedFilesPropsType) => (
      <UploadFilesListing
        key={file.uuid}
        file={file}
        handleRemoveFile={handleRemoveFile}
        markupTaskFileFrom1C={markupTaskFileFrom1C}
      />
    ))
  ) : null;

  const renderDrawerActions = (): JSX.Element => {
    switch (drawerTaskAction) {
      case "Выбрать тему обращения":
        return (
          <ChooseAppealTopic
            form={form}
            values={values}
            taskUuid={taskUuid}
            onFinish={onFinish}
            onDrawerClose={onDrawerClose}
            isFormHasValue={isFormHasValue}
            isAutomaticProcessingActive={isAutomaticProcessingActive}
          />
        );
      case "Переназначить задачу":
        return (
          <ReassignTask
            form={form}
            taskUuid={taskUuid}
            onFinish={onFinish}
            onDrawerClose={onDrawerClose}
            taskActionComment={taskActionComment}
            handleTextAreaChange={handleTextAreaChange}
            remainingCharsComment={remainingCharsComment}
            isAutomaticProcessingActive={isAutomaticProcessingActive}
          />
        );
      case "Сменить исполнителя":
        return (
          <ChangeTaskImplementer
            form={form}
            taskUuid={taskUuid}
            onFinish={onFinish}
            onDrawerClose={onDrawerClose}
            isAutomaticProcessingActive={isAutomaticProcessingActive}
          />
        );
      case "Отправить на уточнение":
      case "Обработать задачу вручную":
        return (
          <SendClarification
            form={form}
            taskUuid={taskUuid}
            taskType={taskType}
            onFinish={onFinish}
            onDrawerClose={onDrawerClose}
            dragAndDropFiles={dragAndDropFiles}
            drawerTaskAction={drawerTaskAction}
            taskActionComment={taskActionComment}
            uploadedFilesList={uploadedFilesList}
            setDragAndDropFiles={setDragAndDropFiles}
            markupTaskFileFrom1C={markupTaskFileFrom1C}
            handleTextAreaChange={handleTextAreaChange}
            remainingCharsComment={remainingCharsComment}
            dragAndDropFilesCount={dragAndDropFilesCount}
            setDragAndDropFilesCount={setDragAndDropFilesCount}
            isAutomaticProcessingActive={isAutomaticProcessingActive}
          />
        );
      case "Распознать документы в КНАП":
        return (
          <RecognizeKnap
            taskUuid={taskUuid}
            onFinish={onFinish}
            onDrawerClose={onDrawerClose}
            isAutomaticProcessingActive={isAutomaticProcessingActive}
          />
        );
      case "Обработать задачу автоматически":
        return (
          <AutomaticProcessing
            form={form}
            taskInn={taskInn}
            taskUuid={taskUuid}
            taskType={taskType}
            onFinish={onFinish}
            fetchTask={fetchTask}
            onDrawerClose={onDrawerClose}
            taskFile1CUuid={taskFile1CUuid}
            taskFile1CData={taskFile1CData}
            drawerTaskAction={drawerTaskAction}
            dragAndDropFiles={dragAndDropFiles}
            taskActionComment={taskActionComment}
            uploadedFilesList={uploadedFilesList}
            parsingResultUuid={parsingResultUuid}
            isAutomaticProcess={isAutomaticProcess}
            setDrawerTaskAction={setDrawerTaskAction}
            setDragAndDropFiles={setDragAndDropFiles}
            parsingResultStatus={parsingResultStatus}
            file1CDownloadStatus={file1CDownloadStatus}
            markupTaskFileFrom1C={markupTaskFileFrom1C}
            handleTextAreaChange={handleTextAreaChange}
            isSuccessDecisionType={isSuccessDecisionType}
            remainingCharsComment={remainingCharsComment}
            dragAndDropFilesCount={dragAndDropFilesCount}
            clarificationHasFiles={clarificationHasFiles}
            markupTaskStatusForm1C={markupTaskStatusForm1C}
            setDragAndDropFilesCount={setDragAndDropFilesCount}
            setAutomaticProcessingActive={setAutomaticProcessingActive}
            isActionDisabled={isActionDisabled}
            declarationType={declarationType}
            declarationEndDate={declarationEndDate}
            declarationStartDate={declarationStartDate}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={css.actionDrawerWrap}>
      <div className="flex items-center mb-4">
        <ButtonCustom
          className="mr-4 p-0 gap-0"
          type="link"
          text=""
          size="small"
          onClick={onDrawerClose}
        >
          <CloseIcon />
        </ButtonCustom>
        <h3 className={css.actionDrawerHeader}>{drawerTaskAction}</h3>
      </div>
      <p className={css.actionDrawerDescr}>{renderActionDrawerDescr(drawerTaskAction)}</p>
      <div>{renderDrawerActions()}</div>
      <TaskCardConfirmModal
        taskUuid={taskUuid}
        confirmTaskAction="closeModal"
        isConfirmModalOpen={isCloseModalOpen}
        setConfirmModalOpen={setCloseModalOpen}
        setDrawerTaskAction={setDrawerTaskAction}
        setDragAndDropFiles={setDragAndDropFiles}
        setDragAndDropFilesCount={setDragAndDropFilesCount}
        setAutomaticProcessingActive={setAutomaticProcessingActive}
      />
    </div>
  );
};

export default TaskCardActionDrawer;
