import { ChangeEvent } from "react";
import { FormInstance } from "antd";

export const handleLettersOnlyChange = (
  event: ChangeEvent<HTMLInputElement> | string,
  fieldName: string,
  form: FormInstance
): void => {
  const inputValue: string = typeof event !== "string"
    ? event.target.value.replace(/[^A-Za-zА-Яа-яЁё\s\-_:,"']/g, "")
    : event;

  // Для латиницы используйте /[A-Za-z]/, для кириллицы /[А-Яа-яЁё]/
  form.setFieldValue(fieldName, inputValue);
};
