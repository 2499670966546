import {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from "react";
import { FilterTasksType } from "app/types";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { actionBtnsData, IActionBtnsDataProps } from "./utils/actionBtnsData";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { UserDataType } from "../UsersListTable/UsersListTable";

interface IUsersListActionsProps {
  paramsData: FilterTasksType | null;
  setParamsData: Dispatch<SetStateAction<FilterTasksType | null>>;
  activeUsersCount: number;
  notActiveUsersCount: number;
  setIsOpenBlockModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenActiveModal: Dispatch<SetStateAction<boolean>>;
  selectedRows: UserDataType[];
  setUserData: Dispatch<SetStateAction<UserDataType[]>>;
}

const renderActionBtns = (
  activeUsers: number,
  notActiveUsers: number,
  handleOpenBlockModal: () => void,
  handleOpenActiveModal: () => void,
): JSX.Element[] => actionBtnsData.map(({
  id,
  type,
  size,
  text,
  danger,
  className,
}: IActionBtnsDataProps) => (
  <ButtonCustom
    key={id}
    type={type}
    size={size}
    text={danger ? `${text} (${activeUsers})` : `${text} (${notActiveUsers})`}
    onClick={danger ? handleOpenBlockModal : handleOpenActiveModal}
    danger={danger}
    className={danger ? `${!activeUsers && "hidden"} ${className}` : `${!notActiveUsers && "hidden"} ${className}`}
  />
));

const searchIconStyle = { opacity: .6, fontSize: 14 };

//TODO: Действия по таблице пока моковые, к АПИ подключим в рамках другой задачи
const UsersListActions: FC<IUsersListActionsProps> = ({
  paramsData,
  setParamsData,
  activeUsersCount,
  notActiveUsersCount,
  setIsOpenBlockModal,
  setIsOpenActiveModal,
  selectedRows,
  setUserData
}): JSX.Element => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    return () => {
      setInputValue("");
    };
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => setParamsData({
    ...paramsData,
    search: e?.target?.value?.trim()?.length ? e?.target?.value : null,
  });

  const handleDebounceSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue: string = event.target.value;
    setInputValue(newValue);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer: NodeJS.Timeout = setTimeout((): void => {
      if (handleSearch) {
        handleSearch(event);
      }
    }, 500);

    setTimer(newTimer);
  };
  
  const handleOpenBlockModal = (): void => {
    setIsOpenBlockModal(true);
    setUserData(selectedRows);
  };
  
  const handleOpenActiveModal = (): void => {
    setIsOpenActiveModal(true);
    setUserData(selectedRows);
  };

  return (
    <div className="flex mb-4">
      {renderActionBtns(
        activeUsersCount,
        notActiveUsersCount,
        handleOpenBlockModal,
        handleOpenActiveModal
      )}
      <Input
        placeholder="Найти по ФИО или email"
        suffix={<SearchOutlined style={searchIconStyle} />}
        size="large"
        allowClear
        value={inputValue}
        onChange={handleDebounceSearch}
      />
    </div>
  );
};

export default UsersListActions;
