import { ITypesOfProfessionProps } from "../AgreementForm";

export const typeOfSalaryPayment: ITypesOfProfessionProps[] = [
  {
    value: "Через кассу",
    label: "Через кассу"
  },
  {
    value: "В рамках зарплатного проекта",
    label: "В рамках зарплатного проекта"
  },
  {
    value: "На счета сотрудников",
    label: "На счета сотрудников"
  },
];
