import dayjs from "dayjs";
import { CatalogTaskStatusType } from "../../../../utils/catalog";

export interface IActiveFilterProps {
  deadline_gte?: string,
  priority?: string,
  responsible_lookup?: string,
}

export const filterVariant: IActiveFilterProps = {
  deadline_gte: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  priority: "Высокий",
};
export const profileTask: CatalogTaskStatusType[] = [
  { status: "responsible_lookup", label: "Мои задачи" },
];
export const taskFilters: CatalogTaskStatusType[] = [
  { status: "deadline_gte", label: "Срочные" },
  { status: "priority", label: "Высокий приоритет" },
];

export const tomorrowDate: string = dayjs().add(1, "day").format("YYYY-MM-DD HH:mm:ss");
