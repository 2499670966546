import { ChangeEvent } from "react";
import { FormInstance } from "antd";

export const handleTariffRateChange = (
  event: ChangeEvent<HTMLInputElement>,
  form: FormInstance,
): void => {
  const { value } = event.target;

  let formattedValue: string;

  if (/^0/.test(value)) {
    formattedValue = value.startsWith("0.") ? "0." + value.slice(2).replace(/[^0-9]/g, "").slice(0, 2) : "0.";
  } else {
    formattedValue = value.replace(/[^0-9.]/g, "").slice(0, 4);
  }

  formattedValue = formattedValue.endsWith("0") ? formattedValue.slice(0, -1) : formattedValue;

  form.setFieldValue("rate", parseFloat(formattedValue) >= 1 ? "1" : formattedValue);
};
