import { ChangeEvent, FC, JSX } from "react";
import { paymentMethods } from "../utils/paymentMethods";
import { isFormItemRequiredRule } from "../../../../../../utils/isFormItemRequiredRule";
import { handleDigitsOnlyChange } from "../utils/handleDigitsOnlyChange";
import { formFieldsProps } from "../utils/formFieldsProps";
import { validateTriggersArr } from "../../../ClientProfileCardImageViewer";
import dayjs, { Dayjs } from "dayjs";
import {
  Form,
  Input,
  Select,
  DatePicker,
} from "antd";
import { IContractFormPropsProps } from "../AgreementForm";

const CivilContractForm: FC<IContractFormPropsProps> = ({
  setDateValue,
  agreementForm,
  docApproveStatus,
  contractDocSchema,
  isFormItemDisabled,
  isContractFormDisabled,
  isContractTypeChanging,
}): JSX.Element => {
  return (
    <>
      <Form.Item
        rootClassName="w-full"
        name="contract_date"
        label="Дата заключения договора"
        rules={[
          {
            required: isFormItemRequiredRule("contract_date", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <DatePicker
          id="contract_date"
          className="w-full"
          placeholder={formFieldsProps.datePlaceholder}
          style={{
            padding: "6.35px"
          }}
          size={formFieldsProps.largeSize}
          format={formFieldsProps.dateFormat}
          allowClear={!docApproveStatus}
          inputReadOnly={!formFieldsProps.isReadOnly}
          onChange={(date: Dayjs | null): void => setDateValue(date, "contract_date")}
          disabledDate={(current: Dayjs) => dayjs().isBefore(current)}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
      <Form.Item
        rootClassName="w-full"
        name="expiration_date"
        label="Срок действия"
        rules={[
          {
            required: true,
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <DatePicker
          id="expiration_date"
          className="w-full"
          placeholder={formFieldsProps.datePlaceholder}
          style={{
            padding: "6.35px"
          }}
          size={formFieldsProps.largeSize}
          format={formFieldsProps.dateFormat}
          allowClear={!docApproveStatus}
          inputReadOnly={!formFieldsProps.isReadOnly}
          onChange={(date: Dayjs | null): void => setDateValue(date, "expiration_date")}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
      <Form.Item
        name="salary"
        label="Сумма договора (в рублях)"
        rootClassName="w-full mr-2"
        rules={[
          {
            required: isFormItemRequiredRule("salary", contractDocSchema),
            message: "Только цифры"
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          id="salary"
          size={formFieldsProps.largeSize}
          addonAfter={<span className="text-gray-400">₽</span>}
          placeholder="Введите сумму договора"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleDigitsOnlyChange(e, "salary", agreementForm)}
          readOnly={isContractFormDisabled}
          disabled={isContractTypeChanging}
        />
      </Form.Item>
      <Form.Item
        name="payment_methods"
        label="Способ оплаты"
        rules={[
          {
            required: isFormItemRequiredRule("payment_methods", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Select
          id="payment_methods"
          placeholder="Выберите способ оплаты"
          options={paymentMethods}
          allowClear
          size={formFieldsProps.largeSize}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
    </>
  );
};

export default CivilContractForm;
