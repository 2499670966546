import React, {
  FC,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import {
  UpdateDocumentFilesType,
  DocumentClientProfileType,
  DocumentEmployeeProfileType,
} from "app/types";
import { AppDispatch } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentSchema,
  getEmployeeDocumentData,
  createNewDocumentFailure,
} from "../../../../../actions/document.actions";
import { AppStateType } from "../../../../../reducers/mainReducer";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { getUnAgreedDocumentType } from "../../../../../actions/clientProfile.actions";
import { updateEmployeeProfileData } from "../../../../../actions/employeeProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../../utils/isDocRequriedValuesFiiled";
import { handleLettersOnlyChange } from "./utils/handleLettersOnlyChange";
import { handleDigitsOnlyChange } from "./utils/handleDigitsOnlyChange";
import { roleResolver } from "../../../../../utils/roleResolver";
import AgreementForm from "./AgreementForm";
import { JWTPayload } from "jose";
import dayjs from "dayjs";
import {
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import css from "../../ClientProfileCardImageViewer.module.css";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

interface IClientProfileCardImageViewerFormAgreementProps {
  docType: string;
  agreementForm: FormInstance;
  closeForm: () => void;
  cancelCheck: () => void;
  documentUuid: string;
  setCanSaveDoc: Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  setDocumentUuid?: Dispatch<SetStateAction<string>>;
  setDocumentType?: Dispatch<SetStateAction<string>>;
  isConfirmModalOpen: boolean;
  setConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ClientProfileCardImageViewerFormAgreement: FC<IClientProfileCardImageViewerFormAgreementProps> = ({
  docType,
  agreementForm,
  closeForm,
  cancelCheck,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  setDocumentUuid,
  setDocumentType,
  isConfirmModalOpen,
  setConfirmModalOpen,
}) => {
  const [contractType, setContractType] = useState<string>("");
  const [isContractTypeChanged, setContractTypeChanged] = useState<boolean>(false);
  const [isContractTypeChanging, setContractTypeChanging] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentEmployeeProfile: DocumentEmployeeProfileType | null =
    useSelector((state: AppStateType) => state.employeeProfile.documentEmployeeProfile);

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const {
    newDocument,
    contractDoc,
    contractDocSchema,
  } = useSelector((state: AppStateType) => state.document);
  
  const isContactCenter:boolean = roleResolver(decodedToken).isContactCenter;
  const isContractFormDisabled: boolean|undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled, isContactCenter]);

  useEffect(() => {
    isDocRequiredValuesFilled(contractDocSchema, agreementForm, setDocApprovalDisabled);

    onFormValuesChange();
  }, [agreementForm, contractDocSchema, onFormValuesChange, isDocApprovalDisabled, setDocApprovalDisabled]);

  useEffect(() => {
    if (!documentEmployeeProfile?.fields) return;

    const { contract_civil: contractCivil, contract_labor: contractLabor } = documentEmployeeProfile.fields;

    const newContractType: string | undefined =
      contractCivil?.length > 0 ? "Договор ГПХ" :
        contractLabor?.length > 0 ? "Трудовой договор" :
          undefined;

    if (newContractType) {
      setContractType(newContractType);
    }
  }, [documentEmployeeProfile]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = contractDoc?.fields?.decline_reason ||
        "Необходимо приложить верный документ (приложен не тот документ)";

      agreementForm.setFieldValue("declineReason", docMigrationDeclineReason);
      onFormValuesChange();
    }
  }, [docApproveStatus, contractDoc?.fields?.decline_reason, agreementForm, onFormValuesChange]);

  useEffect(() => {
    const documentStatus: string = contractDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = contractDoc?.fields?.files?.map(({ uuid }: UpdateDocumentFilesType) => uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, contractDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema(docType));
      dispatch(getEmployeeDocumentData(docType, documentUuid));
    }
  }, [documentUuid, docType, isNotAgreedDocs]);

  useEffect(() => {
    if (!contractDoc?.fields) return;

    const {
      rate: docContractRate,
      contract_type: docContractTypeRaw,
      salary: docContractSalary,
      document_status: docContractStatus,
      position: docContractPosition,
      workplace: docContractWorkplace,
      allowances: docContractAllowances,
      payment_methods: docContractPaymentMethods,
      decline_reason: docContractDeclineReason,
      employment_type: docContractEmploymentType,
      contract_date: docContractConclusionDateRaw,
      expiration_date: docContractExpirationDateRaw,
      payment_type: docContractSalaryPaymentType,
    } = contractDoc.fields;

    const docContractType = docContractTypeRaw || contractType;
    const docContractConclusionDate = dayjs(docContractConclusionDateRaw);
    const docContractExpirationDate = dayjs(docContractExpirationDateRaw);

    const fieldsToUpdate = {
      isApproved: docContractStatus,
      employment_type: docContractEmploymentType,
      contract_type: docContractType,
      payment_type: docContractSalaryPaymentType,
      contract_date: docContractConclusionDate.isValid() ? docContractConclusionDate : null,
      expiration_date: docContractExpirationDate.isValid() ? docContractExpirationDate : null,
    };

    const numericFields = [
      { value: docContractRate, name: "rate" },
      { value: docContractSalary, name: "salary" },
      { value: docContractAllowances, name: "allowances" },
    ];

    const textFields = [
      { value: docContractPosition, name: "position" },
      { value: docContractWorkplace, name: "workplace" },
      { value: docContractPaymentMethods, name: "payment_methods" },
    ];

    Object.entries(fieldsToUpdate).forEach(([field, value]) => {
      agreementForm.setFieldValue(field, value);
    });

    numericFields.forEach(({ value, name }) => {
      if (value !== undefined) {
        handleDigitsOnlyChange(value as string | number, name, agreementForm);
      }
    });

    textFields.forEach(({ value, name }) => {
      if (value) handleLettersOnlyChange(value, name, agreementForm);
    });

    if (docContractDeclineReason) {
      agreementForm.setFieldValue("declineReason", docContractDeclineReason);
    }

    onApproveChange(docContractStatus);
  }, [contractDoc]);

  useEffect(() => {
    if (!documentEmployeeProfile?.fields?.uuid || !newDocument) return;

    const createEmployeeProfileDoc = (contractTypeKey: "contract_labor" | "contract_civil") => ({
      uuid: documentEmployeeProfile.fields.uuid,
      full_name: documentEmployeeProfile.fields.full_name,
      inn_document: documentEmployeeProfile.fields.inn_document?.[0]?.uuid,
      bank_document: documentEmployeeProfile.fields.bank_document?.[0]?.uuid,
      external_uuid: documentEmployeeProfile.fields.external_uuid,
      snils_document: documentEmployeeProfile.fields.snils_document?.[0]?.uuid,
      migration_card: documentEmployeeProfile.fields.migration_card?.[0]?.uuid,
      contract_civil: contractTypeKey === "contract_civil" ? newDocument.fields.uuid : [],
      contract_labor: contractTypeKey === "contract_labor" ? newDocument.fields.uuid : [],
      employee_status: documentEmployeeProfile.fields.employee_status,
      passport_document: documentEmployeeProfile.fields.passport_document?.[0]?.uuid,
      client_profile_ip: documentEmployeeProfile.fields.client_profile_ip?.[0]?.uuid,
      client_profile_ooo: documentEmployeeProfile.fields.client_profile_ooo?.[0]?.uuid,
      income_tax_deduction: documentEmployeeProfile.fields.income_tax_deduction?.[0]?.uuid,
    });

    const handleUpdate = (schema: string, contractTypeKey: "contract_labor" | "contract_civil"): void => {
      const newEmployeeProfileDoc = createEmployeeProfileDoc(contractTypeKey);

      setDocumentType?.(schema);
      setDocumentUuid?.(newDocument.fields.uuid);
      dispatch(updateEmployeeProfileData(documentEmployeeProfile.fields.uuid, newEmployeeProfileDoc));
      dispatch(createNewDocumentFailure());
      setTimeout(() => setContractTypeChanged(true), 500);
    };

    if (contractType === "Трудовой договор") {
      handleUpdate("contract-labor-schema", "contract_labor");
    } else if (contractType === "Договор ГПХ") {
      handleUpdate("contract-civil-schema", "contract_civil");
    }
  }, [newDocument, documentEmployeeProfile, contractType, setDocumentType, setDocumentUuid, setContractTypeChanged]);

  useEffect(() => {
    if (isContractTypeChanged) {
      setContractTypeChanged(false);
      setContractTypeChanging(false);
      dispatch(getDocumentSchema(docType));
      dispatch(getEmployeeDocumentData(docType, documentUuid));
    }
  }, [dispatch, isContractTypeChanged]);

  const saveForm = (): void => {
    setCanSaveDoc(true);
    agreementForm.resetFields();
    dispatch(getUnAgreedDocumentType(true));
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>Договор</h2>
        {!isContractFormDisabled && (
          <p className="mb-5 gray-color w-full">
            После проверки правильности документа
            <br />
            заполните соответствующие данные
            <br />
            и подтвердите его согласованность.
          </p>
        )}
        <AgreementForm
          contractDoc={contractDoc}
          contractType={contractType}
          agreementForm={agreementForm}
          isContactCenter={isContactCenter}
          onApproveChange={onApproveChange}
          setContractType={setContractType}
          docApproveStatus={docApproveStatus}
          isClientEmployee={isClientEmployee}
          contractDocSchema={contractDocSchema}
          onFormValuesChange={onFormValuesChange}
          isDocApprovalDisabled={isDocApprovalDisabled}
          isValidationCompleted={isValidationCompleted}
          isContractFormDisabled={isContractFormDisabled}
          isContractTypeChanging={isContractTypeChanging}
          documentEmployeeProfile={documentEmployeeProfile}
          setContractTypeChanging={setContractTypeChanging}
        />
      </div>
      <Divider className="mt-0 mb-5" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={cancelCheck}
          text={isContractFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isContractFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={saveForm}
            text="Сохранить"
            size="large"
            disabled={isDocVerified}
          />
        )}
      </div>
      <ConfirmModal
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setConfirmModalOpen}
        closeForm={closeForm}
      />
    </div>
  );
};

export default ClientProfileCardImageViewerFormAgreement;
