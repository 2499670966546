import * as jose from "jose";

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

export const roleResolver = (decodedToken: IJWTPayloadProps | null): { [key: string]: boolean } => {
  const roles: string[] = decodedToken?.resource_access?.bc?.roles ?? [];

  const getRole = (role: string): boolean => roles?.includes(role) ?? false;

  return {
    isAdmin: getRole("admin-role"),
    isAccountant: getRole("accountant-role"),
    isAssistant: getRole("assistant-role"),
    isContactCenter: getRole("contact-center-role"),
    isTechSupport: getRole("tech-support-role"),
    isQualityControl: getRole("quality-control-role"),
    isAlphaAdmin: getRole("alpha-admin-role"),
    isAlphaContractor: getRole("alpha-contractor-role"),
    isSupervisor: getRole("supervisor-role"),
  };
};
