import {
  FC,
  JSX,
  Key,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { FilterTasksType, KeycloakUsersType } from "app/types";
import { roleList } from "../../../../utils/performersRole";
import { useTableSorter } from "../../../../utils/handleSorterTable";
import TagCustom from "../../../ui-kit/TagCustom/TagCustom";
import UsersListTableSettings from "./UsersListTableSettings";
import { TableRowSelection } from "antd/es/table/interface";
import { Table, TableColumnsType } from "antd";
import dayjs from "dayjs";

interface IUsersListTableProps {
  isLoading: boolean;
  setUserData: Dispatch<SetStateAction<UserDataType[]>>
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setModalVariant: Dispatch<SetStateAction<string>>;
  setIsOpenBlockModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenActiveModal: Dispatch<SetStateAction<boolean>>;
  setSortValue: Dispatch<SetStateAction<string | null>>;
  setParamsData: Dispatch<SetStateAction<FilterTasksType | null>>;
  keycloakUsersData: KeycloakUsersType[];
  setActiveUsersCount: Dispatch<SetStateAction<number>>;
  setNotActiveUsersCount: Dispatch<SetStateAction<number>>;
  selectedRows: UserDataType[];
  setSelectedRows: Dispatch<SetStateAction<UserDataType[]>>;
}

export interface UserDataType {
  id?: string;
  key?: string;
  roles?: JSX.Element[] | string;
  full_name?: string;
  email?: string;
  userStatus?: string;
  phone?: string;
  userRoles?: string[];
  status?: JSX.Element;
  created_at?: string;
}

const UsersListTable: FC<IUsersListTableProps> = ({
  isLoading,
  setUserData,
  setIsOpenModal,
  setModalVariant,
  setIsOpenBlockModal,
  setIsOpenActiveModal,
  setSortValue,
  setParamsData,
  keycloakUsersData,
  setActiveUsersCount,
  setNotActiveUsersCount,
  selectedRows,
  setSelectedRows,
}): JSX.Element => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  useEffect(() => {
    let activeCount: number = 0;
    let notActiveCount: number = 0;

    selectedRows?.forEach(({ status }: UserDataType) => {
      if (status?.props?.text === "Активен") {
        activeCount++;
      } else if (status?.props?.text === "Заблокирован") {
        notActiveCount++;
      }
    });

    setActiveUsersCount(activeCount);
    setNotActiveUsersCount(notActiveCount);
    
    if (selectedRows.length === 0) {
      setSelectedRowKeys([]);
    }
  }, [selectedRows]);

  const [handleSorter] = useTableSorter(null, setParamsData, setSortValue);

  const onSelectChange = (
    newSelectedRowKeys: Key[],
    selectedRows: UserDataType[]
  ): void => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const tableHeaderCellHandler = (sortKey: string) => () => ({
    onClick: (e: React.MouseEvent<HTMLElement>): void => handleSorter(e, sortKey), }
  );

  const rowSelection: TableRowSelection<UserDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const renderUsersRoles = (roles: string[]) => {
    return roles.map((role: string, index: number) => (
      <TagCustom key={index} text={roleList[role] as string} color="roles" className="mb-1.5" />
    ));
  };

  const renderUsersStatus = (status: string) => {
    switch (status) {
      case "blocked":
        return <TagCustom text="Заблокирован" color="error" />;
      case "active":
        return <TagCustom text="Активен" color="success" />;
      default:
        return <TagCustom text="Активен" color="success" />;
    }
  };

  const dataSource = keycloakUsersData?.map<UserDataType>(({
    id,
    roles,
    email,
    status,
    full_name,
    created_at,
    attributes,
  }) => ({
    key: id,
    roles: roles?.length ? renderUsersRoles(roles) : "-",
    full_name: full_name,
    email,
    userStatus: status,
    userRoles: roles,
    phone: attributes?.phone?.[0] || "",
    status: renderUsersStatus(status),
    created_at: dayjs(created_at).format("DD.MM.YYYY")
  }));

  const columns: TableColumnsType<UserDataType> = [
    {
      title: <span className="table-title">ФИО</span>,
      dataIndex: "full_name",
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: tableHeaderCellHandler("full_name"),
      width: "26%",
    },
    {
      title: <span className="table-title">Email</span>,
      dataIndex: "email",
      width: "22%",
    },
    {
      title: <span className="table-title">Статус</span>,
      dataIndex: "status",
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: tableHeaderCellHandler("status"),
      width: "10%",
    },
    {
      title: <span className="table-title">Роль</span>,
      dataIndex: "roles",
      width: "30%",
    },
    {
      title: <span className="table-title">Дата регистрации</span>,
      dataIndex: "created_at",
      sorter: true,
      showSorterTooltip: false,
      onHeaderCell: tableHeaderCellHandler("created_at"),
      width: "10%",
    },
    {
      title: "",
      dataIndex: "settings",
      width: "2%",
      render: (_, record: UserDataType) => {
        return (
          <UsersListTableSettings
            record={record}
            setIsOpenModal={setIsOpenModal}
            setModalVariant={setModalVariant}
            setIsOpenBlockModal={setIsOpenBlockModal}
            setIsOpenActiveModal={setIsOpenActiveModal}
            setUserData={setUserData}
          />
        );
      }
    },
  ];

  return (
    <div>
      <Table<UserDataType>
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
      />
    </div>
  );
};

export default UsersListTable;
