import { ChangeEvent, FC, JSX } from "react";
import { validateTriggersArr } from "../../../ClientProfileCardImageViewer";
import { typeOfEmployment } from "../utils/typeOfEmployment";
import { isFormItemRequiredRule } from "../../../../../../utils/isFormItemRequiredRule";
import { handleTariffRateChange } from "../utils/handleTariffRateChange";
import { handleDigitsOnlyChange } from "../utils/handleDigitsOnlyChange";
import { handleLettersOnlyChange } from "../utils/handleLettersOnlyChange";
import { formFieldsProps } from "../utils/formFieldsProps";
import dayjs, { Dayjs } from "dayjs";
import {
  Form,
  Input,
  Select,
  DatePicker,
} from "antd";
import { IContractFormPropsProps } from "../AgreementForm";

const LaborContractForm: FC<IContractFormPropsProps> = ({
  setDateValue,
  agreementForm,
  docApproveStatus,
  contractDocSchema,
  isFormItemDisabled,
  isContractFormDisabled,
  isContractTypeChanging
}): JSX.Element => {
  return (
    <>
      <Form.Item
        rootClassName="w-full"
        name="contract_date"
        label="Дата заключения договора"
        rules={[
          {
            required: isFormItemRequiredRule("contract_date", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <DatePicker
          id="contract_date"
          className="w-full"
          placeholder={formFieldsProps.datePlaceholder}
          style={{
            padding: "6.35px"
          }}
          size={formFieldsProps.largeSize}
          format={formFieldsProps.dateFormat}
          allowClear={!docApproveStatus}
          inputReadOnly={!formFieldsProps.isReadOnly}
          onChange={(date: Dayjs | null): void => setDateValue(date, "contract_date")}
          disabledDate={(current: Dayjs) => dayjs().isBefore(current)}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
      <Form.Item
        rootClassName="w-full"
        name="expiration_date"
        label="Срок действия"
        rules={[
          {
            required: isFormItemRequiredRule("expiration_date", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <DatePicker
          id="expiration_date"
          className="w-full"
          placeholder={formFieldsProps.datePlaceholder}
          style={{
            padding: "6.35px"
          }}
          size={formFieldsProps.largeSize}
          format={formFieldsProps.dateFormat}
          allowClear
          inputReadOnly={!formFieldsProps.isReadOnly}
          onChange={(date: Dayjs | null): void => setDateValue(date, "expiration_date")}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
      <Form.Item
        name="position"
        label="Занимаемая должность"
        rules={[
          {
            required: isFormItemRequiredRule("position", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          id="position"
          size={formFieldsProps.largeSize}
          placeholder="Введите должность"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleLettersOnlyChange(e, "position", agreementForm)}
          readOnly={isContractFormDisabled}
          disabled={isContractTypeChanging}
        />
      </Form.Item>
      <Form.Item
        name="employment_type"
        label="Вид занятости"
        rules={[
          {
            required: isFormItemRequiredRule("employment_type", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Select
          id="employment_type"
          placeholder="Выберите вид занятости"
          options={typeOfEmployment}
          allowClear={!docApproveStatus}
          size={formFieldsProps.largeSize}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
      <Form.Item
        name="rate"
        label="Ставка"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("rate", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          className="w-full"
          id="rate"
          size="large"
          placeholder="Введите ставку (числа от 0 до 1)"
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleTariffRateChange(event, agreementForm)}
          readOnly={isContractFormDisabled}
          disabled={isContractTypeChanging}
        />
      </Form.Item>
      <Form.Item
        name="salary"
        label="Оклад"
        rootClassName="w-full mr-2"
        rules={[
          {
            required: isFormItemRequiredRule("salary", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          id="salary"
          size={formFieldsProps.largeSize}
          addonAfter={<span className="text-gray-400">₽</span>}
          placeholder="Введите оклад"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleDigitsOnlyChange(e, "salary", agreementForm)}
          readOnly={isContractFormDisabled}
          disabled={isContractTypeChanging}
        />
      </Form.Item>
      <Form.Item
        name="allowances"
        label="Северная надбавка"
        rootClassName="w-full"
        rules={[
          {
            required: isFormItemRequiredRule("allowances", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          id="allowances"
          size={formFieldsProps.largeSize}
          addonAfter={<span className="text-gray-400">₽</span>}
          placeholder="Введите северную надбавку"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleDigitsOnlyChange(e, "allowances", agreementForm)}
          readOnly={isContractFormDisabled}
          disabled={isContractTypeChanging}
        />
      </Form.Item>
      <Form.Item
        name="workplace"
        label="Место работы"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("workplace", contractDocSchema),
            message: ""
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Input
          id="workplace"
          size={formFieldsProps.largeSize}
          placeholder="Введите место работы (город)"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleLettersOnlyChange(e, "workPlace", agreementForm)}
          readOnly={isContractFormDisabled}
          disabled={isContractTypeChanging}
        />
      </Form.Item>
    </>
  );
};

export default LaborContractForm;
