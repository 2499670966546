import { clientActions, IClientActionsProps } from "../../ClientCardItem/utils/clientActions";

interface Roles {
  isAdmin: boolean;
  isAssistant: boolean;
  isAccountant: boolean;
  isTechSupport: boolean;
  isContactCenter: boolean;
}

const CREATE_REQUEST = "Создать обращение";

export const getAvailableActions = (roles: Roles, isDraftClient?: boolean): IClientActionsProps[] => {
  if (isDraftClient) {
    return [];
  }

  if (roles.isAdmin || roles.isTechSupport) {
    return clientActions;
  }

  return clientActions.filter(({ value }: IClientActionsProps) => {
    if (roles.isAccountant || roles.isAssistant) return value !== CREATE_REQUEST;
    if (roles.isContactCenter) return value === CREATE_REQUEST;

    return false;
  });
};
