import {
  ChangeEvent,
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import {
  Form,
  Input,
  Modal,
  Select,
  Tooltip
} from "antd";
import css from "./UsersModal.module.css";
import { UserDataType } from "../../UsersList/UsersListTable/UsersListTable";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { useWatch } from "antd/es/form/Form";
import { handlePhoneNumberChange } from "../../../../utils/phoneNumberMask";
import { creatUser, editUser, getKeycloakUsers } from "../../../../api/keycloak.api";
import { usersNotification } from "../../utils/Notifications/Notifications";
import { rolesList } from "./utils/roleList";
import { CreatFormType, userNameType } from "./utils/userTypes";
import {
  KeycloakCreateUserType,
  AllUsersListingType,
  UserListingType,
  FilterTasksType
} from "app/types";
import debounce from "debounce";
import { AxiosResponse } from "axios";

interface ICreatUsersModalProps {
  isOpenModal: boolean;
  modalVariant: string;
  userData: UserDataType[];
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setUpdateListing: Dispatch<SetStateAction<boolean>>;
}

const { useForm } = Form;

const debouncedGetUsersListing: debounce.DebouncedFunction<(
  params: FilterTasksType,
  onSuccess: (value: AxiosResponse<AllUsersListingType>) => void,
  onError: any,
) => void> = debounce((params, onSuccess, onError): void => {
  getKeycloakUsers(params)
    .then(onSuccess)
    .catch(onError);
}, 500);

const UsersModal: FC<ICreatUsersModalProps> = ({
  isOpenModal,
  modalVariant,
  userData,
  setIsOpenModal,
  setUpdateListing
}): JSX.Element => {
  const [form] = useForm();
  const values: CreatFormType = useWatch([], form);
  const {
    surname,
    firstName,
    secondName,
    email,
    phoneNumber,
    role
  } = values || {};
  
  const [usersKeycloakList, setUsersKeycloakList] = useState<UserListingType[]>([]);
  const [hasCheckedEmail, setCheckedMail] = useState<boolean>(false);
  const [isFormHasValues, setFormHasValues] = useState<boolean>(false);
  const [phoneNumberBlur, setPhoneNumberBlur] = useState<boolean>(false);
  const [emailBlur, setEmailBlur] = useState<boolean>(false);
  
  const filteredList: UserListingType[] = usersKeycloakList.filter(
    ({ email }): boolean => email !== userData?.[0]?.email
  );
  const isEditVariant: boolean = modalVariant === "edit";
  const isBookedEmail: boolean = !!filteredList.length;
  const isDisableButtonOk: boolean = isFormHasValues || isBookedEmail || !hasCheckedEmail;
  
  const userName = (fullName: string | undefined): userNameType | null => {
    if (!fullName) {
      return null;
    }

    const nameParts: string[] = fullName.split(" ");
    const surName: string = nameParts[0];
    const firstName: string = nameParts[1];
    const secondName: string = nameParts[2];
    
    return {
      surName: surName,
      firstName: firstName,
      secondName: secondName
    };
  };
  
  useEffect(() => {
    if (isEditVariant) {
      const { email, full_name, phone, userRoles } = userData?.[0] || {};
      const { surName, firstName, secondName } = userName(full_name) || {};
      const correctFormPhoneNumber: string = handlePhoneNumberChange(phone as string);
      
      form.setFieldsValue({
        surname: surName,
        firstName,
        secondName,
        email,
        phoneNumber: correctFormPhoneNumber,
        role: userRoles
      });
    }
  }, [modalVariant, userData]);
  
  useEffect((): void => {
    if (values) {
      const requiredFields: CreatFormType = form.getFieldsValue([
        "surname",
        "firstName",
        "secondName",
        "email",
        "role"
      ] as any);
      
      const hasNoValue: boolean = Object.values(requiredFields)
        .some((value: string | string[]): boolean => typeof value === "string" ? !value : !value?.length);
      
      setFormHasValues(hasNoValue);
    }
  }, [values, isBookedEmail]);
  
  useEffect(() => {
    if (isOpenModal && email) {
      setCheckedMail(false);
      
      const params: FilterTasksType = {
        search: email
      };

      debouncedGetUsersListing(
        params,
        (response: any): void => {
          setUsersKeycloakList(response.data.results as UserListingType[]);
          setCheckedMail(true);
        },
        (err: any): void => {
          console.error("Get users error:", err);
          setCheckedMail(true);
        }
      );

      return (): void => {
        debouncedGetUsersListing.clear();
      };
    }

    return (): void => {
      setUsersKeycloakList([]);
    };
  }, [isOpenModal, email]);
  
  const handleOk = (): void => {
    const paramsData: KeycloakCreateUserType = {
      username: [surname, firstName, secondName].join(" "),
      email,
      firstName,
      lastName: secondName,
      emailVerified: true,
      enabled: true,
      attributes: {
        first_name: firstName,
        last_name: surname,
        second_name: secondName,
        ...(phoneNumber ? { phone: phoneNumber.replace(/[\s-]/g, "") } : {})
      },
      roles: role
    };

    if (isEditVariant) {
      const userUuid: string = userData?.[0]?.key || "";

      editUser(userUuid, paramsData)
        .then((response) => {
          if (response.status === 200) {
            setUpdateListing((prev: boolean) => !prev);
            usersNotification("usersEditSuccess", userData);
          }
        })
        .catch((error) => {
          usersNotification("usersEditFailure");
          console.error("Edit user error:", error);
        })
        ?.finally(() => {
          setIsOpenModal(false);
          form.resetFields();
          setFormHasValues(false);
          setEmailBlur(false);
          setPhoneNumberBlur(false);
        });
    } else {
      const notificationUserData: { key: string, full_name: string }[] = [
        {
          key: "1",
          full_name: [surname, firstName, secondName].join(" ")
        }
      ];

      creatUser(paramsData)
        .then((response) => {
          if (response.status === 200) {
            setUpdateListing((prev: boolean) => !prev);
            usersNotification("usersCreateSuccess", notificationUserData);
          }
        })
        .catch((error) => {
          usersNotification("usersCreateFailure");
          console.error("Edit user error:", error);
        })
        ?.finally(() => {
          setIsOpenModal(false);
          form.resetFields();
          setFormHasValues(false);
          setEmailBlur(false);
          setPhoneNumberBlur(false);
        });
    }
  };
  
  const handleCancel = (): void => {
    setIsOpenModal(false);
    setFormHasValues(false);
    setEmailBlur(false);
    setPhoneNumberBlur(false);
    form.resetFields();
  };
  
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const validEmail: string = value.replace(/[^A-Za-z0-9@._%?&-]/g, "");
    form.setFieldValue("email" as any, validEmail);
  };
  
  const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const formattedNumber: string = handlePhoneNumberChange(value);
    form.setFieldValue("phoneNumber" as any, formattedNumber);
  };
  
  const handleValidateStatus = (values: string): "error" | "" => {
    return form.getFieldValue(values as any)?.length > 0
      ? "" : "error";
  };
  
  const handleValidateEmailStatus = (values: string): "error" | "" => {
    return form.getFieldValue(values as any)?.length > 0 && !isBookedEmail ? "" : "error";
  };

  const handleHelp = (values: string, text: string): null | string => {
    return form.getFieldValue(values as any) ? null : text;
  };
  
  const handleEmailHelp = (values: string, text: string): null | string => {
    return form.getFieldValue(values as any) ? text : null;
  };

  const handleBlur = (variant: string): void => {
    const handlers: Record<string, () => void> = {
      phoneNumber: () => setPhoneNumberBlur(true),
      email: () => setEmailBlur(true),
    };
    
    handlers[variant]();
  };
  
  const modalTitle: JSX.Element = (
    <div className={css.modalTitle}>
      {isEditVariant ? "Редактирование пользователя" : "Создание пользователя"}
    </div>
  );
  
  const contentTooltip = (text: string): JSX.Element => (
    <div className={css.tooltip}>
      {text}
    </div>
  );
  
  const buttonOk: JSX.Element = (
    <ButtonCustom
      key="ok"
      size="large"
      text="Сохранить"
      type="primary"
      onClick={handleOk}
      disabled={isDisableButtonOk}
    />
  );
  
  const modalFooter: JSX.Element = (
    <div key="modalBotton" className="flex justify-end">
      <ButtonCustom
        className="mr-2"
        ghost
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={handleCancel}
      />
      {isFormHasValues ? (
        <Tooltip
          placement="topRight"
          title={contentTooltip("Заполните все обязательные поля помеченные звездочкой (*)")}
          trigger="hover"
          arrow={{ pointAtCenter: true }}
        >
          <div>
            {buttonOk}
          </div>
        </Tooltip>
      ) : (
        buttonOk
      )}
    </div>
  );
  
  return (
    <Modal
      width={572}
      title={modalTitle}
      open={isOpenModal}
      onCancel={handleCancel}
      footer={modalFooter}
      centered
    >
      <Form
        form={form}
        className="m-0"
        layout="vertical"
      >
        <Form.Item
          name="surname"
          className="mb-5"
          label="Фамилия"
          required
        >
          <Input
            id="surname"
            size="large"
            placeholder="Введите фамилию"
          />
        </Form.Item>
        <Form.Item
          name="firstName"
          className="mb-5"
          label="Имя"
          required
        >
          <Input
            id="firstName"
            size="large"
            placeholder="Введите имя"
          />
        </Form.Item>
        <Form.Item
          name="secondName"
          className="mb-5"
          label="Отчество"
          required
        >
          <Input
            id="secondName"
            size="large"
            placeholder="Введите отчество"
          />
        </Form.Item>
        <div className="mb-5 flex justify-between gap-3">
          <Form.Item
            name="email"
            className="mb-0 w-1/2"
            label="Email"
            validateStatus={emailBlur || isBookedEmail ? handleValidateEmailStatus("email") : ""}
            help={isBookedEmail ? handleEmailHelp("email", "Этот email уже используется") : null}
            required
          >
            <Input
              id="email"
              size="large"
              placeholder="Введите email"
              value={email}
              onChange={handleEmailChange}
              onBlur={() => handleBlur("email")}
            />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            className="mb-0 w-1/2"
            label="Телефон"
            validateStatus={phoneNumberBlur ? handleValidateStatus("phoneNumber") : ""}
            help={phoneNumberBlur ? handleHelp("phoneNumber", "Только цифры") : null}
          >
            <Input
              id="phoneNumber"
              size="large"
              placeholder="+7 000 000 00 00"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              onBlur={() => handleBlur("phoneNumber")}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="role"
          className="mb-5"
          label="Роль"
          required
        >
          <Select
            id="role"
            size="large"
            showSearch
            allowClear
            options={rolesList}
            mode="multiple"
            placeholder="Выберите роль"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UsersModal;