import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { FormPropsType } from "./TasksType/TasksListingType/TasksListingType";
import { UserListingType, FilterTasksType, ResponsibleFilterType } from "app/types";
import { filterParamsToArray } from "../../utils/filterParamsToArray";

export const handleDateChange = (
  dates: null | (Dayjs | null)[],
  setStartDate: Dispatch<SetStateAction<string | null>>,
  setEndDate: Dispatch<SetStateAction<string | null>>
): void => {
  if (dates && dates[0] && dates[1]) {
    const start: Dayjs = dayjs(dates[0]);
    const end: Dayjs = dayjs(dates[1]);
    
    setStartDate(start.format("YYYY-MM-DD"));
    setEndDate(end.format("YYYY-MM-DD"));
  } else {
    setStartDate(null);
    setEndDate(null);
  }
};

export const innMaskChange = (value: string): string | undefined => {
  const inputValue: string = value.replace(/\D/g, "");
  const matches: RegExpMatchArray | null = inputValue.match(/(\d{1,3})(\d{0,3})(\d{0,3})(\d{0,3})/);
  
  return matches ? matches?.slice(1).filter(Boolean).join(" ") : "";
};

export const handleInitialValues = (
  userProfileUuid: string,
  tasksParams: FilterTasksType | null,
  usersListing: UserListingType[]
): FormPropsType => {
  const responsibleData = (): string[] => {
    const users: UserListingType[] = usersListing.filter((user: UserListingType) =>
      filterParamsToArray(tasksParams?.responsible_lookup as string)
        .filter((uuid: string): boolean => uuid !== userProfileUuid)
        .includes(user.id)
    );
    const correctFormState: ResponsibleFilterType[] = users.map(({ id, attributes }) => {
      const { last_name = "", first_name = "", second_name = "" } = attributes;
      
      return {
        id: id.toString(),
        name: `${last_name} ${first_name} ${second_name}`.trim(),
      };
    });
    
    return correctFormState.map((user: ResponsibleFilterType) => JSON.stringify(user));
  };
  
  const organizationName: string | undefined | null = tasksParams?.clients ? tasksParams?.clients : null;
  const typeOPF: string[] | null = tasksParams?.["clients_lookup.short_name"]
    ? filterParamsToArray(tasksParams?.["clients_lookup.short_name"] as string)
    : null;
  const taxTask: string[] | null = tasksParams?.["clients_lookup.tax_system"]
    ? filterParamsToArray(tasksParams?.["clients_lookup.tax_system"] as string)
    : null;
  const statusTask: string[] | null = tasksParams?.status_task
    ? filterParamsToArray(tasksParams?.status_task as string)
    : null;
  const typeTask: string[] | null = tasksParams?.task_type_lookup
    ? filterParamsToArray(tasksParams?.task_type_lookup as string)
    : null;
  const inn: string | undefined | null = tasksParams?.inn
    ? innMaskChange(tasksParams?.inn as string)
    : null;
  const responsible: string[] | null = tasksParams?.responsible_lookup
    ? responsibleData()
    : null;
  const dateFrom: Dayjs[] | null = (tasksParams?.created_at_gte && tasksParams?.created_at_lte)
    ? [dayjs(tasksParams?.created_at_gte as string), dayjs(tasksParams?.created_at_lte as string)]
    : null;
  const dateTo: Dayjs[] | null = (tasksParams?.updated_at_gte && tasksParams?.updated_at_lte)
    ? [dayjs(tasksParams?.updated_at_gte as string), dayjs(tasksParams?.updated_at_lte as string)]
    : null;
  const dateContract: Dayjs[] | null = (tasksParams?.initiation_date_gte && tasksParams?.initiation_date_lte)
    ? [dayjs(tasksParams?.initiation_date_gte as string), dayjs(tasksParams?.initiation_date_lte as string)]
    : null;
  const deadline: boolean = !!tasksParams?.is_expired;
  const number: string | undefined | null = tasksParams?.number || null;
  const hasUpdate: string[] | null = tasksParams?.new_notifications ? [tasksParams.new_notifications] : null;
  
  return {
    organizationName,
    typeOPF,
    taxTask,
    statusTask,
    typeTask,
    inn,
    responsible,
    dateFrom,
    dateTo,
    dateContract,
    deadline,
    number,
    hasUpdate
  };
};