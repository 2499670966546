export const getNewDocumentFields = (
  type: string,
  documentFiles: string[] | undefined
) => {
  const baseFields = {
    files: documentFiles,
    position: null,
    payment_type: null,
    contract_date: null,
    contract_type: type,
    decline_reason: null,
    document_status: null,
    employment_type: null,
    expiration_date: null,
    payment_methods: null,
  };

  if (type === "Трудовой договор") {
    return {
      ...baseFields,
      rate: null,
      salary: null,
      workplace: null,
      allowances: null,
    };
  } else if (type === "Договор ГПХ") {
    return {
      ...baseFields,
      contract_amount: null,
    };
  }

  return baseFields;
};
