import React, {
  FC,
  JSX,
  SetStateAction,
  Dispatch,
  useContext,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Divider,
  FormInstance,
  RadioChangeEvent
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { getDocumentSchema, getEmployeeDocumentData } from "../../../../../actions/document.actions";
import { AppStateType } from "../../../../../reducers/mainReducer";
import dayjs, { Dayjs } from "dayjs";
import { DocumentClientProfileType, UpdateDocumentFieldType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../../actions/clientProfile.actions";
import { StepContext, TaskQuestionnaireCardSteps } from "../../../ClientProfileCard";
import { onlyOneDirector } from "../../../../../utils/onlyOneDirector";
import { isDocRequiredValuesFilled } from "../../../../../utils/isDocRequriedValuesFiiled";
import css from "../../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../../utils/roleResolver";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import PassportForm from "./PassportForm";

interface IClientProfileCardImageViewerFormPassportProps {
  passportForm: FormInstance;
  closeForm: () => void;
  cancelCheck: () => void;
  documentUuid: string;
  setCanSaveDoc: Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: Dispatch<SetStateAction<number>>;
  isConfirmModalOpen: boolean;
  setConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
  isEmployee?: boolean;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  currentDocumentData?: UpdateDocumentFieldType | null;
}

const ClientProfileCardImageViewerFormPassport: FC<IClientProfileCardImageViewerFormPassportProps> = ({
  passportForm,
  closeForm,
  cancelCheck,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isConfirmModalOpen,
  setConfirmModalOpen,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  isEmployee,
  currentDocumentData
}): JSX.Element => {
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);
  const { passport_document } = documentClientProfile?.fields || {};

  const {
    passportDocSchema,
    passportCompanyDoc,
    passportDoc
  } = useSelector((state: AppStateType) => state.document);
  const {
    document_status,
    files,
    decline_reason,
    sex,
    first_name,
    second_name,
    last_name,
    series_number,
    passport_number,
    issued_by,
    date_of_birth,
    issued_date,
    citizenship,
    subdivision_code
  } = passportDoc?.fields || passportCompanyDoc?.fields || {};

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const currentStep: number | null = useContext(StepContext);

  const isCeoDetailCurrentStep: boolean = currentStep === TaskQuestionnaireCardSteps.ManagerDetailsStep;

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    const documentStatus: string = document_status ?? "";
    const documentFiles: string[] = files?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, passportDoc, passportCompanyDoc]);

  const isAllValuesValid: boolean = Object.values(currentDocumentData ?? {}).every(value =>
    value !== null && value !== undefined
  );

  useEffect(() => {
    isDocRequiredValuesFilled(passportDocSchema, passportForm, setDocApprovalDisabled);
  }, [passportForm, passportDocSchema, isAllValuesValid, onFormValuesChange]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("passport-schema"));
      dispatch(getEmployeeDocumentData("passport-schema", documentUuid));
    }
  }, [dispatch, documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = decline_reason
        || "Необходимо приложить верный документ (приложен не тот документ)";
      passportForm.setFieldValue("declineReason", docMigrationDeclineReason);
    }

    onFormValuesChange();
  }, [docApproveStatus, passportDoc, passportCompanyDoc, passportForm]);

  useEffect(() => {
    const docFields = {
      docPassportSex: sex,
      docPassportName: first_name,
      docPassportNumber: passport_number,
      docPassportStatus: document_status,
      docPassportIssuedBy: issued_by,
      docPassportLastName: last_name,
      docPassportBirthDate: dayjs(date_of_birth),
      docPassportIssuedDate: dayjs(issued_date),
      docPassportSecondName: second_name,
      docPassportCitizenship: citizenship,
      docPassportDivisionCode: subdivision_code,
    };
    const docPassportSeries: string = series_number as string;
    const docPassportDeclineReason: string = decline_reason as string;
    
    const shouldUpdateDocField: boolean = Object.values(docFields).some(
      (value): boolean | undefined => typeof value === "string" ? !!value : value?.isValid()
    );

    if (shouldUpdateDocField) {
      handlePassportSeriesChange(docPassportSeries);
      handlePassportNumberOnlyChange(docFields?.docPassportNumber as string, "passport_number");
      handlePassportDivisionCodeChange(docFields?.docPassportDivisionCode as string);
      onApproveChange(docFields?.docPassportStatus as string);

      passportForm.setFieldValue("sex", docFields.docPassportSex);
      passportForm.setFieldValue("last_name", docFields.docPassportLastName);
      passportForm.setFieldValue("issued_by", docFields.docPassportIssuedBy);
      passportForm.setFieldValue("first_name", docFields.docPassportName);
      passportForm.setFieldValue("second_name", docFields.docPassportSecondName);
      passportForm.setFieldValue("isApproved", docFields.docPassportStatus);
      passportForm.setFieldValue("citizenship", docFields.docPassportCitizenship);
      passportForm.setFieldValue(
        "date_of_birth",
        docFields.docPassportBirthDate.isValid() ? docFields.docPassportBirthDate : null
      );
      passportForm.setFieldValue(
        "issued_date",
        docFields.docPassportIssuedDate.isValid() ? docFields.docPassportIssuedDate : null
      );
    }

    if (docPassportDeclineReason) {
      passportForm.setFieldValue("declineReason", docPassportDeclineReason);
    }
  }, [passportDoc, passportCompanyDoc]);

  const handlePassportSeriesChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string"
      ? event?.target?.value?.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue?.match(/(\d{0,4})/);
    const formattedSeries: string = matches
      ? matches?.[1]
      : "";

    setSubmitBtnDisabled(!(formattedSeries?.length === 4 || formattedSeries?.length === 0));

    passportForm.setFieldValue("series_number", formattedSeries);
  };

  const handlePassportDivisionCodeChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    let inputValue: string = typeof event !== "string"
      ? event?.target?.value?.replace(/\D/g, "")
      : event;

    if (inputValue?.length > 3) {
      inputValue = inputValue?.substring(0, 3) + "-" + inputValue?.substring(3, 6);
    }

    setSubmitBtnDisabled(!(inputValue?.length === 7 || inputValue?.length === 0));

    passportForm.setFieldValue("subdivision_code", inputValue);
  };

  const handlePassportNumberOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement> | string,
    fieldName: string,
  ): void => {
    const inputValue: string = typeof event !== "string"
      ? event?.target?.value?.replace(/\D/g, "")
      : event;
    const matches: RegExpMatchArray | null = inputValue?.match(/(\d{0,6})/);
    const formattedNumber: string = matches
      ? matches?.[1]
      : "";

    setSubmitBtnDisabled(!(formattedNumber?.length === 6 || formattedNumber?.length === 0));

    passportForm.setFieldValue(fieldName, formattedNumber);
  };

  useEffect(() => {
    if (isCeoDetailCurrentStep && onlyOneDirector(documentClientProfile) && documentClientProfile) {
      const firstName: string | undefined = passport_document?.[0].first_name;
      const secondName: string | undefined = passport_document?.[0].second_name;
      const lastName: string | undefined = passport_document?.[0].last_name;

      passportForm.setFieldValue("first_name", firstName);
      passportForm.setFieldValue("second_name", secondName);
      passportForm.setFieldValue("last_name", lastName);
    }
  }, [passportForm]);

  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isPassportFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  const handleSaveForm = (): void => {
    setCanSaveDoc(true);
    dispatch(getUnAgreedDocumentType(true));
    passportForm.resetFields();
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>{`Паспортные данные ${isEmployee ? "сотрудника" : "руководителя"}`}</h2>
        {!isPassportFormDisabled && (
          <p className="mb-5 gray-color w-full">
            После проверки правильности документа
            <br />
            заполните соответствующие данные
            <br />
            и подтвердите его согласованность.
          </p>
        )}
        <PassportForm
          passportForm={passportForm}
          onFormValuesChange={onFormValuesChange}
          docApproveStatus={docApproveStatus}
          onApproveChange={onApproveChange}
          isDocApprovalDisabled={isDocApprovalDisabled}
          handlePassportSeriesChange={handlePassportSeriesChange}
          handlePassportDivisionCodeChange={handlePassportDivisionCodeChange}
          handlePassportNumberOnlyChange={handlePassportNumberOnlyChange}
          isClientEmployee={isClientEmployee}
          isValidationCompleted={isValidationCompleted}
        />
      </div>
      <Divider className="mt-0 mb-5" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={cancelCheck}
          text={isPassportFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isPassportFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={handleSaveForm}
            text="Сохранить"
            size="large"
            disabled={isDocVerified || isSubmitBtnDisabled}
          />
        )}
      </div>
      <ConfirmModal
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setConfirmModalOpen}
        closeForm={closeForm}
      />
    </div>
  );
};

export default ClientProfileCardImageViewerFormPassport;
