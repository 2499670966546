import axios from "../utils/axios";
import { ParamsType } from "../components/Users/UsersModals/BlockingUsersModal/BlockingUsersModal";
import { KeycloakCreateUserType } from "app/types";

const API: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API;

// Получение списка пользователей из keycloak
export const getKeycloakUsers = async (params: any): Promise<any> => {
  return axios.GET(url, "api/mdm/user/v1/keycloak/users", params);
};

// Блокирование пользователя
export const blockUsers = async (params: ParamsType): Promise<any> => {
  return axios.POST(url, "api/mdm/user/v1/block_users", params);
};

// Разблокирование (активация) пользователя
export const activeUsers = async (params: ParamsType): Promise<any> => {
  return axios.PATCH(url, "api/mdm/user/v1/users/edit", params);
};

// Создание пользователя
export const creatUser = async (params: KeycloakCreateUserType): Promise<any> => {
  return axios.POST(url, "api/mdm/user/v1/create", params);
};

// Редактирование пользователя
export const editUser = async (userUuid: string, params: KeycloakCreateUserType): Promise<any> => {
  return axios.POST(url, `api/mdm/user/v1/edit/${userUuid}?update_all_attributes=True`, params);
};
