import { SizeType } from "antd/es/config-provider/SizeContext";

export interface IActionBtnsDataProps {
  id: string;
  type: "primary" | "text" | "link" | "default" | "dashed" | undefined;
  size: SizeType;
  text: string;
  danger: boolean;
  className: string;
}

export const actionBtnsData: IActionBtnsDataProps[] = [
  {
    id: "activate",
    type: "primary",
    size: "large",
    text: "Активировать",
    danger: false,
    className: "mr-2",
  },
  {
    id: "block",
    type: "primary",
    size: "large",
    text: "Заблокировать",
    danger: true,
    className: "mr-2",
  },
];
