import {
  FC,
  JSX,
  useState,
  useEffect,
} from "react";
import { FilterTasksType, KeycloakUsersType } from "app/types";
import { getKeycloakUsers } from "../../api/keycloak.api";
import UsersHeader from "./UsersHeader/UsersHeader";
import UsersList from "./UsersList/UsersList";
import { UserDataType } from "./UsersList/UsersListTable/UsersListTable";
import BlockingUsersModal from "./UsersModals/BlockingUsersModal/BlockingUsersModal";
import UsersModal from "./UsersModals/UsersModal/UsersModal";
import ActivationUsersModal from "./UsersModals/ActivationUsersModal/ActivationUsersModal";

interface IUsersProps {}

const Users: FC<IUsersProps> = (): JSX.Element => {
  const [sortValue, setSortValue] = useState<string | null>(null);
  const [paramsData, setParamsData] = useState<FilterTasksType | null>({ order_by: sortValue });
  const [keycloakUsersData, setKeycloakUsersData] = useState<KeycloakUsersType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserDataType[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalVariant, setModalVariant] = useState<string>("");
  const [isOpenBlockModal, setIsOpenBlockModal] = useState<boolean>(false);
  const [isOpenActiveModal, setIsOpenActiveModal] = useState<boolean>(false);
  const [hasUpdateListing, setUpdateListing] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<UserDataType[]>([]);

  useEffect(() => {
    setIsLoading(true);

    getKeycloakUsers(paramsData)
      .then((response) => setKeycloakUsersData(response?.data?.results))
      .catch((error) => console.error("Get keycloak user list error:", error))
      ?.finally(() => setIsLoading(false));
  }, [paramsData, hasUpdateListing]);

  return (
    <div className="p-4">
      <UsersHeader
        setIsOpenModal={setIsOpenModal}
        setModalVariant={setModalVariant}
      />
      <UsersList
        isLoading={isLoading}
        paramsData={paramsData}
        setUserData={setUserData}
        setSortValue={setSortValue}
        setParamsData={setParamsData}
        setIsOpenModal={setIsOpenModal}
        setModalVariant={setModalVariant}
        setIsOpenBlockModal={setIsOpenBlockModal}
        setIsOpenActiveModal={setIsOpenActiveModal}
        keycloakUsersData={keycloakUsersData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <UsersModal
        isOpenModal={isOpenModal}
        modalVariant={modalVariant}
        userData={userData}
        setIsOpenModal={setIsOpenModal}
        setUpdateListing={setUpdateListing}
      />
      <BlockingUsersModal
        isOpenModal={isOpenBlockModal}
        setIsOpenBlockModal={setIsOpenBlockModal}
        setUpdateListing={setUpdateListing}
        userData={userData}
        setSelectedRows={setSelectedRows}
      />
      <ActivationUsersModal
        isOpenModal={isOpenActiveModal}
        setIsOpenActiveModal={setIsOpenActiveModal}
        setUpdateListing={setUpdateListing}
        userData={userData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default Users;
