import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
} from "react";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings-icon.svg";
import { UserDataType } from "./UsersListTable";
import { Button, Dropdown } from "antd";

interface IUsersListTableSettingsProps {
  record: UserDataType,
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setModalVariant: Dispatch<SetStateAction<string>>;
  setIsOpenBlockModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenActiveModal: Dispatch<SetStateAction<boolean>>;
  setUserData: Dispatch<SetStateAction<UserDataType[]>>;
}

type ItemsPropsType = {
  key: string,
  label: string | JSX.Element,
  onClick: () => void,
};

const UsersListTableSettings: FC<IUsersListTableSettingsProps> = ({
  record,
  setIsOpenModal,
  setModalVariant,
  setIsOpenBlockModal,
  setIsOpenActiveModal,
  setUserData
}): JSX.Element => {
  const editUser: ItemsPropsType = {
    key: "1",
    label: "Редактировать",
    onClick: () => {
      setUserData([record]);
      setModalVariant("edit");
      setIsOpenModal(true);
    },
  };

  const blockUser: ItemsPropsType = {
    key: "2",
    label: (
      <span className="text-red-500">
        {record?.status?.props?.text === "Заблокирован" ? "Разблокировать" : "Заблокировать"}
      </span>
    ),
    onClick: () => {
      setUserData([record]);
      
      return record?.status?.props?.text !== "Заблокирован"
        ? setIsOpenBlockModal(true)
        : setIsOpenActiveModal(true);
    }
  };

  const items: ItemsPropsType[] = [
    editUser,
    blockUser,
  ];

  return (
    <Dropdown.Button
      menu={{ items }}
      buttonsRender={([_leftButton, _rightButton]) => [
        null,
        <Button
          key={record?.id}
          type="text"
          icon={<SettingsIcon />}
        />
      ]}
    />
  );
};

export default UsersListTableSettings;
