import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  useCallback,
  createContext, SetStateAction
} from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import { UpdateDocumentType, QuestionnaireStepsType, UpdateDocumentFieldType } from "app/types";
import ClientProfileCardOrganisation from "./ClientProfileCardOrganisation/ClientProfileCardOrganisation";
import ClientProfileCardManagerDetails from "./ClientProfileCardManagerDetails/ClientProfileCardManagerDetails";
import ClientProfileCardEmployees from "./ClientProfileCardEmployees/ClientProfileCardEmployees";
import EmployeeCard from "./ClientProfileCardEmployees/EmployeeCard/EmployeeCard";
import ClientProfileCardNotAgreed from "./ClientProfileCardNotAgreed/ClientProfileCardNotAgreed";
import { ReactComponent as FirstStepActive } from "../../assets/icons/step_first_active.svg";
import { ReactComponent as SecondStep } from "../../assets/icons/step_second.svg";
import { ReactComponent as SecondStepActive } from "../../assets/icons/step_second_active.svg";
import { ReactComponent as ThirdStep } from "../../assets/icons/step_third.svg";
import { ReactComponent as ThirdStepActive } from "../../assets/icons/step_third_active.svg";
import { ReactComponent as FinishedStep } from "../../assets/icons/step_finished.svg";
import { ReactComponent as ErrorStep } from "../../assets/icons/status_error.svg";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import { Steps } from "antd";
import css from "./ClientProfileCard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import {
  fetchSchemaClientProfile,
  fetchDocumentClientProfile,
  getDocumentClientProfileFailure
} from "../../actions/clientProfile.actions";
import { AppStateType } from "../../reducers/mainReducer";
import {
  updateEmployeeDocumentData,
  getDocumentFailure,
  updateCompanyDocumentData,
  clearDocumentCompany
} from "../../actions/document.actions";
import LoadingCustom from "../ui-kit/LoadingCustom/LoadingCustom";
import { fetchDocumentTask, getDocumentTaskFailure } from "../../actions/tasks.actions";
import dayjs from "dayjs";
import { documentSchemaLabelsMapper } from "../../utils/documentSchemaLabelsMapper";
import { DocumentSchemaLabelMapType } from "app/types";

interface IClientProfileCardProps {
  setNavBarHidden: Dispatch<React.SetStateAction<boolean>>;
}

export enum TaskQuestionnaireCardSteps {
  OrganisationStep = 1,
  ManagerDetailsStep = 2,
  EmployeesStep = 3,
  NotAgreedStep = 4,
}

export const StepContext: React.Context<number | null> = createContext<number | null>(null);

const ClientProfileCard: FC<IClientProfileCardProps> = ({
  setNavBarHidden,
}): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<number>(TaskQuestionnaireCardSteps.OrganisationStep);
  const [documentType, setDocumentType] = useState<string>("");
  const [documentUuid, setDocumentUuid] = useState<string>("");
  const [employeeCard, setEmployeeCard] = useState<boolean>(false);
  const [isImageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
  const [isIndividualEntrepreneur, setIndividualEntrepreneur] = useState<boolean>(false);
  const [clientProfileSlug, setClientProfileSlug] = useState<string>("");
  const [isCompanyStepVerified, setCompanyStepVerified] = useState<boolean>(false);
  const [isPersonalInfoVerified, setPersonalInfoVerified] = useState<boolean>(false);
  const [isEmployeesVerified, setEmployeesVerified] = useState<boolean>(false);
  const [hasNotAgreedSteps, setHasNotAgreedSteps] = useState<boolean>(false);
  const [canUpdateNotAgreedDocs, setCanUpdateNotNotAgreedDocs] = useState<boolean>(true);
  const [isDocumentFetched, setDocumentFetched] = useState<boolean>(false);

  const params: any = useParams();
  const location: string = useLocation().pathname;

  const showEmployeeCard: boolean = location.includes("employee");
  
  useEffect(() => {
    return () => {
      dispatch(getDocumentClientProfileFailure());
      dispatch(getDocumentTaskFailure());
      dispatch(getDocumentFailure());
      dispatch(clearDocumentCompany());
    };
  }, []);

  useEffect((): void => {
    if (!employeeCard && showEmployeeCard) {
      const currentUrl: string = window.location.href;
      const pattern: RegExp = /employee=[a-f0-9-]+$/;
      if (pattern.test(currentUrl)) {
        const newUrl: string = currentUrl.replace(pattern, "");
        window.history.replaceState({}, "", newUrl);
      }
    }
    
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const {
    schemaClientProfile,
    documentClientProfile,
    isSchemaClientProfileLoading,
    isDocumentClientProfileLoading,
  } = useSelector((state: AppStateType) => state.clientProfile);

  const {
    usnCompanyDoc,
    bankCompanyDoc,
    snilsCompanyDoc,
    decisionCompanyDoc,
    passportCompanyDoc,
    migrationCompanyDoc
  } = useSelector((state: AppStateType) => state.document);

  const {
    documentTask,
    schemaTaskData,
  } = useSelector((state: AppStateType) => state.tasks);

  useEffect(() => {
    const isDocumentVerified = (doc: UpdateDocumentType | null): boolean =>
     doc === null || (doc.fields?.document_status !== null && doc.fields?.document_status !== "");
    
    const isUsnVerified: boolean = isDocumentVerified(usnCompanyDoc);
    const isSnilsVerified: boolean = isDocumentVerified(snilsCompanyDoc);
    const isPatentVerified: boolean = (migrationCompanyDoc?.fields?.document_status !== null
      && migrationCompanyDoc?.fields?.document_status !== "") ?? true;
    const isStatementVerified: boolean = isDocumentVerified(bankCompanyDoc);
    const isDecisionVerified: boolean = isDocumentVerified(decisionCompanyDoc);
    const isPassportVerified: boolean = isDocumentVerified(passportCompanyDoc);
    const hasUnverifiedEmployee: boolean | undefined = documentClientProfile?.fields?.employees_profiles
      ?.some(({ employee_status }): boolean => employee_status === "VALIDATION");

    if (isUsnVerified && isStatementVerified && isPatentVerified) {
      setCompanyStepVerified(true);
    } else {
      setCompanyStepVerified(false);
    }

    if (isSnilsVerified && isPassportVerified && isDecisionVerified) {
      setPersonalInfoVerified(true);
    } else {
      setPersonalInfoVerified(false);
    }

    if (hasUnverifiedEmployee) {
      setEmployeesVerified(false);
    } else {
      setEmployeesVerified(true);
    }
  }, [
    usnCompanyDoc,
    bankCompanyDoc,
    migrationCompanyDoc,
    snilsCompanyDoc,
    decisionCompanyDoc,
    documentClientProfile,
  ]);

  useEffect(() => {
    if (!isCompanyStepVerified || !isPersonalInfoVerified || !isEmployeesVerified) {
      setHasNotAgreedSteps(true);
    } else {
      setHasNotAgreedSteps(false);
    }
  }, [isCompanyStepVerified, isPersonalInfoVerified, isEmployeesVerified]);

  useEffect(() => {
    if (clientProfileSlug === "client-profile-ip") {
      setIndividualEntrepreneur(true);
    }
  }, [clientProfileSlug]);

  useEffect(() => {
    if (documentTask?.fields?.client_profile_ooo) {
      setClientProfileSlug("client-profile-ooo");
    }

    if (documentTask?.fields?.client_profile_ip) {
      setClientProfileSlug("client-profile-ip");
    }
  }, [
    documentTask,
    usnCompanyDoc,
    bankCompanyDoc,
    snilsCompanyDoc,
    passportCompanyDoc,
    decisionCompanyDoc,
  ]);

  const clientProfileCardLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClientProfile);
  const taskLabels: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaTaskData);

  useEffect(() => {
    if (!documentTask) {
      const documentTaskId = location?.split("/")?.[2];
      dispatch(fetchDocumentTask(documentTaskId));
    }
  }, [location, documentTask]);

  useEffect(() => {
    if (!isDocumentFetched && clientProfileSlug?.length) {
      setNavBarHidden(true);
      setDocumentFetched(true);
      dispatch(fetchSchemaClientProfile(clientProfileSlug));
      dispatch(fetchDocumentClientProfile(clientProfileSlug, params.id));
    }
  }, [clientProfileSlug, params, isDocumentFetched]);

  const showImageViewer = (docType: string, uuid: string): void => {
    setDocumentUuid(uuid);
    setDocumentType(docType);
    setImageViewerOpen(true);
  };

  const saveCompanyFormData = (docUuid: string, data: UpdateDocumentFieldType | null): void => {
    dispatch(updateCompanyDocumentData(documentType, docUuid, data));

    setImageViewerOpen(false);
    setCanUpdateNotNotAgreedDocs(true);
  };

  const saveFormData = (docUuid: string, data: UpdateDocumentFieldType | null): void => {
    dispatch(updateEmployeeDocumentData(documentType, docUuid, data));

    setImageViewerOpen(false);
    setCanUpdateNotNotAgreedDocs(true);
  };

  const closeForm = (): void => {
    setImageViewerOpen(false);
    setCanUpdateNotNotAgreedDocs(true);
  };

  const navigate: NavigateFunction = useNavigate();

  const secondStepIcon = (step: number): JSX.Element => {
    switch (step) {
      case TaskQuestionnaireCardSteps.ManagerDetailsStep:
        return <SecondStepActive />;
      case TaskQuestionnaireCardSteps.EmployeesStep:
      case TaskQuestionnaireCardSteps.NotAgreedStep:
        return isPersonalInfoVerified ? <FinishedStep /> : <ErrorStep />;
      default:
        return <SecondStep />;
    }
  };

  const secondStepIndividualEntrepreneurIcon = (step: number): JSX.Element => {
    switch (step) {
      case TaskQuestionnaireCardSteps.OrganisationStep:
        return <SecondStep />;
      case TaskQuestionnaireCardSteps.EmployeesStep:
        return <SecondStepActive />;
      case TaskQuestionnaireCardSteps.NotAgreedStep:
        return isEmployeesVerified ? <FinishedStep /> : <ErrorStep />;
      default:
        return <SecondStep />;
    }
  };

  const thirdStepIcon = (step: number): JSX.Element => {
    switch (step) {
      case TaskQuestionnaireCardSteps.EmployeesStep:
        return <ThirdStepActive />;
      case TaskQuestionnaireCardSteps.NotAgreedStep:
        return isEmployeesVerified ? <FinishedStep /> : <ErrorStep />;
      default:
        return <ThirdStep />;
    }
  };

  const isValidationCompleted: boolean = documentTask?.fields?.status_task === "FIN_TRUE"
    || documentTask?.fields?.status_task === "1C_SYNC"
    || documentTask?.fields?.status_task === "FIN_FALSE"
    || documentTask?.fields?.status_task === "1C_SUCCESS"
    || documentTask?.fields?.status_task === "PAUSE";

  const taskQuestionnaireCard = (step: number): JSX.Element => {
    switch (step) {
      case TaskQuestionnaireCardSteps.OrganisationStep:
        return <ClientProfileCardOrganisation
          closeForm={closeForm}
          saveCompanyFormData={saveCompanyFormData}
          documentType={documentType}
          documentUuid={documentUuid}
          setCurrentStep={setCurrentStep}
          showImageViewer={showImageViewer}
          isImageViewerOpen={isImageViewerOpen}
          schemaClientProfile={schemaClientProfile}
          documentClientProfile={documentClientProfile}
          isIndividualEntrepreneur={isIndividualEntrepreneur}
          isValidationCompleted={isValidationCompleted}
        />;
      case TaskQuestionnaireCardSteps.ManagerDetailsStep:
        return (!isIndividualEntrepreneur && <ClientProfileCardManagerDetails
          closeForm={closeForm}
          saveCompanyFormData={saveCompanyFormData}
          documentUuid={documentUuid}
          documentType={documentType}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          showImageViewer={showImageViewer}
          isImageViewerOpen={isImageViewerOpen}
          isValidationCompleted={isValidationCompleted}
        />) as JSX.Element;
      case TaskQuestionnaireCardSteps.EmployeesStep:
        return (employeeCard
          ? (
            <EmployeeCard
              setEmployeeCard={setEmployeeCard}
              setCanUpdateNotNotAgreedDocs={setCanUpdateNotNotAgreedDocs}
              variant="questionnaire"
            />
          ) : (
            <ClientProfileCardEmployees
              setCurrentStep={setCurrentStep}
              setEmployeeCard={setEmployeeCard}
              isIndividualEntrepreneur={isIndividualEntrepreneur}
              clientProfileSlug={clientProfileSlug}
            />
          )
        );
      case TaskQuestionnaireCardSteps.NotAgreedStep:
        return <ClientProfileCardNotAgreed
          closeForm={closeForm}
          saveFormData={saveFormData}
          saveCompanyFormData={saveCompanyFormData}
          documentUuid={documentUuid}
          documentType={documentType}
          setCurrentStep={setCurrentStep}
          showImageViewer={showImageViewer}
          isImageViewerOpen={isImageViewerOpen}
          isValidationCompleted={isValidationCompleted}
          hasNotAgreedSteps={hasNotAgreedSteps}
          clientProfileSlug={clientProfileSlug}
          canUpdateNotAgreedDocs={canUpdateNotAgreedDocs}
          setCanUpdateNotNotAgreedDocs={setCanUpdateNotNotAgreedDocs}
          setDocumentUuid={setDocumentUuid}
          setDocumentType={setDocumentType}
        />;
      default:
        return <ClientProfileCardOrganisation
          closeForm={closeForm}
          saveCompanyFormData={saveCompanyFormData}
          documentType={documentType}
          documentUuid={documentUuid}
          setCurrentStep={setCurrentStep}
          showImageViewer={showImageViewer}
          isImageViewerOpen={isImageViewerOpen}
          schemaClientProfile={schemaClientProfile}
          documentClientProfile={documentClientProfile}
          isIndividualEntrepreneur={isIndividualEntrepreneur}
          isValidationCompleted={isValidationCompleted}
        />;
    }
  };

  const isFirstStepVerified = (): JSX.Element => {
    return isCompanyStepVerified ? <FinishedStep /> : <ErrorStep />;
  };

  // Рендер блоков для первого шага
  const firstStepTitleStyle: string =
    currentStep >= TaskQuestionnaireCardSteps.OrganisationStep ? `${css.stepsText}` : `${css.textGray}`;

  const renderFirstStepTitle = useCallback((): JSX.Element => {
    return (
      <div
        id="toOrganization"
        className={`cursor-pointer 
          ${currentStep !== TaskQuestionnaireCardSteps.OrganisationStep && !isCompanyStepVerified
            ? "text-red-500"
            : firstStepTitleStyle
          }
        `}
        onClick={() => setCurrentStep(TaskQuestionnaireCardSteps.OrganisationStep)}
      >
        Организация
      </div>
    );
  }, [currentStep, isCompanyStepVerified]);

  const renderFirstStepDescr = useCallback((): JSX.Element => {
    return (
      <>
        {currentStep === TaskQuestionnaireCardSteps.OrganisationStep && (
          <div>
            Общая информация о организации
          </div>
        )}
        {currentStep !== TaskQuestionnaireCardSteps.OrganisationStep && !isCompanyStepVerified && (
          <div className="text-red-500">
            Общая информация о организации
          </div>
        )}
      </>
    );
  }, [currentStep, isCompanyStepVerified]);

  const renderFirstStepStatus = useCallback((): "wait" | "process" | "finish" | "error" | undefined => {
    return currentStep !== TaskQuestionnaireCardSteps.OrganisationStep && !isCompanyStepVerified
      ? "error"
      : undefined;
  }, [currentStep, isCompanyStepVerified]);

  // Рендер блоков для второго шага
  const secondStepTitleStyle: string =
    currentStep >= TaskQuestionnaireCardSteps.ManagerDetailsStep ? `${css.stepsText}` : `${css.textGray}`;

  const renderSecondStepTitle = useCallback((): JSX.Element => {
    return (
      <div
        id="toPersonalData"
        className={`cursor-pointer 
          ${currentStep !== TaskQuestionnaireCardSteps.OrganisationStep
            && currentStep !== TaskQuestionnaireCardSteps.ManagerDetailsStep
            && !isPersonalInfoVerified
              ? "text-red-500"
              : secondStepTitleStyle
          }
        `}
        onClick={() => setCurrentStep(TaskQuestionnaireCardSteps.ManagerDetailsStep)}
      >
        Персональные данные
      </div>
    );
  }, [currentStep, isPersonalInfoVerified]);

  const renderSecondStepDescr = useCallback((): JSX.Element => {
    return (
      <>
        {currentStep === TaskQuestionnaireCardSteps.ManagerDetailsStep && (
          <div>
            Паспортные данные и контактная информация
          </div>
        )}
        {currentStep !== TaskQuestionnaireCardSteps.OrganisationStep
          && currentStep !== TaskQuestionnaireCardSteps.ManagerDetailsStep
          && !isPersonalInfoVerified && (
            <div className="text-red-500">
              Паспортные данные и контактная информация
            </div>
          )
        }
      </>
    );
  }, [currentStep, isPersonalInfoVerified]);

  const renderSecondStepStatus = useCallback((): "wait" | "process" | "finish" | "error" | undefined => {
    return currentStep !== TaskQuestionnaireCardSteps.OrganisationStep
            && currentStep !== TaskQuestionnaireCardSteps.ManagerDetailsStep
            && !isPersonalInfoVerified
              ? "error"
              : undefined;
  }, [currentStep, isPersonalInfoVerified]);

  // Рендер блоков для третьего шага
  const thirdStepTitleStyle: string =
    currentStep >= TaskQuestionnaireCardSteps.EmployeesStep ? `${css.stepsText}` : `${css.textGray}`;

  const renderThirdStepTitle = useCallback((): JSX.Element => {
    return (
      <div
        id="toEmployees"
        className={`cursor-pointer 
          ${currentStep === TaskQuestionnaireCardSteps.NotAgreedStep
            && !isEmployeesVerified
              ? "text-red-500"
              : thirdStepTitleStyle
        }
        `}
        onClick={() => setCurrentStep(TaskQuestionnaireCardSteps.EmployeesStep)}
      >
        Сотрудники
      </div>
    );
  }, [currentStep, isEmployeesVerified]);

  const renderThirdStepDescr = useCallback((): JSX.Element => {
    return (
      <>
        {currentStep === TaskQuestionnaireCardSteps.EmployeesStep && (
          <div>
            Проверка данных сотрудников
          </div>
        )}
        {currentStep === TaskQuestionnaireCardSteps.NotAgreedStep
          && !isEmployeesVerified && (
            <div className="text-red-500">
              Проверка данных сотрудников
            </div>
          )
        }
      </>
    );
  }, [currentStep, isEmployeesVerified]);

  const renderThirdStepStatus = useCallback((): "wait" | "process" | "finish" | "error" | undefined => {
    return currentStep === TaskQuestionnaireCardSteps.NotAgreedStep
      && !isEmployeesVerified
        ? "error"
        : undefined;
  }, [currentStep, isEmployeesVerified]);

  const questionnaireSteps: QuestionnaireStepsType[] = [
    {
      icon: currentStep === TaskQuestionnaireCardSteps.OrganisationStep ? <FirstStepActive /> : isFirstStepVerified(),
      status: renderFirstStepStatus(),
      title: renderFirstStepTitle(),
      description: renderFirstStepDescr(),
    },
    {
      icon: secondStepIcon(currentStep),
      status: renderSecondStepStatus(),
      title: renderSecondStepTitle(),
      description: renderSecondStepDescr(),
    },
    {
      icon: thirdStepIcon(currentStep),
      status: renderThirdStepStatus(),
      title: renderThirdStepTitle(),
      description: renderThirdStepDescr(),
    },
  ];

  const questionnaireIndividualEntrepreneurSteps: QuestionnaireStepsType[] = [
    {
      icon: currentStep === TaskQuestionnaireCardSteps.OrganisationStep ? <FirstStepActive /> : isFirstStepVerified(),
      status: renderFirstStepStatus(),
      title: renderFirstStepTitle(),
      description: renderFirstStepDescr(),
    },
    {
      icon: secondStepIndividualEntrepreneurIcon(currentStep),
      status: renderThirdStepStatus(),
      title: renderThirdStepTitle(),
      description: renderThirdStepDescr(),
    },
  ];

  const navigateToTaskCard = (): void => {
    const taskId: string = location
    ? location?.split("/")[2]
    : "";

    navigate(`/task/${taskId}`);
    setNavBarHidden(false);
  };

  const documentCreatedAt: string = dayjs(documentClientProfile?.created_at)?.format("DD-MM-YYYY") ?? "";
  const taskReceiptDate: string = dayjs(documentTask?.fields?.deadline)?.format("DD-MM-YYYY") ?? "";
  const taskCreatedDate: string = dayjs(documentTask?.created_at)?.format("DD-MM-YYYY") ?? "";
  
  const renderProfileData = (label: string | undefined, text: string | undefined):JSX.Element => (
    <li className="flex flex-col mb-3">
      <span className="inline-block mb-1 gray-color field-label">
        {label}
      </span>
      <span className="text-sm">
        {text}
      </span>
    </li>
  );

  return (!isDocumentClientProfileLoading && !isSchemaClientProfileLoading ?
    (<StepContext.Provider value={ currentStep }>
        <div className="w-full z-20">
          <div className={`flex justify-between bg-white w-full ${css.headerContainer}`}>
            <h1>
              Анкета №{documentClientProfile?.fields?.number} от {documentCreatedAt}
            </h1>
            <ButtonCustom
              id="buttonExit"
              size="large"
              text="Выйти из анкеты"
              type="default"
              className={css.buttonExit}
              onClick={navigateToTaskCard}
            />
          </div>
          <div className={`${css.container} flex`}>
            <div className={`${css.questionnaireInfo} flex flex-col`}>
              <div className={`${css.block} bg-white p-5`}>
                <Steps
                  direction="vertical"
                  current={currentStep}
                  items={isIndividualEntrepreneur ? questionnaireIndividualEntrepreneurSteps : questionnaireSteps}
                />
              </div>
              <div className={`${css.block} bg-white mt-3 p-5`}>
                <ul>
                  {renderProfileData(
                    clientProfileCardLabels?.["opf"]?.label,
                    documentClientProfile?.fields?.opf
                  )}
                  {renderProfileData(
                    clientProfileCardLabels?.["name"]?.label,
                    documentClientProfile?.fields?.name
                  )}
                  {renderProfileData(
                    clientProfileCardLabels?.["tax_system"]?.label,
                    documentClientProfile?.fields?.tax_system
                  )}
                  {renderProfileData(
                    taskLabels?.["tariff"]?.label,
                    documentTask?.fields?.tariff
                  )}
                  {renderProfileData(
                    "Дата поступления задачи",
                    taskCreatedDate
                  )}
                  {renderProfileData(
                    clientProfileCardLabels?.["service_expired"]?.label,
                    taskReceiptDate
                  )}
                </ul>
              </div>
            </div>
            <div className="w-full pl-3 min-h-full">
              {taskQuestionnaireCard(currentStep)}
            </div>
          </div>
        </div>
      </ StepContext.Provider>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom />
      </div>
    ));
};

export default ClientProfileCard;
