import { ITypesOfProfessionProps } from "../AgreementForm";

export const paymentMethods: ITypesOfProfessionProps[] = [
  {
    value: "Ежемесячно",
    label: "Ежемесячно"
  },
  {
    value: "По окончании договора",
    label: "По окончании договора"
  },
];
