import {
  Dispatch,
  FC,
  JSX,
  SetStateAction
} from "react";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";

interface IUsersHeaderProps {
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setModalVariant: Dispatch<SetStateAction<string>>;
}

const UsersHeader: FC<IUsersHeaderProps> = ({
  setIsOpenModal,
  setModalVariant
}): JSX.Element => {
  
  const handleOpenCreatModal = (): void => {
    setModalVariant("create");
    setIsOpenModal(true);
  };

  return (
    <div className="flex items-center justify-between mb-4">
      <h1 className="font-bold text-xl">
        Пользователи
      </h1>
      <ButtonCustom
        type="primary"
        size="large"
        text="Добавить пользователя"
        onClick={handleOpenCreatModal}
      />
    </div>
  );
};

export default UsersHeader;
