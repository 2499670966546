import React, {
  FC,
  JSX,
  useRef,
  Dispatch,
  RefObject,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { createNewDynamicDocument } from "../../../../../actions/document.actions";
import {
  SchemaType,
  UpdateDocumentType,
  DocumentEmployeeProfileType,
} from "app/types";
import { validateTriggersArr } from "../../ClientProfileCardImageViewer";
import { isFormItemRequiredRule } from "../../../../../utils/isFormItemRequiredRule";
import { translateContractType } from "../../../../../utils/translateContractType";
import { getNewDocumentFields } from "./utils/getNewDocumentFields";
import { typeOfSalaryPayment } from "./utils/typeOfSalaryPayment";
import { typesOfContracts } from "./utils/typesOfContracts";
import { fieldsToReset } from "./utils/fieldsToReset";
import { formFieldsProps } from "./utils/formFieldsProps";
import LaborContractForm from "./AgreementFormVariant/LaborContractForm";
import CivilContractForm from "./AgreementFormVariant/CivilContractForm";
import {
  Form,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { Dayjs } from "dayjs";
import css from "../../ClientProfileCardImageViewer.module.css";

export interface ITypesOfProfessionProps {
  value: string;
  label: string;
}

interface IAgreementFormProps {
  contractDoc: UpdateDocumentType | null;
  contractType: string;
  agreementForm: FormInstance;
  isContactCenter: boolean;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  setContractType: Dispatch<SetStateAction<string>>;
  docApproveStatus: RadioChangeEvent | string;
  isClientEmployee: boolean | undefined;
  contractDocSchema: SchemaType | null;
  onFormValuesChange: () => void;
  isDocApprovalDisabled: boolean;
  isValidationCompleted: boolean | undefined;
  isContractFormDisabled: boolean | undefined;
  isContractTypeChanging: boolean;
  documentEmployeeProfile: DocumentEmployeeProfileType | null;
  setContractTypeChanging: Dispatch<SetStateAction<boolean>>;
}

export interface IContractFormPropsProps {
  setDateValue: (date: Dayjs | null, dateVariant: string) => void;
  agreementForm: FormInstance;
  docApproveStatus: RadioChangeEvent | string;
  contractDocSchema: SchemaType | null;
  isFormItemDisabled: boolean;
  isContractFormDisabled: boolean | undefined;
  isContractTypeChanging: boolean;
}

const AgreementForm: FC<IAgreementFormProps> = ({
  contractDoc,
  contractType,
  agreementForm,
  isContactCenter,
  onApproveChange,
  setContractType,
  docApproveStatus,
  isClientEmployee,
  contractDocSchema,
  onFormValuesChange,
  isDocApprovalDisabled,
  isValidationCompleted,
  isContractFormDisabled,
  isContractTypeChanging,
  documentEmployeeProfile,
  setContractTypeChanging,
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const refInput: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const declineSelectWidth: number = refInput?.current?.clientWidth ?? 0;

  const agreementExpirationDateStatus: "" | "error" = agreementForm.getFieldValue("expiration_date") ? "" : "error";

  const isFormItemDisabled: boolean = isContractFormDisabled || isContractTypeChanging;
  const isContractApproveBtnDisabled: boolean = isContractTypeChanging
    || isContactCenter
    || isDocApprovalDisabled
    || (contractType === "Договор ГПХ" && !!agreementExpirationDateStatus);

  const setDateValue = (date: Dayjs | null, dateVariant: string): void => {
    agreementForm.setFieldValue(dateVariant, date === null ? null : date);
  };

  const contractFormProps: IContractFormPropsProps = {
    setDateValue,
    agreementForm,
    docApproveStatus,
    contractDocSchema,
    isFormItemDisabled,
    isContractFormDisabled,
    isContractTypeChanging,
  };

  const renderContractFieldsVariant = (contractType: string): JSX.Element => {
    switch (contractType) {
      case("Трудовой договор"):
        return (
          <LaborContractForm {...contractFormProps} />
        );
      case("Договор ГПХ"):
        return (
          <CivilContractForm {...contractFormProps} />
        );
      default:
        return <></>;
    }
  };

  const onContractTypeChange = (newContractType: string): void => {
    setContractType(newContractType);
    setContractTypeChanging(true);

    agreementForm.resetFields(fieldsToReset);

    if (!documentEmployeeProfile?.fields?.uuid) return;

    const newDocumentFilesField: string[] | undefined =
      contractDoc?.fields?.files.map(({ uuid }) => uuid);

    const schema: string = newContractType === "Трудовой договор"
      ? "contract-labor-schema"
      : "contract-civil-schema";

    const newDocumentsFields = getNewDocumentFields(newContractType, newDocumentFilesField);

    dispatch(createNewDynamicDocument(schema, newDocumentsFields));
  };

  return (
    <Form
      form={agreementForm}
      name="agreement-form"
      onChange={onFormValuesChange}
      onClick={onFormValuesChange}
      validateTrigger={validateTriggersArr}
      layout="vertical"
    >
      <Form.Item
        name="contract_type"
        label="Тип договора"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("contract_type", contractDocSchema),
            message: "",
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Select
          id="contract_type"
          placeholder="Выберите тип договора"
          options={typesOfContracts}
          size={formFieldsProps.largeSize}
          onChange={onContractTypeChange}
          value={translateContractType(contractType)}
          loading={isContractTypeChanging}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
      {renderContractFieldsVariant(contractType)}
      <Form.Item
        name="payment_type"
        label="Вид выплаты зарплаты"
        className="mb-5"
        rules={[
          {
            required: isFormItemRequiredRule("payment_type", contractDocSchema),
            message: "",
          }
        ]}
        validateTrigger={validateTriggersArr}
      >
        <Select
          id="payment_type"
          placeholder="Выберите вид выплаты зарплаты"
          options={typeOfSalaryPayment}
          allowClear
          size={formFieldsProps.largeSize}
          disabled={isFormItemDisabled}
        />
      </Form.Item>
      {!isClientEmployee && !isValidationCompleted && (
        <>
          <Divider className="mt-0 mb-5" />
          <div ref={refInput} className={`mb-2 ${css.modalText}`}>Согласование</div>
          <p className="mb-5 gray-color w-full">
            Подтвердите согласование документа. При
            <br />
            наличии ошибок или проблем с файлом
            <br />
            отклоните согласование, указав причину.
          </p>
          <div className="flex justify-between">
            <Tooltip
              title={isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""}
            >
              <Form.Item name="isApproved">
                <Radio.Group
                  onChange={onApproveChange}
                  value={docApproveStatus}
                  disabled={isContractApproveBtnDisabled}
                  className="flex flex-col gap-2"
                >
                  <Radio value="APPROVED">Согласовать</Radio>
                  <Radio value="DECLINED">Отклонить</Radio>
                </Radio.Group>
              </Form.Item>
            </Tooltip>
          </div>
          <div>
            {docApproveStatus === "DECLINED" && (
              <Form.Item
                name="declineReason"
                label="Причина"
                className="w-fit"
                rules={[
                  {
                    required: true,
                    message: ""
                  }
                ]}
                initialValue="Необходимо приложить верный документ (приложен не тот документ)"
              >
                <Select
                  placeholder="Причина"
                  size={formFieldsProps.largeSize}
                  disabled={isContractTypeChanging || isContactCenter}
                  style={{ width: `${declineSelectWidth}px` }}
                  options={[
                    {
                      value: "Необходимо приложить верный документ (приложен не тот документ)",
                      label: "Необходимо приложить верный документ (приложен не тот документ)"
                    },
                    {
                      value: "Плохое качество документа, нечитаемый скан",
                      label: "Плохое качество документа, нечитаемый скан"
                    }
                  ]}
                />
              </Form.Item>
            )}
          </div>
        </>
      )}
    </Form>
  );
};

export default AgreementForm;
