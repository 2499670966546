export const fieldsToReset: string[] = [
  "position",
  "employment",
  "rate",
  "allowances",
  "salary",
  "workplace",
  "payment_methods",
  "payment_type",
  "contract_date",
  "expiration_date",
];
