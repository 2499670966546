import axios from "../utils/axios";
import {
  CreateReportDataType,
  DownloadReportDataType,
  ReportsListingFilterType,
} from "app/types";

const API_REPORTS: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_REPORTS;

//ручка получения листинга отчетов
export const getReports = async (params: null | ReportsListingFilterType = null): Promise<any> => {
  return axios.GET(url, "api/mdm/reports/v1/report_records", params);
};

//ручка создания отчета
export const createReport = async (newReportData: CreateReportDataType): Promise<any> => {
  return axios.POST(url, "api/or/internal/v1/task-report/create", newReportData);
};

//ручка скачивания отчета
export const downloadReport = async (
  schema_slug: string,
  downloadReportData: DownloadReportDataType
): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/${schema_slug}/dynamic_schema`, downloadReportData);
};
