import React, {
  FC,
  JSX,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import css from "./ClientCard.module.css";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { Tabs, TabsProps, Tooltip } from "antd";
import AboutClient from "./ClientCardItem/AboutClient/AboutClient";
import Tasks from "./ClientCardItem/Tasks/Tasks";
import Documents from "./ClientCardItem/Documents/Documents";
import EmployeesListing from "./ClientCardItem/Employees/EmployeesListing/EmployeesListing";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store/store";
import {
  fetchDocumentClient,
  fetchSchemaClient,
  fetchSchemaClientLabourRegulation,
  getClientLabourRegulationData,
  getClientTariffInfo,
  getDocumentClientFailure,
  setClientCardTagKey,
} from "../../../actions/client.actions";
import { AppStateType } from "../../../reducers/mainReducer";
import ClientCardTechInfo from "./ClientCardItem/ClientCardTechInfo/ClientCardTechInfo";
import * as jose from "jose";
import { getUserTokenDecryptionData } from "../../../actions/account.actions";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow_left_icon.svg";
import Accounts from "./ClientCardItem/Accounts/Accounts";
import Comments from "./ClientCardItem/Comments/Comments";
import { UpdateDocumentType } from "app/types";
import { updateDocumentFailure } from "../../../actions/document.actions";
import { roleResolver } from "utils/roleResolver";
import ClientCardActionBtn from "./ClientCardActionBtn/ClientCardActionBtn";
import ClientCardActionDrawer from "./ClientCardActionDrawer/ClientCardActionDrawer";

interface IClientCardProps {
}

enum StyleSize {
  numColumns = 2,
  drawerPaddings = 48,
}

const ClientCard: FC<IClientCardProps> = (): JSX.Element => {
  const [isEmployeeCardOpen, setIsEmployeeCardOpen] = useState<boolean>(false);
  const [clientLabour, setClientLabour] = useState<boolean>(true);
  const [isClientLoaded, setClientLoaded] = useState<boolean>(false);
  const [drawerClientAction, setDrawerClientAction] = useState<string>("");

  const isDrawerActionValid: boolean = !!drawerClientAction && drawerClientAction !== "Списание платной консультации";
  const taskCardBlockContainer: string = isDrawerActionValid ? css.containerCollapsed : css.container;
  const formWrapper: string | false = isDrawerActionValid ? "flex" : "";

  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const ref: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(null);

  const paramsData: any = useParams();
  const path: string = useLocation().pathname;
  const params = useMemo(() => paramsData, [path]);

  const {
    documentClient,
    schemaClient,
    clientTagKey
  } = useSelector((state: AppStateType) => state.client);
  
  const {
    labour_remuneration_regulations,
    client_tariff,
    short_name,
    name
  } = documentClient?.fields || {};

  const updatedDoc: UpdateDocumentType | null = useSelector((state: AppStateType) => state.document.updatedDoc);
  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const isDraftClient: boolean = documentClient?.fields?.status_client === "DRAFT";

  useEffect(() => {
    return () => {
      dispatch(getDocumentClientFailure());
    };
  }, []);

  useEffect((): void => {
    dispatch(getUserTokenDecryptionData());
  }, [dispatch]);

  const techSupportRole: boolean = roleResolver(decodedToken).isTechSupport;
  const hasName: boolean = !!(short_name || name);

  useEffect(() => {
    if (params) {
      dispatch(fetchSchemaClient());
      dispatch(fetchSchemaClientLabourRegulation());
      dispatch(fetchDocumentClient(params?.id))
        .finally(() => setClientLoaded(true));
    }
  }, [params]);

  useEffect(() => {
    if (updatedDoc) {
      dispatch(fetchDocumentClient(params?.id));
      dispatch(updateDocumentFailure());
    }
  }, [updatedDoc]);

  useEffect(() => {
    if (labour_remuneration_regulations && clientLabour) {
      dispatch(getClientLabourRegulationData(labour_remuneration_regulations?.[0].uuid));
      setClientLabour(false);
    }
  }, [labour_remuneration_regulations]);

  useEffect(() => {
    if (client_tariff) {
      dispatch(getClientTariffInfo(client_tariff?.[0]?.uuid));
    }
  }, [client_tariff]);

  const returnToClientsOrTaskCard = (): void => {
    const hasTaskUuid: boolean = path?.split("/")?.includes("task");

    if (hasTaskUuid) {
      const taskUuid: string = path?.split("/")?.[2];
      navigate(`/task/${taskUuid}/`);
    } else {
      navigate("/clients");
    }
  };

  //данные верхнего меню страницы
  const itemsTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Общая информация",
      destroyInactiveTabPane: true,
      children: (
        <AboutClient
          clientInfo={documentClient}
          isClientLoaded={isClientLoaded}
          showAction={!!drawerClientAction}
        />
      ),
    },
    {
      key: "2",
      label: "Задачи",
      destroyInactiveTabPane: true,
      children: (
        <Tasks
          clientInfo={documentClient}
        />
      ),
    },
    {
      key: "3",
      label: "Документы",
      destroyInactiveTabPane: true,
      children: (
        <Documents
          clientInfo={documentClient}
          schemaClient={schemaClient}
        />
      ),
    },
    {
      key: "4",
      label: "Сотрудники",
      destroyInactiveTabPane: true,
      children: (
        <EmployeesListing />
      ),
    },
    {
      key: "5",
      label: "Счета",
      destroyInactiveTabPane: true,
      children: (
        <Accounts/>
      ),
    },
    {
      key: "6",
      label: "Комментарии",
      destroyInactiveTabPane: true,
      children: (
        <Comments />
      ),
    },
    {
      key: "7",
      label: techSupportRole ? "Доп. Информация" : null,
      destroyInactiveTabPane: true,
      children: (
        techSupportRole ? <ClientCardTechInfo /> : null
      ),
    },
  ];

  const [activeKey, setActiveKey] = useState<string>(clientTagKey);

  useEffect(() => {
    setActiveKey(clientTagKey);
  }, [clientTagKey]);

  const handleTabChange = (key: string): void => {
    setActiveKey(() => {
      dispatch(setClientCardTagKey(key));
      return key;
    });
  };

  const truncateTaskTopic = (clientName: string | null | undefined): string | null | undefined => {
    const maxLength: number = drawerClientAction ? 30 : 70;

    if (clientName && clientName?.length > maxLength) {
      return clientName?.substring(0, maxLength) + "...";
    }

    return clientName;
  };
  
  const correctSelectWidth: number =
    ((ref.current?.clientWidth ?? 0) / StyleSize.numColumns) - StyleSize.drawerPaddings;

  const renderActionDrawer = (): false | JSX.Element => !!drawerClientAction && (
    <ClientCardActionDrawer
      clientUuid={params?.id}
      drawerClientAction={drawerClientAction}
      setDrawerClientAction={setDrawerClientAction}
      correctSelectWidth={correctSelectWidth}
    />
  );

  return (
    <div ref={ref} className={formWrapper}>
      <div className={`${taskCardBlockContainer} ${css.cardsHeader}`}>
        <div>
          <div className="flex">
            <ButtonCustom
              className={css.buttonReturn}
              type="link"
              text=""
              onClick={returnToClientsOrTaskCard}
            >
              <ArrowLeft/>
            </ButtonCustom>
            <h1 className={css.clientCardHeader}>
              {hasName && truncateTaskTopic(short_name ?? name)}
            </h1>
          </div>
          {!isDraftClient && (
            <ClientCardActionBtn
              isDraftClient={isDraftClient}
              setDrawerClientAction={setDrawerClientAction}
            />
          )}
        </div>
        <Tabs
          className={css.clientCardTabs}
          defaultActiveKey={activeKey}
          items={itemsTabs}
          activeKey={activeKey}
          tabBarStyle={isEmployeeCardOpen ? {display: "none"} : {}}
          onChange={handleTabChange}
        />
      </div>
      {renderActionDrawer()}
    </div>
  );
};

export default ClientCard;
