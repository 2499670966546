import { JSX } from "react";
import { ReactComponent as SuccessIcon} from "../../../../assets/icons/check_circle_icon_green.svg";
import { ReactComponent as WarningIcon} from "../../../../assets/icons/warning_circle_icon.svg";
import css from "./Notifications.module.css";
import { notification } from "antd";
import { UserDataType } from "../../UsersList/UsersListTable/UsersListTable";

const notificationsUsersSuccessContent = (
  variant: string,
  usersData: UserDataType[] | undefined
): JSX.Element => {
  const notificationVariant: Record<string, string> = {
    active: "Пользователи активированы",
    blocked: "Пользователи заблокированы",
    edit: "Пользователь отредактирован",
    create: "Пользователь создан",
  };
  
  return (
    <div className="flex">
      <SuccessIcon className="w-[22px] h-6 mr-3" />
      <div>
        <div className={css.headerContent}>{notificationVariant[variant]}</div>
        <div className={css.bodyContent}>
          {usersData?.map(({ key, full_name }) =>
            <div key={key}>{full_name}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const notificationsUsersFailureContent = (variant: string): JSX.Element => {
  const notificationVariant: Record<string, string> = {
    active: "При активации выбранных пользователей произошла ошибка. Пожалуйста, повторите позже",
    blocked: "При блокировке выбранных пользователей произошла ошибка. Пожалуйста, повторите позже",
    edit: "При редактировании пользователя произошла ошибка. Пожалуйста, повторите позже.",
    create: "При создании пользователя произошла ошибка. Пожалуйста, повторите позже.",
  };
  
  return (
    <div className="flex">
      <WarningIcon className="w-[50px] h-6 mr-3"/>
      <div>
        <div className={css.headerContent}>
          Произошла ошибка
        </div>
        <div className={css.bodyContent}>
          {notificationVariant[variant]}
        </div>
      </div>
    </div>
  );
};

const contentVariant = (value: string, usersData?: UserDataType[]): JSX.Element | null => {
  switch (value) {
    case "usersBlockSuccess":
      return notificationsUsersSuccessContent("blocked", usersData);
    case "usersBlockFailure":
      return notificationsUsersFailureContent("blocked");
    case "usersActiveSuccess":
      return notificationsUsersSuccessContent("active", usersData);
    case "usersActiveFailure":
      return notificationsUsersFailureContent("active");
    case "usersEditSuccess":
      return notificationsUsersSuccessContent("edit", usersData);
    case "usersEditFailure":
      return notificationsUsersFailureContent("edit");
    case "usersCreateSuccess":
      return notificationsUsersSuccessContent("create", usersData);
    case "usersCreateFailure":
      return notificationsUsersFailureContent("create");
    default:
      return null;
  }
};

export const usersNotification = (value: string, usersData?: UserDataType[]): void => {
  notification.open({
    key: value,
    message: null,
    duration: 5,
    description: contentVariant(value, usersData),
    style: { backgroundColor: "#FFF", width: "400px", borderRadius: "8px" },
  });
};