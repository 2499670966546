import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { AppStateType } from "../../../../reducers/mainReducer";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { DocumentClientProfileType, UpdateDocumentFilesType, DocumentClientType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";
import ConfirmModal from "./ConfirmModal/ConfirmModal";

interface IClientProfileCardImageViewerFormUsnProps {
  usnForm: FormInstance;
  closeForm: () => void;
  cancelCheck: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  isConfirmModalOpen: boolean;
  setConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ClientProfileCardImageViewerFormUsn: FC<IClientProfileCardImageViewerFormUsnProps> = ({
  usnForm,
  closeForm,
  cancelCheck,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  isConfirmModalOpen,
  setConfirmModalOpen,
}) => {
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null = useSelector(
    (state: AppStateType) => state.clientProfile.documentClientProfile
  );

  const documentClient: DocumentClientType | null = useSelector((state: AppStateType) => state.client.documentClient);

  const {
    usnDoc,
    usnDocSchema
  } = useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(usnDocSchema, usnForm, setDocApprovalDisabled);
  }, [usnForm, usnDocSchema, onFormValuesChange]);

  useEffect(() => {
    const documentStatus: string = usnDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = usnDoc?.fields?.files?.map((file: UpdateDocumentFilesType) => file?.uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile, usnDoc]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("usn-schema"));
      dispatch(getEmployeeDocumentData("usn-schema", documentUuid));
    }
  }, [dispatch, documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = usnDoc?.fields?.decline_reason ?? "";

      usnForm.setFieldValue(
        "declineReason" as any,
        docMigrationDeclineReason || "Необходимо приложить верный документ (приложен не тот документ)"
      );
    }

    onFormValuesChange();
  }, [docApproveStatus, usnDoc, usnForm]);

  useEffect(() => {
    const docUsnStatus: string = usnDoc?.fields?.document_status ?? "";
    const docUsnDeclineReason: string = usnDoc?.fields?.decline_reason ?? "";

    if (docUsnStatus) {
      usnForm.setFieldValue("isApproved", docUsnStatus);
    }

    if (docUsnDeclineReason) {
      usnForm.setFieldValue("declineReason", docUsnDeclineReason);
    }
  }, [usnDoc, usnForm]);

  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isUsnFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  const handleSave = (): void => {
    setCanSaveDoc(true);
    dispatch(getUnAgreedDocumentType(true));
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>Подтверждение о переходе на УСН</h2>
        {!isClientEmployee && !isValidationCompleted && (
          <p className="mb-4 gray-color w-full">
            После проверки правильности документа
            <br />
            подтвердите его согласованность.
          </p>
        )}
        <Divider className="mt-0 mb-4" />
        <h3 className={`${css.modalText} mb-4`}>Данные о клиенте и СНО</h3>
        <div className="flex mb-2">
          <span className="document-field-viewer">Система налогообложения</span>
          <span className="text-sm">
            {documentClientProfile?.fields?.tax_system ?? documentClient?.fields?.tax_system}
          </span>
        </div>
        <Form
          form={usnForm}
          name="usn-form"
          onChange={onFormValuesChange}
          onClick={onFormValuesChange}
          validateTrigger={validateTriggersArr}
          layout="vertical"
        >
          {!isUsnFormDisabled && (
            <>
              <Divider className="mt-3" />
              <div className={`mb-2 ${css.modalText}`}>Согласование</div>
              <p className="mb-5 gray-color w-fit">
                Подтвердите согласование документа. При
                <br />
                наличии ошибок или проблем с файлом
                <br />
                отклоните согласование, указав причину.
              </p>
              <div className="flex justify-between">
                <Tooltip
                  title={
                    isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""
                  }
                >
                  <Form.Item name="isApproved">
                    <Radio.Group
                      onChange={onApproveChange}
                      value={docApproveStatus}
                      disabled={isContactCenter || isDocApprovalDisabled}
                      className="flex flex-col gap-2"
                    >
                      <Radio value="APPROVED">Согласовать</Radio>
                      <Radio value="DECLINED">Отклонить</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Tooltip>
              </div>
              <div className="w-full">
                {docApproveStatus === "DECLINED" && (
                  <Form.Item
                    name="declineReason"
                    label="Причина"
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                    initialValue="Необходимо приложить верный документ (приложен не тот документ)"
                  >
                    <Select
                      placeholder="Причина"
                      size="large"
                      options={[
                        {
                          value: "Необходимо приложить верный документ (приложен не тот документ)",
                          label: "Необходимо приложить верный документ (приложен не тот документ)"
                        },
                        {
                          value: "Необходимо приложить полный документ (не все страницы)",
                          label: "Необходимо приложить полный документ (не все страницы)"
                        },
                        {
                          value: "Истек срок действия документа",
                          label: "Истек срок действия документа"
                        },
                        {
                          value: "Плохое качество документа, нечитаемый скан",
                          label: "Плохое качество документа, нечитаемый скан"
                        }
                      ]}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
      <Divider className="mt-0 mb-5" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={cancelCheck}
          text={isUsnFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isUsnFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            type="primary"
            className={css.buttonOk}
            onClick={handleSave}
            text="Сохранить"
            size="large"
            disabled={isDocVerified}
          />
        )}
      </div>
      <ConfirmModal
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setConfirmModalOpen}
        closeForm={closeForm}
      />
    </div>
  );
};

export default ClientProfileCardImageViewerFormUsn;
