import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import {
  Form,
  Input,
  Radio,
  Select,
  Tooltip,
  Divider,
  FormInstance,
  RadioChangeEvent,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getEmployeeDocumentData, getDocumentSchema } from "../../../../actions/document.actions";
import { AppStateType } from "../../../../reducers/mainReducer";
import { DocumentClientProfileType, UpdateDocumentFilesType } from "app/types";
import { getUnAgreedDocumentType } from "../../../../actions/clientProfile.actions";
import { isDocRequiredValuesFilled } from "../../../../utils/isDocRequriedValuesFiiled";
import { isFormItemRequiredRule } from "../../../../utils/isFormItemRequiredRule";
import { validateTriggersArr } from "../ClientProfileCardImageViewer";
import css from "./../ClientProfileCardImageViewer.module.css";
import { JWTPayload } from "jose";
import { roleResolver } from "../../../../utils/roleResolver";
import ConfirmModal from "./ConfirmModal/ConfirmModal";

interface IClientProfileCardImageViewerFormBankDetailsProps {
  bankDetailsForm: FormInstance;
  closeForm: () => void;
  cancelCheck: () => void;
  documentUuid: string;
  setCanSaveDoc: React.Dispatch<SetStateAction<boolean>>;
  onApproveChange: (e: RadioChangeEvent | string) => void;
  docApproveStatus: RadioChangeEvent | string;
  onFormValuesChange: () => void;
  setDocFilesLinks: React.Dispatch<SetStateAction<string[]>>;
  setTotalDocFiles: React.Dispatch<SetStateAction<number>>;
  isClientEmployee?: boolean;
  isValidationCompleted?: boolean;
  isNotAgreedDocs?: boolean;
  isConfirmModalOpen: boolean;
  setConfirmModalOpen: Dispatch<SetStateAction<boolean>>;
}

enum FormLength {
  noData = 0,
  bankBic = 9,
  bankAccount = 20,
}

const ClientProfileCardImageViewerFormBankDetails: FC<IClientProfileCardImageViewerFormBankDetailsProps> = ({
  bankDetailsForm,
  closeForm,
  cancelCheck,
  documentUuid,
  setCanSaveDoc,
  onApproveChange,
  docApproveStatus,
  onFormValuesChange,
  setDocFilesLinks,
  setTotalDocFiles,
  isClientEmployee,
  isValidationCompleted,
  isNotAgreedDocs,
  isConfirmModalOpen,
  setConfirmModalOpen,
}) => {
  const [isSubmitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(false);
  const [isDocApprovalDisabled, setDocApprovalDisabled] = useState<boolean>(false);
  const [isDocVerified, setDocVerified] = useState<boolean>(false);
  const [bicBlur, setBicBlur] = useState<boolean>(false);
  const [bankAccount, setBankAccount] = useState<boolean>(false);
  const [correspondentAccount, setCorrespondentAccount] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const documentClientProfile: DocumentClientProfileType | null = useSelector(
    (state: AppStateType) => state.clientProfile.documentClientProfile
  );

  const {
    bankDoc,
    bankDocSchema
  } = useSelector((state: AppStateType) => state.document);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    setDocVerified((!!docApproveStatus && isDocApprovalDisabled) || isContactCenter);
  }, [docApproveStatus, isDocApprovalDisabled]);

  useEffect(() => {
    isDocRequiredValuesFilled(bankDocSchema, bankDetailsForm, setDocApprovalDisabled);
  }, [bankDetailsForm, bankDocSchema, onFormValuesChange]);

  useEffect(() => {
    const documentStatus: string = bankDoc?.fields?.document_status ?? "";
    const documentFiles: string[] = bankDoc?.fields?.files?.map(({ uuid }) => uuid) || [];

    onApproveChange(documentStatus);
    setDocFilesLinks(documentFiles);
    setTotalDocFiles(documentFiles?.length);
  }, [documentClientProfile]);

  useEffect(() => {
    if (!isNotAgreedDocs && documentUuid) {
      dispatch(getDocumentSchema("bank-schema"));
      dispatch(getEmployeeDocumentData("bank-schema", documentUuid));
    }
  }, [dispatch, documentUuid, isNotAgreedDocs]);

  useEffect(() => {
    if (docApproveStatus === "DECLINED") {
      const docMigrationDeclineReason: string = bankDoc?.fields?.decline_reason ?? "";

      bankDetailsForm.setFieldValue(
        "declineReason" as any,
        docMigrationDeclineReason || "Необходимо приложить верный документ (приложен не тот документ)"
      );
    }

    onFormValuesChange();
  }, [docApproveStatus, bankDoc, bankDetailsForm]);

  useEffect(() => {
    const docBik: string = bankDoc?.fields?.bic ?? "";
    const docBankAccountNumber: string = bankDoc?.fields?.bank_account_number ?? "";
    const docCorrespondentAccount: string = bankDoc?.fields?.correspondent_account ?? "";
    const docBankStatus: string = bankDoc?.fields?.document_status ?? "";
    const docBankDeclineReason: string = bankDoc?.fields?.decline_reason ?? "";
    const shouldUpdateDocField: boolean =
      !!docBik || !!docBankStatus || !!docBankAccountNumber || !!docCorrespondentAccount;

    if (shouldUpdateDocField) {
      handleBikChange(docBik);
      handleAccountNumberChange(docBankAccountNumber);
      handleCorrespondentAccountChange(docCorrespondentAccount);
      onApproveChange(docBankStatus);

      bankDetailsForm.setFieldValue("isApproved", docBankStatus);
    }

    if (docBankDeclineReason) {
      bankDetailsForm.setFieldValue("declineReason", docBankDeclineReason);
    }
  }, [bankDoc, bankDetailsForm]);

  const handleBikChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string" ? event.target.value.replace(/\D/g, "") : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,9})/);
    const formattedBik: string = matches ? matches[1] : "";

    setSubmitBtnDisabled(!(formattedBik?.length === FormLength.bankBic || formattedBik?.length === FormLength.noData));

    bankDetailsForm.setFieldValue("bic", formattedBik);
  };

  const handleAccountNumberChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string" ? event.target.value.replace(/\D/g, "") : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,20})/);
    const formattedAccountNumber: string = matches ? matches[1] : "";

    setSubmitBtnDisabled(
      !(formattedAccountNumber?.length === FormLength.bankAccount ||
        formattedAccountNumber?.length === FormLength.noData)
    );

    bankDetailsForm.setFieldValue("bank_account_number", formattedAccountNumber);
  };

  const handleCorrespondentAccountChange = (event: React.ChangeEvent<HTMLInputElement> | string): void => {
    const inputValue: string = typeof event !== "string" ? event.target.value.replace(/\D/g, "") : event;
    const matches: RegExpMatchArray | null = inputValue.match(/(\d{0,20})/);
    const formattedCorrespondentAccount: string = matches ? matches[1] : "";

    setSubmitBtnDisabled(
      !(formattedCorrespondentAccount?.length === FormLength.bankAccount ||
        formattedCorrespondentAccount?.length === FormLength.noData)
    );

    bankDetailsForm.setFieldValue("correspondent_account", formattedCorrespondentAccount);
  };

  const isContactCenter: boolean = roleResolver(decodedToken).isContactCenter;
  const isBankFormDisabled: boolean | undefined = isContactCenter || isClientEmployee || isValidationCompleted;

  const handleValidateStatus = (values: string, formLength: number): "error" | "" => {
    return bankDetailsForm.getFieldValue(values as any)?.length === formLength ? "" : "error";
  };

  const handleHelp = (values: string, text: string): null | string => {
    return bankDetailsForm.getFieldValue(values as any) ? null : text;
  };

  const handleBlur = (variant: string): void => {
    const handlers: Record<string, () => void> = {
      bic: () => setBicBlur(true),
      bank_account_number: () => setBankAccount(true),
      correspondent_account: () => setCorrespondentAccount(true)
    };

    handlers[variant]();
  };
  
  const handleSave = (): void => {
    setCanSaveDoc(true);
    dispatch(getUnAgreedDocumentType(true));
  };

  return (
    <div>
      <div className={`${css.formStyle} w-[340px]`}>
        <h2 className={`${css.modalText} mb-2`}>Банковские реквизиты</h2>
        {!isBankFormDisabled && (
          <p className="mb-4 gray-color w-full">
            После проверки правильности документа
            <br />
            заполните соответствующие данные
            <br />
            и подтвердите его согласованность.
          </p>
        )}
        <Form
          form={bankDetailsForm}
          name="bank-details-form"
          onChange={onFormValuesChange}
          onClick={onFormValuesChange}
          validateTrigger={validateTriggersArr}
          layout="vertical"
        >
          <div className="flex flex-col">
            <Form.Item
              name="bic"
              className="mb-5"
              label="БИК банка"
              rules={[
                {
                  min: 9,
                  message: "Значение должно быть 9 цифр."
                },
                {
                  max: 10,
                  message: "Значение должно быть 9 цифр."
                },
                {
                  required: isFormItemRequiredRule("bic", bankDocSchema),
                  message: ""
                }
              ]}
              validateTrigger={validateTriggersArr}
              validateStatus={bicBlur ? handleValidateStatus("bic", FormLength.bankBic) : ""}
              help={bicBlur ? handleHelp("bic", "Только цифры") : null}
            >
              <Input
                id="bic"
                size="large"
                placeholder="000000000"
                showCount
                maxLength={9}
                onBlur={() => handleBlur("bic")}
                onChange={handleBikChange}
                readOnly={isBankFormDisabled}
              />
            </Form.Item>
            <Form.Item
              name="bank_account_number"
              className="mb-5"
              label="Номер счета получателя"
              rules={[
                {
                  min: 20,
                  message: "Значение должно быть 20 цифр."
                },
                {
                  max: 21,
                  message: "Значение должно быть 20 цифр."
                },
                {
                  required: isFormItemRequiredRule("bank_account_number", bankDocSchema),
                  message: ""
                }
              ]}
              validateTrigger={validateTriggersArr}
              validateStatus={bankAccount ? handleValidateStatus("bank_account_number", FormLength.bankAccount) : ""}
              help={bankAccount ? handleHelp("bank_account_number", "Только цифры") : null}
            >
              <Input
                id="bank_account_number"
                size="large"
                placeholder="00000000000000000000"
                showCount
                maxLength={20}
                onChange={handleAccountNumberChange}
                readOnly={isBankFormDisabled}
                onBlur={() => handleBlur("bank_account_number")}
              />
            </Form.Item>
            <Form.Item
              name="correspondent_account"
              label="Корреспондентский счет"
              rules={[
                {
                  min: 20,
                  message: "Значение должно быть 20 цифр."
                },
                {
                  max: 21,
                  message: "Значение должно быть 20 цифр."
                },
                {
                  required: isFormItemRequiredRule("correspondent_account", bankDocSchema),
                  message: ""
                }
              ]}
              validateTrigger={validateTriggersArr}
              validateStatus={
                correspondentAccount ? handleValidateStatus("correspondent_account", FormLength.bankAccount) : ""
              }
              help={correspondentAccount ? handleHelp("correspondent_account", "Только цифры") : null}
            >
              <Input
                id="correspondent_account"
                size="large"
                placeholder="00000000000000000000"
                showCount
                maxLength={20}
                onChange={handleCorrespondentAccountChange}
                readOnly={isBankFormDisabled}
                onBlur={() => handleBlur("correspondent_account")}
              />
            </Form.Item>
          </div>
          {!isBankFormDisabled && (
            <>
              <Divider className="mt-3" />
              <div className={`mb-2 ${css.modalText}`}>Согласование</div>
              <p className="mb-5 gray-color w-fit">
                Подтвердите согласование документа. При
                <br />
                наличии ошибок или проблем с файлом
                <br />
                отклоните согласование, указав причину.
              </p>
              <div className="flex justify-between">
                <Tooltip
                  title={
                    isDocApprovalDisabled ? "Для согласования документа необходимо заполнить обязательные поля" : ""
                  }
                >
                  <Form.Item name="isApproved">
                    <Radio.Group
                      onChange={onApproveChange}
                      value={docApproveStatus}
                      disabled={isDocApprovalDisabled || isContactCenter}
                      className="flex flex-col gap-2"
                    >
                      <Radio value="APPROVED">Согласовать</Radio>
                      <Radio value="DECLINED">Отклонить</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Tooltip>
              </div>
              <div>
                {docApproveStatus === "DECLINED" && (
                  <Form.Item
                    name="declineReason"
                    label="Причина"
                    rules={[
                      {
                        required: true,
                        message: ""
                      }
                    ]}
                  >
                    <Select
                      placeholder="Причина"
                      size="large"
                      options={[
                        {
                          value: "Необходимо приложить верный документ (приложен не тот документ)",
                          label: "Необходимо приложить верный документ (приложен не тот документ)"
                        },
                        {
                          value: "Плохое качество документа, нечитаемый скан",
                          label: "Плохое качество документа, нечитаемый скан"
                        }
                      ]}
                    />
                  </Form.Item>
                )}
              </div>
            </>
          )}
        </Form>
      </div>
      <Divider className="mt-0 mb-5" />
      <div className="flex mx-6 my-4 gap-2">
        <ButtonCustom
          id="close"
          className={css.buttonBack}
          type="default"
          onClick={cancelCheck}
          text={isBankFormDisabled ? "Закрыть" : "Отменить"}
          size="large"
        />
        {!isBankFormDisabled && (
          <ButtonCustom
            id="save"
            key="submit"
            className={css.buttonOk}
            type="primary"
            onClick={handleSave}
            text="Сохранить"
            size="large"
            disabled={isDocVerified || isSubmitBtnDisabled}
          />
        )}
      </div>
      <ConfirmModal
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setConfirmModalOpen}
        closeForm={closeForm}
      />
    </div>
  );
};

export default ClientProfileCardImageViewerFormBankDetails;
