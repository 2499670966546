import {
  Dispatch,
  FC,
  JSX,
  SetStateAction
} from "react";
import { Modal } from "antd";
import css from "./ActivationUsersModal.module.css";
import { UserDataType } from "../../UsersList/UsersListTable/UsersListTable";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { activeUsers } from "../../../../api/keycloak.api";
import { usersNotification } from "../../utils/Notifications/Notifications";
import { ParamsType } from "../BlockingUsersModal/BlockingUsersModal";

interface IActivationUsersModalProps {
  isOpenModal: boolean;
  setIsOpenActiveModal: Dispatch<SetStateAction<boolean>>;
  setUpdateListing: Dispatch<SetStateAction<boolean>>;
  userData: UserDataType[];
  setSelectedRows: Dispatch<SetStateAction<UserDataType[]>>;
}

const ActivationUsersModal: FC<IActivationUsersModalProps> = ({
  isOpenModal,
  setIsOpenActiveModal,
  setUpdateListing,
  userData,
  setSelectedRows
}): JSX.Element => {
  const blockUsers: UserDataType[] = userData.filter(({ userStatus }): boolean => userStatus === "blocked");
  
  const modalTitle: JSX.Element = <div className={css.modalTitle}>Активация пользователей</div>;
  
  const handleOk = (): void => {
    setIsOpenActiveModal(false);
    
    const paramsData: ParamsType = {
      users: blockUsers.map(({ key }) => key),
      status: "active",
    };
    
    activeUsers(paramsData)
      .then((response) => {
        if (response.status === 200) {
          setSelectedRows([]);
          setUpdateListing((prev: boolean) => !prev);
          usersNotification("usersActiveSuccess", blockUsers);
        }
      })
      .catch((error) => {
        usersNotification("usersActiveFailure");
        console.error("Block user error:", error);
      })
      ?.finally(() => setIsOpenActiveModal(false));
  };
  
  const handleCancel = (): void => {
    setIsOpenActiveModal(false);
  };
  
  const modalFooter: JSX.Element = (
    <div key="modalBotton">
      <ButtonCustom
        className="mr-2"
        ghost
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={handleCancel}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={handleOk}
      />
    </div>
  );
  
  return (
    <Modal
      width={572}
      title={modalTitle}
      open={isOpenModal}
      onCancel={handleCancel}
      footer={modalFooter}
      centered
    >
      <div className={`${css.modalHeader} mb-5`}>Вы уверены, что хотите разблокировать выбранных пользователей?</div>
      <div className={css.modalHeader}>Выбранные пользователи</div>
      <div className={css.userName}>
        {blockUsers?.map(({ id, full_name }) =>
          <div key={id}>{full_name}</div>
        )}
      </div>
    </Modal>
  );
};

export default ActivationUsersModal;