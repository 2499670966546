import React, {
  FC,
  JSX,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { IClientActionsProps } from "../ClientCardItem/utils/clientActions";
import { roleResolver } from "../../../../utils/roleResolver";
import { getAvailableActions } from "./utils/getAvailableActions";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ConfigProvider, Select } from "antd";
import * as jose from "jose";

interface IClientCardActionBtnProps {
  isDraftClient: boolean;
  setDrawerClientAction: Dispatch<SetStateAction<string>>;
}

const selectActionName: JSX.Element = <span className="text-white">Действия по клиенту</span>;

const ClientCardActionBtn: FC<IClientCardActionBtnProps> = ({
  isDraftClient,
  setDrawerClientAction
}): JSX.Element => {
  const [isSelectActive, setSelectActive] = useState<boolean>(false);

  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const {
    isAdmin,
    isAssistant,
    isAccountant,
    isTechSupport,
    isContactCenter,
  } = roleResolver(decodedToken);

  const availableActions: IClientActionsProps[] = getAvailableActions({
    isAdmin,
    isAssistant,
    isAccountant,
    isTechSupport,
    isContactCenter,
  }, isDraftClient);

  const disableActionBtn: boolean = isDraftClient;
  const selectActionIconColor: string = disableActionBtn ? "rgba(0, 0, 0, 0.25)" : "text-white";

  const customSelectStyle = {
    components: {
      Select: {
        fontSizeLG: 15,
        selectorBg: "#3438E0",
        colorIcon: "white",
        controlOutlineWidth: 0,
        colorTextPlaceholder: disableActionBtn ? "rgba(0, 0, 0, 0.25)" : "white",
        colorBgContainerDisabled: "#67768C26",
        colorTextDisabled: "black",
      },
    }
  };

  const selectedActive = (): void => {
    setSelectActive((prev: boolean) => !prev);
  };
  
  const onSelectAction = (action: JSX.Element): void => {
    setDrawerClientAction(String(action));
  };
  
  return (
    <div className="flex items-center">
      <ConfigProvider theme={customSelectStyle}>
        <Select
          value={selectActionName}
          size="large"
          options={availableActions}
          listHeight={300}
          placeholder="Действия по клиенту"
          suffixIcon={
            isSelectActive
              ? <UpOutlined className={selectActionIconColor} />
              : <DownOutlined className={selectActionIconColor} />
          }
          dropdownStyle={{ width: "290px" }}
          onClick={selectedActive}
          onSelect={onSelectAction}
          disabled={disableActionBtn}
        />
      </ConfigProvider>
    </div>
  );
};

export default ClientCardActionBtn;
